.mfa-modal {
    color: $step-content;

    &.modal--modern {
        .modal-box {
            &--title {
                font-size: 18px;
                justify-content: center;
                line-height: normal;
                margin-bottom: 41px;
                padding-right: 36px;
            }

            &--content {
                position: relative;
                padding: 0 0 100px;
                overflow-y: auto;

                a {
                    color: $violet-new;
                }
            }

            &--close {
                width: 24px;
                height: 24px;

                svg {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }

    &--content {
        padding: 0;

        &-instructions {
            font-size: 14px;

            h4 {
                margin: 0;
            }

            p {
                margin: 0;
                text-align: justify;
            }
        }

        &-description {
            font-size: 14px;

            p {
                margin: 0;
            }
        }

        &-qrcode {
            img {
                width: 140px;
                height: 140px;
                border: 1px solid $violet-new;
                border-radius: 20px;
            }

            .secret-key-wrapper {
                font-size: 14px;
            }
        }

        &-footer {
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            padding: 20px 16px;

            button {
                width: 100%;
                height: 48px;
            }

            .enable-mfa-btn {
                width: 100%;
            }
        }

        &-status {
            .status-wrapper {
                h2 {
                    font-size: 20px;
                }
            }
        }
    }

    &--modern {
        padding: 0;

        .modal-box {
            padding: 0;
        }
    }
}
