.btn {
    border-radius: $border-radius;
    border: none;
    font-size: 14px;
    outline: none;
    padding: 0 16px;
    height: 34px;
    line-height: 34px;
    display: inline-block;
    letter-spacing: 0.02em;
    background: $purple;
    color: $white;
    text-align: center;
    font-weight: 600;

    &-submit {
        background: $yellow;
        color: $violet-submit;

        &.btn-modern {
            background: $yellow-alt;
            color: $step-content;
        }
    }

    &--primary {
        background: $yellow;
        color: $white;
    }

    &--cancel,
    &-cancel {
        background: $white;
        border: solid 1px $default-border-color;
        color: $default-border-color;

        &.btn-modern {
            border: none;
            color: $step-content;
            background: transparent;
        }

        &.bonus {
            color: $light-purple;
        }
    }

    &--small {
        font-size: 12px;
        font-weight: 500;
        height: 24px;
        line-height: 24px;
        min-width: 92px;
    }

    &--large {
        font-size: 16px;
        padding: 0 22px;
        height: 44px;
        line-height: 43px;
        border-radius: $border-radius;
    }

    &-purple {
        &.btn-modern {
            color: $white;
            border: 1px solid $violet-new;
            background: $new-dark-purple;
        }
    }

    &.btn-modern {
        border-radius: 100px;
        white-space: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: normal;
    }

    &--rounded {
        @include font-family-nunito;
        background: $yellow-alt;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        box-shadow: 0 10px 25px 0 rgba(187, 126, 0, 0.5);
        border-radius: 24px;
        height: 48px;
        min-width: 140px;
        border: none;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        color: $step-content;
        transition: 0.3s;
    }

    &--no-border {
        border: none;
        background: none;
        min-width: 140px;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
    }

    &.btn-copy {
        background: $white;
        color: $step-content;
        border: 1px solid $yellow-alt;
    }

    &.btn-decline {
        background: $orange;
        border: 1px solid $orange;
        color: $white;
    }
}

.content .btn--primary {
    color: $white;
}

button[disabled] {
    opacity: 0.6;
}
