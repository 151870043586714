.stop-session-modal {
    @include font-family-nunito;
    display: flex;
    flex-direction: column;
    align-items: center;

    &_content {
        .text {
            color: $step-content;
            text-align: center;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
        }
    }

    .container-btns {
        width: 100%;

        .btn.btn-cancel.btn-large.btn-modern {
            color: $step-content;
        }
    }
}
