.wheel-of-fortune.page-container {
    .column {
        width: calc(50% - 50px);
    }

    .content-wrapper {
        .logo-wrapper {
            img {
                height: 150px;
                max-width: 100%;
            }
        }

        .wheel-wrapper {
            font-size: 20px;
            position: relative;
            margin-top: -70px;
        }
    }

    .wheel-of-fortune-content {
        padding: 32px 75px;
        display: flex;
        min-height: 100vh;

        &:not(.no-content) {
            justify-content: space-between;
        }

        &.no-content {
            p .space-after-br {
                display: block;
                margin-bottom: 10px;
            }

            .wheel-of-fortune-maintenance {
                max-width: 700px;

                .maintenance-wrapper {
                    .maintenance-container {
                        &--inner {
                            padding: 24px 12px;
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        &--title {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &.loading {
            .wheel-of-fortune-loader {
                align-self: center;
            }
        }

        .current-player--edit {
            cursor: pointer;

            &:hover {
                text-decoration: underline;

                svg {
                    path {
                        fill: $violet-new;
                    }
                }
            }
        }

        .wof-messages-carousel {
            .wof-carousel--card {
                max-width: var(--desktop-page-message-max-width);
                margin: 0 auto;
            }
        }

        .refresh-desktop {
            flex-shrink: 0;

            &:hover {
                background: $wheel-of-fortune-hover-bg;
                color: white;

                .refresh-icon {
                    transform: rotate(-180deg);

                    svg {
                        path {
                            fill: $white;
                        }
                    }
                }
            }
        }

        .wheel-of-fortune--prizes-timer {
            display: grid;
            row-gap: 24px;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto;
            position: relative;
            grid-auto-flow: column;
            column-gap: 16px;
        }

        .table-content {
            table,
            .table--no-participants {
                background: $wheel-of-fortune-table-background;
            }
        }

        .table--no-participants {
            .text {
                height: 390px;

                h5 {
                    margin: 16px 0;
                    font-size: 24px;
                    font-weight: 800;
                }
            }
        }
    }

    .wheel-of-fortune-info {
        padding: 0 75px 75px;
        display: grid;
        grid-template-columns: 1fr 2fr 2fr;
        grid-template-rows: auto auto;
        grid-auto-flow: column;
        column-gap: 70px;

        .terms-and-conditions--card {
            border-radius: 20px;
            padding: 24px;
            background: $light-peach;
            height: max-content;
            box-shadow: 0 32px 32px -12px $box-shadow-peach;
        }
    }

    @media screen and (max-width: 1400px) {
        .column {
            width: calc(50% - 20px);
        }

        .wheel-of-fortune-content {
            padding: 32px;
        }
    }
}

.wheel-of-fortune-modal {
    &.modal--modern {
        .modal-box {
            width: 600px;

            &--content {
                gap: 16px;

                &.loading {
                    height: 550px;
                }

                .content-wrapper {
                    .logo-wrapper {
                        img {
                            height: 100px;
                            max-width: 100%;
                        }
                    }

                    .wheel-wrapper {
                        font-size: 18px;
                        position: relative;
                        margin-top: -50px;
                    }
                }

                .wheel-of-fortune-maintenance {
                    height: 550px;

                    .wild-fortunes {
                        width: 300px;
                    }

                    .maintenance-wrapper {
                        .maintenance-container {
                            max-width: 400px;

                            &--inner {
                                padding: 18px;
                                font-size: 14px;
                                line-height: 18px;
                            }
                        }
                    }
                }

                .modal-info,
                .container-btns {
                    display: flex;
                    justify-content: center;
                    width: 384px;
                    margin: 0 auto;
                }

                .container-btns {
                    .btn-submit {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.wheel-widget {
    margin: 20px;
    bottom: 100px;
    width: 90px;
    height: 90px;

    canvas {
        opacity: 0;
    }

    img {
        width: 80px;
        height: 80px;
        animation-play-state: running;
    }

    .spins-count {
        width: 36px;
        height: 36px;
        font-size: 22px;
    }

    &:hover {
        canvas {
            opacity: 1;
        }

        img {
            animation-play-state: paused;
        }
    }
}

.win-modal {
    &.modal--modern {
        .modal-box--content {
            min-height: 450px;
            height: auto;
            gap: 48px;

            .prize {
                margin: 50px auto 40px;

                &--bonus {
                    img {
                        width: 215px;
                        height: auto;
                    }
                }
            }

            h2 {
                font-size: 24px;
            }

            p {
                font-size: 14px;
            }

            .btn {
                max-width: 288px;
                margin: 0 auto;
                flex-shrink: 0;
            }
        }
    }
}
