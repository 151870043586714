@each $level,
    $values
        in (
            low: (
                20%,
                $progress-bar-low,
                $progress-bar-low-blur,
                2.5s
            ),
            lowMedium: (
                40%,
                $progress-bar-low-medium,
                $progress-bar-low-medium-blur,
                2s
            ),
            medium: (
                60%,
                $progress-bar-medium,
                $progress-bar-medium-blur,
                1.5s
            ),
            mediumHigh: (
                80%,
                $progress-bar-medium-high,
                $progress-bar-medium-high-blur,
                1s
            ),
            high: (
                100%,
                $progress-bar-high,
                $progress-bar-high-blur,
                0.5s
            ),
            extreme: (
                100%,
                $progress-bar-high,
                $progress-bar-high-blur,
                0.5s
            )
        )
{
    $width: nth($values, 1);
    $background: nth($values, 2);
    $blur: nth($values, 3);
    $animation-duration: nth($values, 4);

    @keyframes breathing-#{$level} {
        0%,
        100% {
            box-shadow:
                0 0 0 #{$background},
                0 0 0 #{$background},
                0 0 0 #{$background};
        }
        50% {
            box-shadow:
                0 0 1px #{$blur},
                0 0 5px #{$blur},
                0 0 20px #{$blur};
        }
    }

    .progress-bar--inner.#{$level} {
        width: #{$width};
        background: #{$background};
        animation: breathing-#{$level} #{$animation-duration} ease-in-out infinite;
    }
}

.progress-bar {
    &--outer {
        position: relative;
        height: 10px;
        border-radius: 5px;
        margin: 6px 0 16px;
        background: $progress-bar-background;
    }

    &--inner {
        border-radius: 5px;
        height: 10px;
        position: absolute;
        top: 0;
        left: 0;
    }

    &--tooltip {
        position: absolute;
        top: 15px;
        background: $progress-bar-tooltip-bg;
        padding: 5px 10px;
        border-radius: 5px;
        margin-left: 70px;
        transition: all 0.3s ease-in-out;
        text-wrap: nowrap;
        z-index: 2;
        display: none;

        &.visible {
            display: flex;
        }

        &.default {
            margin-left: 35px;
            top: 10px;
        }
    }
}
