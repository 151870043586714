body {
    @include font-family-nunito;
    font-size: 14px;
    font-weight: 400;
    background: $main-bg url('#{$asset-path}/main_bg.jpg') repeat;
    color: $step-content;
    padding: 0 !important;
    min-width: 1280px;
}

a {
    color: $step-content;

    &.btn-modern {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

#content {
    min-height: 100vh;

    &.play {
        height: 100%;
        width: 100%;
    }
}

.form-loader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container {
    width: 980px;
    margin: 0 auto;
    position: relative;

    &--narrow {
        width: 504px;
        text-align: center;
    }
}

.content {
    &--white {
        border-radius: 4px;
        background: #fff;
        width: auto;
        margin: 40px auto;
        padding: 30px 40px;
    }
}

.page-message {
    padding: 10px 15px;
    background: $input-disabled;
    border-radius: 4px;
    color: $main-color;
    margin: 0;
    font-size: 14px;
    text-align: center;

    &--error {
        background: $red-error;
        color: $white;
    }

    &--warning {
        background: $yellow;
    }
}

.d-flex {
    display: flex !important;
}

.blurred {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
    pointer-events: none;
    overflow: hidden;
}

.page-header {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    line-height: 40px;
    font-size: 17px;

    :is(h1, h2, h3, h4, h5, h6) {
        font-size: 18px;
        padding: 0;
        display: block;
        line-height: 31px;
        font-weight: 500;
        margin-bottom: 0;

        a {
            color: $main-color;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &.center {
        text-align: center;
        display: block;
    }

    &.large {
        font-size: 22px;
        font-weight: 800;
        margin: 30px 0;
    }

    &--separator {
        position: relative;
        margin: 0 8px;

        &:before {
            content: '›';
            font-weight: 300;
        }
    }

    &--subtitle {
        font-weight: 400;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.violet {
    color: $light-violet;

    &-global {
        color: $violet-global;
    }
}

.no-results-message {
    padding: 20px 0 40px;
    font-size: 24px;
    text-align: center;
    line-height: 30px;
    width: 100%;
}

.load-more {
    margin: 48px 0 32px;
    position: relative;

    .btn {
        position: relative;
        min-width: 200px !important;
        margin: 0 auto;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.radio-group {
    padding: 0 12px;

    label {
        display: flex;
        align-items: flex-start;
        margin: 0 0 16px 0;
    }

    .radio-button {
        color: $violet-new !important;
        width: 24px;
        height: 24px;
        padding: 0 !important;
        margin-right: 10px !important;

        input {
            height: 100%;
        }

        .MuiSvgIcon-root {
            width: 24px;
            height: 24px;
        }

        &-checked {
            color: $violet-new !important;

            .MuiSvgIcon-root {
                &:nth-child(2) {
                    path {
                        fill: $violet-new;
                    }
                }
            }
        }
    }

    .MuiFormControlLabel-root {
        .MuiTypography-root {
            @include font-family-nunito;
            width: 100%;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: normal;
        }
    }
}

.page--no-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
        font-size: 24px;
        font-weight: 500;
        color: $no-items-message-color;
        margin: 0;
    }
}

.panel-tabs {
    .MuiTabs-indicator {
        background: $violet-new;
    }

    &--tab {
        color: $info-text;
        text-align: center;
        font-size: 16px;
        font-weight: 700;

        &[aria-selected='true'] {
            color: $step-content;
        }
    }
}

.MuiTooltip-tooltip {
    background-color: $progress-bar-tooltip-bg;
    border-radius: 5px;
    margin-left: 5px;
    margin-top: 0;
}

.payment-provider-method {
    justify-content: center;
}
