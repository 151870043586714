$main-color: #4a2c10;
$input-disabled: #faf6ed;
$violet: #4f0053;
$violet-global: #90018b;
$light-yellow: #ded3bf;
$yellow: #ffc000;
$yellow-alt-hover: #ffbe0b1a;
$dark-yellow: #eb9f1d;
$footer-bg-light: #eddac0;
$footer-bg-violet: #560058;
$game-item-bg: #eedac1;
$red: #d45858;
$font-play-tab: #666;
$grey: #808080;
$check-color: #5fbc25;
$status_validated: #00987b;
$separator: #e2ded7;
$box-shadow-dark: rgba(0, 0, 0, 0.5);
$latest-winner-card-border: rgba(194, 152, 117, 0.3);
$favorite-hover: rgba(131, 56, 236, 0.6);
$message-description: rgba(48, 22, 0, 0.5);
$btn-decline-hover: #bf0000;
$active-filters-border: rgba(194, 152, 117, 0.5);
$ftd-bg: linear-gradient(180deg, #90028b 0%, #610162 100%);
$amount-animation-shadow-1: #a97d05;
$amount-animation-shadow-2: #7a5a02;
$amount-animation-shadow-3: #513c00;
$amount-animation-shadow-4: #1f1700;
$payment-method-hover: rgba(255, 190, 11, 0.3);
$deposit-limits-color: #c29875;
$golden-yellow: #faba19;
$tooltip-brown-bg: #2d1311;
$android-app-lp-banner-gradient: linear-gradient(270deg, rgba(45, 8, 82, 0) 30%, #2d0852 60%);

//SIZES
$game-thumb-width-default: 176px;
$game-thumb-width-small: 140px;
$deposit-container-btns-height: 72px;
$menu-item-height: calc((100vh - var(--nav-item_height)) / var(--nav_length));
$menu-item-min-height: 30px;
$menu-item-max-height: 68px;
$banner-height: 340px;
$lobby-bottom-margin: 64px;
$banner-padding-side: 64px;
$promo-modal-default: 600px;
$promo-modal-big: 800px;
