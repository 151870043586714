.game-loader {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $black;
}

.error-page {
    &-play {
        position: fixed;
        width: 100%;
        top: 0;
    }
}

#game {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    background: $black;
}

#iframe {
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
}

#game_actions.actions {
    position: fixed;
    top: 0;
    left: 0;
    padding: 12px 10px;
    background: $white;
    border-bottom-right-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    z-index: 9;

    button {
        border-radius: 50%;
        flex-shrink: 0;
        padding: 0;
        width: 24px;
        height: 24px;
        border: 1px solid $step-content;
        background: none;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 8px;
            height: 8px;

            path {
                fill: $step-content;
            }
        }

        &.star {
            svg {
                width: 12px;
                height: 12px;
            }
        }

        &.favorite {
            border: 1px solid $violet-new;
            background: $violet-new;

            svg {
                path {
                    fill: $yellow-alt;
                }
            }
        }
    }
}
