.casino-guide-page {
    .casino-guide--title {
        font-size: 32px;
        font-weight: 800;
        margin-bottom: 24px;
    }
}

.container-casino-guide {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 25px;
    grid-row-gap: 25px;
    margin: 24px 0 48px;

    .infos {
        transition: box-shadow 0.2s ease-in-out;

        &:hover {
            box-shadow: 0 5px 20px 0 $card-box-shadow;
        }

        a {
            color: $violet-new;
        }

        .title {
            font-size: 18px;
            margin: 0 0 16px;
        }
    }
}

.casino-guide-content {
    iframe {
        overflow: hidden !important;
        width: 100%;
        min-height: 100%;
        border: none;
    }

    * {
        max-width: 100%;
    }
}

.no-casino-guide-content {
    margin-top: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
}

.casino-guide-side {
    margin: 40px 0;

    .main-title {
        background: $footer-bg-light;
        border-radius: 4px;
        text-align: center;
        margin-bottom: 18px;
        line-height: 46px;
        font-size: 13px;
        font-weight: 700;
    }

    .casino-guide {
        border-radius: 4px;
        background: $white;
        overflow: hidden;
        margin-bottom: 20px;
        font-size: 12px;
        display: block;

        .visual {
            font-size: 0;

            img {
                width: 100%;
            }
        }

        .title {
            font-size: 14px;
            text-align: center;
            padding: 8px 10px;
        }
    }
}
