.hamburger-menu {
    height: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    cursor: pointer;

    .bar1,
    .bar2,
    .bar3 {
        width: 15px;
        height: 3px;
        background-color: $white;
        transition: all 0.3s ease;
        border-radius: 20px;
    }

    &.open {
        .bar1 {
            transform: rotate(-45deg) translate(-5px, 5px);
        }

        .bar2 {
            opacity: 0;
        }

        .bar3 {
            transform: rotate(45deg) translate(-5px, -5px);
        }
    }
}
