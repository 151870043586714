.draggable {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 100;

    &-tooltip {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        transition: all 0.3s ease;
        z-index: 100;

        &::before {
            content: '';
            position: absolute;
            top: 7px;
            left: 0;
            transform: translateX(-50%);
            width: 130px;
            height: 150px;
            background-color: transparent;
            border-radius: 50%;
            box-shadow: 0 0 0 1000px rgba(0, 0, 0, 0.8);
            z-index: -1;
        }

        &__body {
            padding: 32px 16px;
            width: 100%;
            height: 100%;
            color: $white;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            text-align: center;

            &-container {
                margin-top: 50px;
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                h3 {
                    margin: 24px 0 16px 0;
                    color: $white;
                    font-weight: 800;
                }

                p {
                    font-size: 12px;
                    font-weight: 400;
                    margin: 0;
                    line-height: 18px;
                }

                .delimeter {
                    width: 160px;
                    border-top: 1px solid $semi-white;
                    margin: 24px 0;
                }
            }

            button {
                min-width: 136px;
                min-height: 40px;
            }
        }

        @media (orientation: landscape) {
            display: none;
        }
    }

    &-backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        transition: all 0.3s ease;
    }

    &-guidance {
        position: fixed;
        top: 0;
        left: 0;
        width: 20px;
        height: 100%;
        background-color: $violet-new;
        z-index: 101;
        transition: all 0.3s ease;
    }

    &-container {
        position: fixed;
        display: flex;
        width: 0%;
        height: 100%;
        transition: all 0.3s ease-in-out;
        transform: translateX(-80%);

        &--open {
            transform: translateX(0);
            width: 100%;
        }
    }

    &-item-wrapper {
        position: fixed;

        .draggable-item {
            position: fixed;
            width: 34px;
            height: 110px;
            background-color: $new-footer-violet;
            color: white;
            border: 0px;
            border-radius: 0px 12px 12px 0;
            overflow: hidden;

            .menu-icon {
                display: flex;
                height: 100%;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &-menu {
        &--slider {
            width: 80%;
            background-color: $new-footer-violet;
            padding: 32px 0;
            color: $white;
            transition: all 0.3s ease-in-out;
            overflow: hidden;

            &-open {
                padding: 16px;
            }
        }

        &--opacity {
            opacity: 0;
            transition: opacity 0.1s ease-in-out;

            &-open {
                opacity: 1;
                transition: opacity 0.3s ease-in-out 0.2s;
            }
        }

        &--header {
            transition: all 0.3s ease-in-out;
            display: flex;
            flex-direction: column;

            @media (min-width: 570px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-template-rows: repeat(1, 1fr);
            }

            .auth-slider-header {
                @media (min-width: 570px) {
                    padding: 0 11px 24px 0;
                }
            }

            &__title {
                display: flex;
                font-size: 16px;
                font-weight: 800;
                margin-bottom: 16px;

                @media (min-width: 570px) {
                    font-size: 16px;
                    font-weight: 800;
                }

                .app-loader--logo {
                    width: 140px;
                    height: 107px;
                }
            }

            &__amounts {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                @media (min-width: 570px) {
                    margin-bottom: 16px;
                    line-height: 24px;
                }
            }

            &__balance {
                text-align: center;
                font-size: 12px;
                font-weight: 700;
                margin: 0 0 8px 0;

                @media (min-width: 570px) {
                    margin: 0;
                    font-size: 12px;
                    font-weight: 700;
                    color: $white;
                }
            }

            &__bonus {
                text-align: center;
                font-size: 10px;
                font-weight: 400;
                margin-bottom: 16px;
                color: $semi-white;
                overflow: hidden;
                text-overflow: ellipsis;

                p {
                    margin: 0;
                }

                @media (min-width: 570px) {
                    margin-bottom: 0;
                    font-weight: 700;
                    color: $semi-white;
                }
            }

            &__deposit {
                text-align: center;
                font-size: 12px;
                font-weight: 400;
                color: $semi-white;
                overflow: hidden;
                text-overflow: ellipsis;

                a {
                    height: 40px;

                    p {
                        margin-left: 16px;
                        text-transform: capitalize;
                    }
                }

                @media (min-width: 570px) {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    a {
                        width: 100%;
                    }
                }
            }

            &__auth {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 32px;
                gap: 16px;

                a {
                    width: 100%;
                    height: 40px;
                    font-size: 14px;
                    font-weight: 800;
                }

                @media (min-width: 570px) {
                    .btn {
                        padding: 0 10px;
                    }

                    a {
                        max-width: 109px;
                    }
                }
            }

            .separator {
                border-top: 1px solid $loader-bar-background;
                margin: 19px 0;

                @media (min-width: 570px) {
                    display: none;
                }
            }
        }
    }
}
