.financial-page {
    &--content {
        &.wallet-container {
            .wallet-data {
                display: flex;
                gap: 70px;

                &--column {
                    display: flex;
                    flex-direction: column;
                    gap: 48px;

                    &:first-of-type {
                        flex-basis: calc(100% / 3);
                    }

                    &:last-of-type {
                        flex-basis: calc(100% / 3 * 2);
                    }

                    .form-loader .top {
                        justify-content: center;
                    }
                }

                .table {
                    border-collapse: separate;
                    border-spacing: 0 5px;
                    margin: -5px 0;

                    &.games-rounds-table {
                        thead tr th {
                            &:nth-of-type(1) {
                                width: 20%;
                            }

                            &:nth-of-type(2),
                            &:nth-of-type(3) {
                                width: 15%;
                            }

                            &:nth-of-type(4),
                            &:nth-of-type(5) {
                                width: 10%;
                            }
                        }

                        td.game-name a {
                            color: $violet-new;
                            font-size: 14px;
                            font-weight: 400;
                        }
                    }

                    &.transactions {
                        .btn-submit {
                            pointer-events: none;
                        }

                        .btn-copy {
                            border: 1px solid rgba(255, 190, 11, 1);
                        }
                    }

                    thead {
                        background: $white;
                        font-weight: 700;
                    }

                    tr {
                        height: 50px;
                    }

                    thead tr th {
                        font-weight: 700;

                        &:nth-of-type(1) {
                            width: 18%;
                        }

                        &:nth-of-type(2) {
                            width: 15%;
                        }

                        &:nth-of-type(3) {
                            width: 10%;
                        }

                        &:nth-of-type(4) {
                            width: 15%;
                        }

                        &:nth-of-type(5) {
                            width: 15%;
                        }

                        &:nth-of-type(6) {
                            width: 27%;
                        }
                    }

                    tbody tr {
                        background: $light-peach;

                        .btn {
                            height: 30px;
                            width: 100%;
                        }
                    }

                    thead tr th,
                    tbody tr td {
                        &:first-of-type {
                            text-align: left;
                            padding-left: 30px;
                            border-top-left-radius: 10px;
                            border-bottom-left-radius: 10px;
                        }

                        &:last-of-type {
                            padding-right: 30px;
                            border-top-right-radius: 10px;
                            border-bottom-right-radius: 10px;
                        }
                    }
                }

                .wallet-info-card {
                    width: 100%;
                    aspect-ratio: 1.59;
                    height: auto;
                    background: radial-gradient(at left top, #3a87ff, #ff006f);
                    border-radius: 20px;
                    z-index: 1;
                    position: relative;
                    padding: 0 24px;
                    display: flex;
                    flex-direction: column;
                    color: $white;
                    box-shadow: 0 24px 24px -12px rgba(95, 2, 187, 0.5);

                    &--row {
                        display: flex;
                        align-items: center;
                        padding: 12px 0;
                        flex-basis: calc(100% / 3);

                        .label {
                            font-size: 16px;
                        }

                        .amount {
                            font-weight: 800;
                            color: $yellow-alt;
                        }

                        &:nth-of-type(1),
                        &:nth-of-type(2) {
                            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                        }

                        &:nth-of-type(1) {
                            justify-content: space-between;
                            font-weight: 800;
                            font-size: 24px;
                        }

                        &:nth-of-type(2) {
                            .amount {
                                font-size: 32px;
                                line-height: 34px;
                            }
                        }

                        &:nth-of-type(3) {
                            .wallet-info-card--subrow {
                                width: 100%;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                > div {
                                    width: 50%;

                                    &:nth-of-type(1) {
                                        border-right: 1px solid rgba(255, 255, 255, 0.3);
                                    }

                                    &:nth-of-type(2) {
                                        padding-left: 24px;
                                    }
                                }
                            }

                            .amount {
                                font-size: 24px;
                                line-height: 26px;
                            }
                        }
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: radial-gradient(at right bottom, #3a87ff, #ff006f);
                        opacity: 0;
                        animation: fadeIn 2500ms ease-in-out 0s infinite alternate-reverse;
                        z-index: -1;
                        border-radius: inherit;
                    }
                }
            }

            .wallet-data--title {
                font-size: 32px;
                font-weight: 800;
                margin-bottom: 24px;
            }

            .games-history-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 24px;

                h3 {
                    margin: 0;
                }

                .filtering-container {
                    display: flex;
                    gap: 10px;
                    align-items: center;

                    .clear-selected-range {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        font-size: 14px;
                        font-weight: 700;
                        background: $violet-new;
                        height: 40px;
                        color: $white;
                        cursor: pointer;
                        padding: 10px 15px;
                        border-radius: 5px;

                        &:hover {
                            background: $white;
                            color: $violet-new;

                            svg path {
                                fill: $violet-new;
                            }
                        }

                        svg path {
                            fill: $white;
                        }
                    }

                    .filtering-icon {
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 40px;
                        height: 40px;
                        background: $white;
                        border-radius: 10px;
                        outline: 0;
                        border: 0;
                        padding: 0;

                        &:hover {
                            background: $violet-new;

                            svg path {
                                fill: $white;
                            }
                        }

                        .dot {
                            position: absolute;
                            top: 4px;
                            right: 4px;
                            width: 10px;
                            height: 10px;
                            background: $coral;
                            border-radius: 50%;
                        }
                    }
                }
            }

            .games-history-no-results {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 16px;
                color: $step-content;
                background: $light-peach;
                padding: 20px 20px 70px 20px;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                border-radius: 10px;

                h2 {
                    font-size: 24px;
                    font-weight: 800;
                    text-align: center;
                    line-height: normal;
                    margin: 0;
                }
            }
        }
    }

    &--data {
        &-title {
            font-size: 14px;
            margin: 0 0 8px 0;
        }

        .transaction-amount {
            margin-bottom: 12px;
            overflow: auto;

            &-extra {
                display: inline-block;
                font-size: 11px;
                font-weight: 300;
                line-height: 34px;
            }
        }

        .transaction-content-withdrawal {
            font-weight: 700;
        }

        .extra-content {
            font-size: 16px;
            font-weight: 600;
        }

        .extra-content-will-be-lost {
            font-size: 16px;
        }
    }
}

.games-history-filter-modal {
    .modal-box {
        &--close {
            opacity: 1;
        }
    }

    &--body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 32px;
        height: 100%;

        .date-range {
            display: flex;
            gap: 10px;
            align-items: center;

            .range {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;

                label {
                    color: $step-content;
                    font-size: 14px;
                    font-weight: 700;
                }

                .date-picker {
                    gap: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid $card-border;
                    border-radius: 5px;
                    width: 100%;
                    height: 42px;
                    color: $step-content;
                    font-size: 14px;
                    font-weight: 400;
                    padding: 8px;
                    cursor: pointer;

                    .MuiSvgIcon-root {
                        color: $violet-new;
                    }

                    &.active {
                        border: 1px solid $violet-new;
                    }

                    &.disabled {
                        opacity: 0.5;
                        cursor: not-allowed;
                    }
                }
            }
        }

        .date-range-calendar {
            border: 1px solid $card-border;
            border-radius: 5px;

            .MuiDateCalendar-root {
                button[aria-selected='true'] {
                    background: $violet-new;
                    color: $white;
                    border-radius: 5px;
                }

                button[aria-current='date'] {
                    border-radius: 5px;
                    border: 2px solid $violet-new;
                }

                button[disabled] {
                    color: rgba($step-content, 0.15);
                }
            }
        }

        .predefined-ranges {
            display: flex;
            gap: 10px;
            flex-direction: column;
            height: 100%;

            .header {
                color: $step-content;
                font-size: 14px;
                font-weight: 700;
            }

            .content {
                display: flex;
                align-items: center;
                gap: 2px;
                height: 30px;

                .separator {
                    height: 50%;
                    width: 1px;
                    background: $step-content;
                }

                .predefined-btn {
                    color: $violet-new;
                    font-size: 14px;
                    font-weight: 400;
                    background: transparent;
                    border: 0;
                    outline: 0;
                }
            }
        }

        .buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .btn {
                width: 100%;
            }
        }
    }
}
