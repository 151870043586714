.static {
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 32px;
        font-weight: 800;
    }

    h2 {
        margin-top: 15px;
    }

    h3 {
        margin-top: 15px;
        font-size: 18px;
        color: $font-play-tab;
    }

    a {
        font-weight: bold;
        text-decoration: underline;
    }

    li {
        margin-top: 5px;
    }
}
