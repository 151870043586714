.lobby-page {
    font-size: 14px;
    padding: 16px 0 32px;

    .title {
        padding: 8px 10px;

        .name {
            margin: 0;
        }
    }

    .form-loader {
        .top {
            justify-content: center;
        }
    }

    // Styles for welcome part
    .lobby--welcome {
        display: flex;
        flex-direction: column;
        width: 100%;

        .title-welcome {
            .name {
                display: flex;
                align-items: center;
                margin: 0;

                img {
                    position: relative;
                    top: -2px;
                    margin-right: 12px;
                    max-width: 36px;
                }

                .first-name {
                    color: $new-purple;
                }
            }
        }

        .welcome-banner-carousel {
            .banner-carousel {
                height: calc(100% + 32px);

                .carousel-slider {
                    padding-bottom: $banner-padding-bottom;

                    .control-dots {
                        padding-bottom: $banner-padding-bottom;
                    }

                    .slider-wrapper {
                        padding-bottom: $banner-padding-bottom;
                        height: calc(100% + $banner-padding-bottom);
                    }
                }

                .offer-details {
                    text-align: left;
                }
            }
        }

        .welcome-banner {
            padding: 0 10px;
            margin-bottom: 32px;
        }

        .welcome-banner,
        .welcome-banner-carousel {
            height: 300px;

            .form-loader {
                height: 100%;

                .top {
                    height: 100%;
                }
            }

            .banner {
                display: flex;
                justify-content: right;
                border-radius: 20px;
                position: relative;
                width: 100%;
                height: 100%;

                .offer {
                    position: absolute;
                    height: 100%;
                    width: max-content;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    border-radius: inherit;
                    color: $white;
                    padding: 24px 16px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    max-width: 100%;

                    &-details {
                        br.desktop {
                            display: none;
                        }

                        h1,
                        h2,
                        h3 {
                            transition: all 0.5s ease-in-out;
                        }

                        h1 {
                            font-size: 24px;
                            font-weight: 800;
                            margin: 0;
                            border-bottom: 1px solid $white;
                        }

                        h2 {
                            font-size: 16px;
                            font-weight: 800;
                            margin: 12px 0 24px;
                        }

                        h3 {
                            font-size: 14px;
                            font-weight: 600;
                            margin: 0;
                        }

                        .btn {
                            height: 30px;
                            border-radius: 100px;
                            font-size: 12px;
                            width: auto;
                            max-width: 200px;
                        }
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 0;
                        height: 100%;
                        width: 100%;
                        z-index: -1;
                        transform: translate(0, 0);
                        border-radius: 20px 0 0 20px;
                    }
                }

                .banner--bg {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: 80%;
                    border-radius: 20px;
                }
            }
        }

        .bonus-carousel {
            &-container {
                position: relative;
                z-index: 1;
            }

            .bonus-card {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height: 100%;
                height: 280px;
                padding-top: 60px;
                box-shadow: 0 32px 24px -18px rgba(228, 142, 69, 0.5);
                margin: 46px 0 0;
            }
        }
    }

    // Styles for promotions block
    .lobby--promotions {
        .promotion {
            box-shadow: 0 32px 24px -18px rgba(57, 0, 65, 0.5);
        }
    }
}

// Styles for featured games
.lobby--games,
.lobby--lives,
.live-lobby {
    .games-lobby--featured {
        padding: 0 10px;
        margin-bottom: 32px;

        .game-list {
            &-item {
                width: 100%;

                &--background {
                    img {
                        width: 100%;
                        display: inherit;
                    }
                }
            }
        }
    }
}
