.withdrawal-flow {
    &.modal--modern {
        .modal-box {
            width: 894px;
            height: 80%;
            min-height: 450px;
            border-radius: 20px;

            @media only screen and (max-width: 1080px) {
                width: 80%;
            }

            &--content {
                position: relative;
                height: calc(100% - 60px);

                .withdrawal-header-wrapper {
                    position: sticky;
                    top: 0;
                    background: $white;
                    z-index: 1;
                    padding: 0 46px;

                    .page-message {
                        margin-bottom: 20px;
                    }
                }

                .withdrawal-form-error {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    text-align: center;
                }

                #withdraw_form {
                    overflow-y: auto;
                    position: initial;

                    .financial-page--data {
                        overflow-y: initial;
                        max-height: initial;

                        &-title {
                            margin: 0 0 16px 0;
                        }
                    }

                    .container-btns {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        background-color: $white;
                        display: flex;
                        grid-gap: 24px;
                        gap: 24px;
                        right: 0;

                        &:before {
                            top: -32px;
                        }
                    }

                    .form-control {
                        line-height: 20px;
                    }
                }

                .form-group--row {
                    .transaction-amount-extra {
                        order: 3;
                        width: 100%;
                    }
                }

                .methods-list {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 12px;
                    margin: 0 0 15px 0;
                }

                .withdrawal-method {
                    position: relative;
                }

                .method-radio {
                    border-radius: 5px;
                    background-color: $white;
                    background-image: none;
                    border: 1px solid $card-border;
                    width: calc((100% - 60px) / 6);
                    height: 48px;
                    filter: grayscale(0);
                    opacity: 1;
                    margin: 0;
                    min-width: 100px;
                    display: flex;
                    justify-content: center;
                    position: relative;
                    padding: 4px;

                    .MuiButtonBase-root {
                        display: none;
                    }

                    &:hover {
                        background-color: $yellow-alt-hover;
                        border: 1px solid $yellow-alt;
                    }

                    &.checked {
                        background: $amount-bg;
                        border: 1px solid $yellow-alt;
                        transition: all 0.2s ease-in-out;
                    }

                    &:after {
                        display: none;
                    }
                }

                .wdr-data-select {
                    width: 100%;

                    .MuiSelect-select {
                        font-family: inherit;
                        padding-top: 10px;
                        max-height: 40px;
                        min-width: auto;

                        &:focus {
                            background-color: $white;
                        }
                    }
                }

                .transaction-amount {
                    overflow: hidden;

                    .financial-page--data-title {
                        margin-bottom: 12px;
                    }

                    &-extra {
                        font-weight: 400;
                        line-height: 15px;
                        margin: 8px 0;
                        display: flex;
                        flex-wrap: wrap;

                        div:last-of-type {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

#menu-email {
    .MuiList-root {
        padding: 0;
    }

    .MuiListItem-root {
        @include mui-list-item-root;
        @include font-family-nunito;
    }
}
