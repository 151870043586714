.favorite-panel {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 20px;
    height: 100%;
    background-color: $new-dark-violet;
    backdrop-filter: blur(5px);
    z-index: 0;
    color: $white;
    transition: all 0.3s ease-in-out;
    max-width: 320px;
    width: 100%;
    opacity: 0;

    &.visible {
        opacity: 1;
        z-index: 2;
    }

    &_header {
        position: sticky;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: 20px 20px 0 0;
        padding: 23px 20px;
        background-color: $new-footer-violet;

        h3 {
            margin-bottom: 0;
        }

        .close-btn {
            position: absolute;
            right: 20px;
            top: 20px;
            cursor: pointer;
            color: $white;
            font-size: 20px;
        }
    }

    &_body {
        width: 100%;
        padding: 16px 0;
        display: flex;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;

        .favorite-game {
            display: flex;
            width: 100%;
            align-items: center;
            padding: 0 16px;

            &_thumbnail {
                width: 124px;
                height: 92px;
                flex-shrink: 0;
                border-radius: 10px;
                background-size: cover;
                background-position: center;
                margin-right: 16px;

                img {
                    width: 100%;
                }
            }

            .game-info {
                margin: 13px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;

                h3 {
                    margin-bottom: 8px;
                    font-size: 16px;
                }

                p {
                    margin: 0;
                    text-transform: capitalize;
                    color: $semi-white;
                    font-size: 11px;
                    margin-bottom: 16px;
                }

                .volatility-info {
                    display: flex;
                    justify-content: space-between;

                    span {
                        display: flex;
                        flex-direction: column;
                        font-size: 12px;
                        font-weight: 700;
                        flex: 1;
                        position: relative;

                        > div {
                            .progress-bar--outer {
                                margin: 6px 0 0 0;
                            }
                        }
                    }

                    .separator {
                        border-right: 1px solid $loader-bar-background;
                        margin: 0 16px 0 0;
                    }

                    .rtp {
                        color: $light_blue;
                        font-weight: 800;
                        font-size: 14px;
                    }

                    .vlt {
                        .value {
                            position: relative;
                        }
                    }
                }
            }
        }

        .add-to-favorites {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 16px 0 32px 0;
            padding: 0 16px;

            button {
                width: 100%;
            }
        }

        .favorite-games-list {
            display: flex;
            flex-direction: column;
            overflow-y: hidden;

            &_header {
                display: flex;
                border-bottom: 1px solid $semi-white;
                font-weight: 700;
                margin: 0 16px 8px 16px;
            }

            &_body {
                overflow-y: auto;

                .favorite-game-item {
                    display: flex;
                    width: 100%;
                    transition: all 0.3s ease-in-out;

                    &:hover {
                        background-color: $violet-new;
                    }

                    .game-list-item-favorite--link {
                        width: 100%;
                        transform: scale(1);
                    }

                    .favorite-game {
                        display: flex;
                        width: 100%;
                        margin: 8px 0;

                        &_thumbnail {
                            width: 72px;
                            height: 54px;
                            flex-shrink: 0;
                            border-radius: 5px;
                            background-size: cover;
                            background-position: center;
                            margin-right: 12px;
                            display: flex;
                            align-items: center;

                            img {
                                width: 100%;
                            }
                        }

                        .game-info {
                            margin: 8px 0;
                            color: $white;

                            p {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            &_no-games {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                img {
                    width: 170px;
                    height: 170px;
                }
            }
        }
    }
}
