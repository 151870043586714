body {
    @include font-family-nunito;
    background: $main-bg url('#{$asset-path}/main_bg.jpg') repeat;
    font-size: 16px;
    color: $step-content;
    margin: 0;
    padding: 0;
    min-height: 100%;
    width: 100vw;
    position: relative;
    -webkit-overflow-scrolling: touch;
}

a {
    color: $step-content;

    &.btn-modern {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

.page-header,
.game-block-header {
    font-size: 16px;
    font-weight: 700;
    padding: 16px 12px 12px;

    :is(h1, h2, h3, h4, h5, h6) {
        font-size: 16px;
        padding: 0;
        display: block;
        line-height: 18px;
        margin-bottom: 0;
    }

    &--separator {
        position: relative;
        margin: 0 8px;

        &:before {
            content: '›';
            font-weight: 300;
        }
    }

    &--subtitle {
        font-weight: 400;
    }

    &--back {
        position: relative;

        &:after {
            content: '';
            width: 8px;
            height: 8px;
            border-top: 2px solid #4a2c10;
            border-left: 2px solid #4a2c10;
            transform: rotate(-45deg);
            position: absolute;
            top: -4px;
            left: 8px;
        }
    }

    &--extra {
        display: flex;
        justify-content: space-between;
    }
}

.main {
    min-height: 100vh;
}

.container {
    padding: 0 12px;

    &--limited {
        max-width: 754px;
        margin: 0 auto;
    }

    &--no-padding {
        padding: 0;
    }

    &--modern {
        padding: 0 10px;
    }
}

.section {
    background: rgba(255, 255, 255, 0.5);
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;
    padding: $mobile-padding;
    margin-bottom: 25px;

    &-title {
        display: block;
        text-transform: uppercase;
        font-size: 12px;
        line-height: 14px;
        margin: 0 0 6px;
        font-weight: 400;
        opacity: 0.7;

        &.how-its-work {
            color: $dark;
            font-weight: 600;
        }
    }

    &.contact-data {
        margin: 0;
    }
}

.page-message {
    padding: 10px 15px;
    background: $light;
    color: $dark;
    border-radius: 6px;
    margin: 12px;
    font-size: 14px;
    text-align: center;

    &--error {
        background: $red-error;
        color: $white;
    }

    &--demo-restricted {
        text-align: left;
        margin: 0;
        line-height: 18px;
    }

    &--success {
        background: rgba(144, 1, 139, 0.1);
        color: $light-purple;
    }

    &--warning {
        background: $yellow;
    }
}

#login_form {
    p {
        text-transform: uppercase;
        font-size: 12px;
        line-height: 14px;
        margin: 0 0 6px;
        font-weight: 400;
        opacity: 0.7;
    }

    #forgot-password-form-row button.btn--primary {
        margin-bottom: 0;
    }
}

.d-flex {
    display: flex !important;
}

.prepend-field {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    font-weight: 500;
    font-size: 24px;
    margin: 6px 0 !important;
    width: 100%;

    input {
        margin: 0 !important;
        border-radius: 0 6px 6px 0 !important;
    }
}

.violet {
    color: $main-purple;

    &-global {
        color: $light-purple;
    }
}

.scroll-navigation {
    @include hidden-scrollbar;
    list-style-type: none;
    margin: 0;
    padding: 0 10px;
    overflow-y: auto;
    white-space: nowrap;
    position: sticky;
    z-index: 2;
    background: $scroll-nav-bg;

    li {
        display: inline-block;
        font-size: 14px;
        font-weight: 700;
        color: $scroll-nav-item;

        .scroll-navigation-label {
            padding: 8px 0;
        }

        .scroll-navigation-active-line {
            height: 2px;
            background: $violet-new;
            border-radius: 2px;
        }

        &.active {
            color: $step-content;
        }

        &:not(:last-of-type) {
            margin-right: 16px;
        }
    }
}

.load-more {
    margin: 24px 0;
    position: relative;

    .btn {
        font-size: 12px !important;
        position: relative;
        min-width: 200px !important;
        margin: 0 auto;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

.breadcrumbs-container {
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 24px;

    a {
        color: $violet-new;
    }

    a + span {
        margin: 0 10px;
        color: $extra-light-bronze;
    }
}

.radio-group {
    label {
        display: flex;
        align-items: flex-start;
        margin: 0 0 16px 0;
    }

    .radio-button {
        color: $violet-new !important;
        width: 24px;
        height: 24px;
        padding: 0 !important;
        margin-right: 10px !important;

        input {
            height: 100%;
        }

        .MuiSvgIcon-root {
            width: 24px;
            height: 24px;
        }

        &-checked {
            color: $violet-new !important;

            .MuiSvgIcon-root {
                &:nth-child(2) {
                    path {
                        fill: $violet-new;
                    }
                }
            }
        }
    }

    .MuiFormControlLabel-root {
        .MuiTypography-root {
            @include font-family-nunito;
            width: 100%;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: normal;
        }
    }
}

.page--no-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
        font-size: 16px;
        font-weight: 500;
        color: $no-items-message-color;
        margin: 0;
    }
}

.panel-tabs {
    .MuiTabs-indicator {
        background: $violet-new;
    }

    &--tab {
        color: $info-text;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        text-transform: capitalize;

        &[aria-selected='true'] {
            color: $step-content;
        }
    }
}
