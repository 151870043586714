.MuiDrawer-paper {
    width: 570px;
    background: $new-dark-violet;

    &.small {
        width: 360px;

        .menu--top {
            padding: 24px;

            .logo-container {
                img:first-child {
                    height: 40px;
                    margin-right: 8px;
                }

                img:last-child {
                    height: 36px;
                }
            }
        }
    }

    &:not(.small) {
        .menu {
            padding: 24px 22px;
        }

        .menu--top {
            .logo-container {
                img:first-child {
                    margin-right: 12px;
                }
            }
        }
    }

    .menu {
        .forgot a {
            color: $new-light-violet;
        }

        &--top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .logo-container {
                cursor: pointer;
            }
        }

        &--close {
            border: 1px solid $white;
            border-radius: 50%;
            width: 32px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                width: 12px;
                height: 12px;
            }

            &:hover {
                background-color: $white;

                svg {
                    path {
                        fill: $violet-new;
                    }
                }
            }
        }

        &--section {
            padding: 16px 24px;
            border-top: 1px solid $ultra-semi-white;

            &:last-of-type {
                border-bottom: 1px solid $ultra-semi-white;
            }

            &.amount-data {
                color: $white;
                font-size: 12px;
                text-align: center;

                .amount {
                    color: $yellow-alt;
                    font-size: 16px;
                    font-weight: 800;
                }

                &:first-of-type {
                    font-size: 16px;

                    .amount {
                        font-size: 24px;
                    }
                }

                .btn {
                    margin-top: 16px;
                }
            }

            .form-loader .top {
                margin: 0;
            }
        }

        .first-deposit-info {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-bottom: 16px;
            font-size: 16px;
            font-weight: 700;

            .gift-icon {
                img {
                    width: 22px;
                }
            }

            .gift-text {
                .timer {
                    color: $yellow-alt;
                    font-weight: 800;
                }
            }
        }

        .nav-menu {
            .container-btns {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                gap: 16px;

                a {
                    flex: 1;

                    &.btn-cancel {
                        color: $white;
                        opacity: 0.7;
                        margin: 0 auto;

                        &:hover {
                            opacity: 1;
                            color: $white !important;
                            text-decoration: underline;
                        }
                    }
                }
            }

            .navigation-list {
                margin: 0;
                padding: 0;
                list-style-type: none;

                &-item {
                    a {
                        display: flex;
                        align-items: center;
                        text-align: center;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 19px;
                        transition: ease 100ms;
                        color: $white;
                        opacity: 0.5;
                        position: relative;
                        min-height: $menu-item-min-height;
                        max-height: $menu-item-max-height;
                        height: $menu-item-height;
                        padding: 0 24px;

                        svg {
                            width: 40px;
                            margin-right: 12px;
                            flex-shrink: 0;
                        }

                        &.active {
                            background: $new-purple;
                        }

                        &:hover {
                            background: $new-deep-violet;
                        }

                        &:hover,
                        &.hovered,
                        &.active {
                            opacity: 1;

                            svg {
                                path {
                                    fill-opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        .form-container {
            padding: 50px;
            color: $white;

            .form--title {
                font-weight: 800;
                font-size: 24px;
                margin-bottom: 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .info {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                    margin: initial;
                }
            }

            .form--subtitle {
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 32px;
            }

            .info {
                font-weight: 400;
                font-size: 14px;
                line-height: 19px;
                color: $semi-white;
                margin: 24px 0 32px;
            }

            .form--btns {
                display: flex;
                gap: 12px;
                margin-top: 50px;

                .btn {
                    width: 100%;
                }

                .btn-cancel {
                    color: $white;
                }
            }

            .text-field {
                @include form-control-new;
            }

            /* Hack to disable changing background color to yellow on field autocomplete */
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            textarea:-webkit-autofill:active,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus,
            select:-webkit-autofill:active {
                transition: background-color 6000s linear 6000s;
                border: 1px solid rgba(194, 152, 117, 0.3);
                -webkit-box-shadow: 0 0 0 1000px #fffbdc inset;
            }

            .form {
                &-info {
                    font-size: 14px;
                    line-height: 18px;
                    margin-bottom: 12px;
                }

                &-group {
                    position: relative;

                    label {
                        text-transform: capitalize;
                    }

                    &.password {
                        .reveal-password {
                            position: absolute;
                            width: 40px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            top: 10px;
                            right: 0;
                            cursor: pointer;
                            transform: translate(0, 50%);

                            span {
                                position: absolute;
                                width: 24px;
                                height: 4px;
                                background: $document-color;
                                border-radius: 1px;
                                border-top: solid $white 2px;
                                transform: rotate(-45deg);
                                transition: all ease-in-out 0.3s;
                            }

                            &.hide {
                                span {
                                    width: 0;
                                }
                            }
                        }
                    }

                    & + .form-group {
                        margin-top: 24px;
                    }

                    .phone-code-selector {
                        margin-right: 16px;
                    }

                    .phone-code-selector,
                    .country-selector {
                        background: $white;
                        border: 1px solid transparent;
                        border-radius: 4px;
                        height: 40px;
                        font-size: 14px;
                        line-height: 17px;
                        padding: 0;
                        flex-basis: 90px;
                        flex-shrink: 0;

                        .MuiSelect-select:not(select) {
                            padding-left: 12px;
                            padding-top: 0;
                            padding-bottom: 0;
                            font-size: 14px;

                            &:focus {
                                background: inherit !important;
                            }
                        }
                    }

                    label {
                        display: block;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 19px;
                        margin-bottom: 12px;
                    }

                    &--row {
                        display: flex;
                        flex-direction: row;
                        margin: 0 -8px;
                    }

                    &--cell {
                        width: 100%;
                        margin: 0 8px;

                        &-large {
                            width: 70%;
                        }

                        &-small {
                            width: 30%;
                        }
                    }

                    &--loading {
                        .loader {
                            width: 16px;
                            height: 16px;
                            position: absolute;
                            top: 42px;
                            right: 10px;
                            z-index: 1;
                        }
                    }

                    &--success-marked,
                    &--cell-success-marked {
                        position: relative;

                        .text-field,
                        input[type='tel'],
                        .select-wrapper {
                            background: $menu-light-green;
                            border: 1px solid $menu-green;
                        }

                        .text-field,
                        input[type='tel'] {
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus,
                            &:-webkit-autofill:active,
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus,
                            &:-webkit-autofill:active,
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus,
                            &:-webkit-autofill:active {
                                border: 1px solid $menu-green;
                                -webkit-box-shadow: 0 0 0 1000px $menu-light-green inset;
                            }
                        }
                    }

                    &--error-marked,
                    &--cell-error-marked {
                        position: relative;

                        &:after {
                            top: 44px;
                        }

                        .text-field,
                        input[type='tel'],
                        .select-wrapper {
                            background: $menu-peach;
                            border: 1px solid $menu-red;
                        }

                        .text-field,
                        input[type='tel'] {
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus,
                            &:-webkit-autofill:active,
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus,
                            &:-webkit-autofill:active,
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus,
                            &:-webkit-autofill:active {
                                border: 1px solid $menu-red;
                                -webkit-box-shadow: 0 0 0 1000px $menu-peach inset;
                            }
                        }
                    }

                    &:last-of-type {
                        margin-bottom: 16px;
                    }

                    a,
                    .link {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                        text-decoration: none;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                &-control {
                    margin-bottom: 0;
                }

                &-message {
                    display: block;
                    margin-top: 8px;
                    line-height: 15px;
                    font-size: 11px;
                    clear: both;
                    font-weight: 500;

                    &::first-letter {
                        text-transform: uppercase;
                    }

                    &--error {
                        color: $menu-red;
                    }

                    &--success {
                        color: $menu-green;
                    }
                }
            }
        }

        .MuiInput-root {
            @include font-family-nunito;
            width: 100%;
            height: 100%;
        }

        .MuiMenuItem-root {
            @include font-family-nunito;
            font-size: 14px;

            &:hover,
            &.Mui-selected {
                background-color: rgba(95, 2, 187, 0.1);
            }
        }

        .d-flex {
            display: flex;
        }

        .verification-code {
            display: flex;
            align-items: center;

            &--inputs {
                width: 100% !important;

                input[type='tel'] {
                    font-size: 24px;
                    line-height: 33px;
                    caret-color: auto;

                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }

            &--send-new-code {
                width: 110px;
            }
        }

        .phone-prefix {
            align-self: center;
            font-weight: 700;
            margin-right: 6px;
        }
    }

    .page-message {
        font-size: 14px;
        margin: 0 0 12px;
        background: transparent;
        text-align: left;
        padding: 0;

        &--success,
        &--error {
            font-size: 14px;
            margin: 0 0 12px;
        }

        &--success {
            font-weight: 700;
        }

        &--error {
            color: $menu-red;
        }
    }
}
