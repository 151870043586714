.spinner {
    width: 25px;
    height: 25px;
    border: 4px solid transparent;
    border-top: 4px solid $black;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.spinner-inner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
