.suspended-modal {
    @include font-family-nunito;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .suspended-modal-content {
        color: $step-content;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }
}
