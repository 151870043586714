.refer-a-friend {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    max-width: 1920px;
    margin: 0 auto 60px;

    .column {
        width: calc(50% - 90px);
    }

    h1 {
        font-size: 58px;
        line-height: 78px;
        font-weight: 800;
        margin-bottom: 38px;
    }

    h3 {
        font-size: 32px;
        font-weight: 800;
        margin-bottom: 25px;
    }

    p,
    li {
        font-size: 16px;
    }

    .main-img--wrapper {
        margin-bottom: 45px;
    }

    .share-link--container {
        margin-top: 45px;

        .share-link {
            padding: 25px;

            p {
                margin-bottom: 14px;
            }

            .copy-input {
                margin: 20px 0;

                input {
                    height: 40px;
                    font-size: 16px;
                    padding-right: 150px;
                }
            }
        }
    }

    .how-it-works {
        max-width: 520px;

        &--content {
            padding: 25px;

            &.phase-1 {
                padding-right: 85px;

                img {
                    right: -40px;
                }
            }

            &.phase-2 {
                margin-left: 85px;
                padding-left: 75px;

                img {
                    left: -50px;
                }
            }

            &.phase-3 {
                padding-right: 75px;

                img {
                    right: -100px;
                    bottom: -11px;
                }
            }
        }
    }
}
