header {
    position: relative; // Relative should be applied if sticky is not supported
    position: sticky;
    top: 0;
    z-index: 22;
    background: $new-dark-violet no-repeat top center;
    background-size: cover;

    .accept-privacy-policy {
        &--cookies {
            &-enabled,
            &-disabled {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 700;
                font-size: 16px;
                line-height: 18px;
                background: $new-purple;
                padding: 12px 30px;
                color: $white;
                z-index: 999999;

                a {
                    color: $new-light-violet;
                }

                .btn {
                    margin-left: 30px;
                    text-transform: capitalize;
                }

                &--description {
                    margin: 0;
                }
            }
        }
    }

    .header-container {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-cell {
            display: flex;
            align-items: center;
            height: inherit;
            flex-shrink: 0;

            &:first-child {
                flex: 1;
            }

            &:last-child {
                padding-right: 30px;
            }

            .vip-program {
                align-self: flex-start;

                &--item {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 60px;
                    padding: 0 16px;
                    border-width: 0 1px 0 1px;
                    border-color: $ultra-semi-white;
                    border-style: solid;
                }

                .vip-level-icon {
                    @include vip-level-icon--wrapper;

                    img {
                        width: 24px;
                        height: 24px;
                    }
                }

                &--hover-wrapper {
                    position: absolute;
                    left: 0;
                    top: 60px;

                    .vip-program--hover {
                        background: $new-dark-violet;
                        border-radius: 0 0 20px 20px;
                        border-top-color: red;
                        height: 60px;
                        width: max-content;
                        z-index: 11;
                        padding: 0 16px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;

                        &-title {
                            color: $white;
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 16px;
                        }

                        &-value {
                            font-weight: 800;
                            font-size: 15px;
                            line-height: 20px;
                            color: $yellow-alt;
                            white-space: nowrap;
                        }
                    }
                }
            }

            .my-wallet {
                position: relative;
                align-self: flex-start;

                &--menu {
                    background: $new-dark-violet;
                    border-radius: 0 0 20px 20px;
                    border-top-color: red;
                    width: max-content;
                    z-index: 11;
                }

                &--grid {
                    display: flex;
                    align-items: center;

                    &-item {
                        padding: 0 16px;
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        justify-content: center;
                        color: $white;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 16px;
                        height: 60px;

                        &:first-of-type {
                            border-right: 1px solid $ultra-semi-white;
                            min-width: 120px;
                            max-width: 120px;
                        }

                        .amount {
                            font-weight: 800;
                            font-size: 15px;
                            line-height: 20px;
                            color: $yellow-alt;
                            white-space: nowrap;
                        }

                        .btn-cancel {
                            color: $white;
                            opacity: 0.7;

                            &:hover {
                                opacity: 1;
                                color: $white !important;
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            .btn:not(.ftd-btn) {
                max-width: 140px;
            }

            .ftd-btn {
                padding: 0 5px;

                &:hover {
                    .first-deposit-timer {
                        .gift-icon {
                            animation: gift-hover 0.2s ease-in-out;
                            transform: translate(-50%, 0) scale(1.1) rotate(-10deg);
                        }
                    }
                }

                .btn-label {
                    padding: 0 14px;
                }

                .first-deposit-timer {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: 700;
                    color: $yellow-alt;
                    background: $ftd-bg;
                    position: relative;
                    border-radius: 20px;
                    width: 80px;
                    flex-shrink: 0;
                    height: calc(100% - 10px);
                    margin-left: 10px;

                    .gift-icon {
                        position: absolute;
                        top: 0;
                        left: 0;
                        animation: gift-default 0.2s ease-in-out;
                        transform: translate(-50%, 0) scale(1) rotate(0);

                        img {
                            width: 28px;
                        }
                    }
                }
            }
        }

        .menu-button {
            display: flex;
            flex-direction: column;
            flex-shrink: 0;
            gap: 4px;
            padding: 20px 30px;
            background: transparent;
            border: none;
            transition: background-color 200ms ease;

            div {
                height: 4px;
                border-radius: 2px;
                background: $white;
                transition: width 200ms ease;
            }

            div:nth-child(1) {
                width: 24px;
            }

            div:nth-child(2) {
                width: 14px;
            }

            div:nth-child(3) {
                width: 18px;
            }

            &:hover {
                background: $new-purple;

                div:nth-child(2),
                div:nth-child(3) {
                    width: 24px;
                }
            }
        }

        .navigation {
            --nav-indent: 30px;
            height: inherit;
            padding: 0 var(--nav-indent);
            border-width: 0 1px 0 1px;
            border-color: $ultra-semi-white;
            border-style: solid;
            flex-shrink: 0;
            overflow-y: hidden;

            li > a {
                svg {
                    height: 22px;
                }
            }

            &-list {
                list-style: none;
                display: flex;
                display: -webkit-flex;
                flex-flow: row nowrap;
                -webkit-flex-flow: row nowrap;
                flex-shrink: 0;
                gap: var(--nav-indent);
                padding: 0;
                margin: 0;
                height: inherit;

                &:after {
                    content: none;
                }

                &-item {
                    flex-grow: 0;
                    -webkit-flex-grow: 0;

                    a {
                        display: flex;
                        align-items: center;
                        text-align: center;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 19px;
                        transition: ease 100ms;
                        color: $white;
                        opacity: 0.5;
                        position: relative;

                        &:not(.menu-link) {
                            transform: translate(0, -50%);
                            top: 50%;

                            &.active {
                                &:after {
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    width: 100%;
                                    height: 40px;
                                    background: $blue;
                                    filter: blur(20px);
                                    transform: translate(0, 100%);
                                    z-index: -1;
                                }
                            }
                        }

                        svg {
                            margin-right: 12px;
                            flex-shrink: 0;
                        }

                        &:hover,
                        &.hovered,
                        &.active {
                            opacity: 1;

                            svg {
                                path {
                                    fill-opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        a.btn-modern {
            &:not(:last-child) {
                margin-right: 16px;
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    header {
        &.auth {
            .navigation {
                --nav-indent: 24px;
                max-width: 470px;

                &-list {
                    &-item {
                        min-width: 40px;

                        a {
                            display: flex;
                            flex-direction: column;

                            svg {
                                margin-right: 0;
                                height: 28px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1350px) {
    header {
        &.auth {
            .menu-button {
                padding: 20px 24px;
            }

            .navigation {
                --nav-indent: 12px;
                max-width: 430px;

                &-list {
                    &-item {
                        min-width: 40px;

                        a {
                            display: flex;
                            flex-direction: column;

                            svg {
                                margin-right: 0;
                                height: 28px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@keyframes gift-hover {
    0% {
        transform: translate(-50%, 0) scale(1) rotate(0);
    }
    100% {
        transform: translate(-50%, 0) scale(1.1) rotate(-10deg);
    }
}

@keyframes gift-default {
    0% {
        transform: translate(-50%, 0) scale(1.1) rotate(-10deg);
    }
    100% {
        transform: translate(-50%, 0) scale(1) rotate(0);
    }
}
