.bonuses-page {
    position: relative;

    h1 {
        font-size: 32px;
        font-weight: 800;
        margin-bottom: 24px;
    }

    &--loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &--bonus-list {
        list-style-type: none;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 30px;
        row-gap: 64px;
        padding: 0;
        margin: 64px 0;
    }

    &--no-bonuses {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        p {
            font-size: 24px;
            font-weight: 500;
            color: $no-items-message-color;
            margin: 0;
        }
    }
}

//Reusing styles on lobby page
.bonus-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 20px;
    background: $light-peach;
    padding: 74px 24px 8px;
    position: relative;
    box-shadow: 0 32px 32px -12px $box-shadow-peach;
    transition: all 0.3s ease-in-out;
    text-align: left;

    &--icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 32px;
        border-radius: 10px;
        background-color: $icon-card;
        width: auto;
        min-width: 80px;
        height: 80px;
        transform: translate(0, -50%);
        box-shadow: 0 5px 20px 0 $card-box-shadow;

        &#icon-blue {
            box-shadow: 0 10px 40px -10px $box-shadow-light-blue;
        }

        &#icon-green {
            box-shadow: 0 10px 40px -10px $box-shadow-light-green;
        }

        &-free-rounds {
            aspect-ratio: 1.77;
        }

        .game-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    &--status {
        position: absolute;
        top: 24px;
        right: 24px;
        color: $blue;
        font-size: 16px;
        font-weight: 700;

        &:before {
            content: '';
            position: relative;
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-right: 10px;
            border-radius: 50%;
            top: 1px;
            background: $blue;
        }
    }

    &--title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 12px;
    }

    &--progress {
        .progress-bonus {
            margin: 4px 0;
            background: $latest-winner-card-border;

            .MuiLinearProgress-bar {
                border-radius: 3px;
                background: $blue;
            }
        }

        .progress-label {
            color: $blue;
        }

        .progress-info {
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            color: $message-description;
        }
    }

    &--claim-bonus {
        margin: 44px 0 24px;
        display: flex;
        gap: 8px;
        justify-content: flex-start;

        .btn {
            max-width: 200px;
            width: 100%;
        }
    }

    &--description,
    &--limits {
        font-size: 12px;
        color: $message-description;
    }

    &--description {
        min-height: 60px;
        padding: 24px 0;
        border-top: 1px solid $border-description;
        transition: all 0.3s ease-in-out;
    }

    &--limits {
        margin-top: 12px;

        span {
            + span:before {
                content: '|';
                margin: 0 5px;
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .bonuses-page {
        &--bonus-list {
            .bonus-card {
                padding-top: 60px;

                &--claim-bonus {
                    margin: 24px 0 16px;
                }

                &--description {
                    padding: 12px 0;
                }
            }
        }
    }
}
