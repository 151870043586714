.profile-kyc {
    > .profile-data {
        display: flex;
        flex-direction: column;
        gap: 48px;

        .profile-data--details {
            margin-bottom: 0;
        }

        section {
            font-size: 16px;

            > h4 {
                font-weight: 700;
            }
        }
    }

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
    }

    &--list {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;

        > * {
            flex: 1 1 calc(50% - 24px);
            min-width: 300px;
        }
    }

    &--document-categories-list {
        .profile-kyc--document-category {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &-data {
                margin-bottom: 22px;
            }

            &-title {
                font-size: 21px;
                font-weight: 700;
                margin-bottom: 16px;
            }
        }
    }

    &--uploaded-documents {
        section {
            ul {
                padding: 0 24px;

                li {
                    font-weight: 800;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    &--how-it-works-list {
        display: flex;
        gap: 24px;

        .profile-kyc--how-it-works-step {
            font-size: 18px;
            flex-basis: calc(100% / 3);
            max-width: 200px;
            position: relative;

            &-description {
                font-weight: 700;
            }

            &:nth-of-type(1):before,
            &:nth-of-type(2):before {
                content: '';
                background: url('#{$asset-path}/kyc/arrow_step.svg') no-repeat;
                position: absolute;
                width: 50px;
                height: 16px;
                z-index: 1;
                right: 0;
            }

            &:nth-of-type(1):before {
                top: 25%;
                transform: translateX(75%);
            }

            &:nth-of-type(2):before {
                bottom: 25%;
                transform: translateX(75%) rotate3d(-0.1, 2, 0, 180deg) scale(-1, -1);
            }
        }
    }
}

.kyc-modal {
    &.modal--modern {
        .modal-box {
            width: 600px;

            &--header-back {
                @include header-back-btn;
                margin-right: 16px;
            }

            &--close:disabled {
                pointer-events: none;
            }

            &--content {
                height: 475px;
            }
        }
    }

    &.preview-document-modal {
        .modal-box {
            background: $black;
            color: $white;
            aspect-ratio: 1.2;
            width: 90%;
            max-width: 800px;
            display: flex;
            flex-direction: column;
            flex: 1 1 100%;

            &--content {
                background: none;
                color: inherit;
                display: flex;
                flex-direction: column;
                gap: 24px;
                width: 100%;
                height: 100%;

                &-view {
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 100%;
                    gap: 24px;
                }
            }

            .modal-box--header-back {
                svg {
                    path {
                        fill: $white;
                    }
                }
            }
        }

        .document-preview {
            &--info {
                &-title {
                    font-size: 16px;
                    font-weight: 800;
                    margin-bottom: 8px;
                }

                &-name {
                    font-size: 11px;
                    font-weight: 400;
                }
            }

            &--file {
                position: relative;
                align-self: center;
                height: 100%;
                width: 100%;

                img,
                embed {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                }

                img {
                    object-fit: contain;
                    object-position: top;
                }
            }
        }
    }

    .modal-box--content {
        h4 {
            font-size: 16px;
            margin-bottom: 24px;
            font-weight: 700;
        }

        &-step,
        &-view {
            width: 100%;
            height: 100%;
            overflow-y: auto;
            font-weight: 600;
        }

        &-step {
            padding-bottom: 32px;

            .radio-group {
                padding: 0;
            }
        }

        .container-btns {
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                min-width: 180px;
                width: auto;
            }
        }
    }

    &--requirements-list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            position: relative;
            padding-left: 24px;

            &:not(:last-child) {
                margin-bottom: 24px;
            }

            &:before {
                position: absolute;
                left: 0;
                content: '';
                background: url('#{$asset-path}/kyc/kyc_success.svg') no-repeat;
                background-size: contain;
                margin-right: 12px;
                vertical-align: middle;
                width: 16px;
                height: 16px;
            }
        }
    }

    &--uploading-slots-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 12px;

        .kyc-modal--uploading-slot {
            padding: 14px 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 6px;
            border-radius: 10px;
            flex-basis: 100%;
            border: 1px dashed $violet-new;
            transition: background-color 0.2s ease;

            &:not(.loading) {
                &:hover {
                    background-color: rgba($violet-new, 0.05);
                }
            }

            &.success {
                .kyc-modal--uploading-slot-drag-n-drop {
                    margin-bottom: 8px;
                    color: $toast-success;
                }
            }

            &.error {
                border-color: $toast-error;

                .kyc-modal--uploading-slot-drag-n-drop {
                    margin-bottom: 8px;
                    color: $toast-error;
                }
            }

            &-loader {
                font-weight: 700;

                .form-loader {
                    .top {
                        margin-bottom: 24px;
                    }
                }
            }

            &-title {
                font-size: 16px;
                font-weight: 800;
                margin-bottom: 14px;
            }

            &-drag-n-drop {
                display: flex;
                flex-direction: column;
                align-items: center;
                color: $violet-new;
                text-align: center;
                gap: 6px;

                &-title {
                    font-weight: 800;
                }

                &-text {
                    font-size: 11px;
                }
            }

            &-or {
                font-size: 11px;
                font-weight: 400;
            }

            &-btns {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 8px;

                button {
                    min-width: auto;
                }
            }
        }
    }

    &--quality-guide {
        &-requirements-list {
            margin-bottom: 28px;

            li:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        &-examples-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;
            gap: 16px;
        }

        &-example {
            &-file {
                margin-bottom: 12px;
            }

            &-description {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 6px;
                font-weight: 800;

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    &--main-loader {
        display: table;
        text-align: center;
        font-weight: 700;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .form-loader {
            .top {
                margin-bottom: 14px;
            }
        }
    }
}
