//TODO Replace form-control with form-control-new to apply the same styles across the app
@mixin form-control {
    @include font-family-nunito;
    font-size: 16px;
    padding: 0 $mobile-padding;
    margin-bottom: 10px;
    border-radius: $border-radius;
    outline: none;
    height: 44px;
    font-weight: 300;
    letter-spacing: 0.01em;
    width: 100%;
    background: $white;
    background-clip: padding-box;
    border: 1px solid $default-border-color;

    &:focus::placeholder {
        color: $dark !important;
        opacity: 0.4;
    }

    &:not(:focus)::placeholder {
        color: $dark;
    }

    &[disabled] {
        background: rgba(222, 214, 199, 0.2);
        background-clip: padding-box;
        color: rgba(74, 44, 16, 0.5);

        &::placeholder {
            color: rgba(74, 44, 16, 0.5);
        }
    }
}

@mixin prepend-field {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100%;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;

    input {
        margin: 0 !important;
        border-radius: 5px 0 0 5px !important;
    }

    .transaction-amount-currency,
    .prepend-field--mark {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $currency-color;
        width: 40px;
        flex-shrink: 0;
        height: inherit;
        background: $currency-bg;
        border: 1px solid $card-border;
        border-radius: 0 5px 5px 0;
        border-left: none;
    }
}

@mixin mui-list-item-root {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    height: 40px;

    &.Mui-selected,
    &:hover {
        background: $light-graphite;
    }
}

@mixin font-family-nunito {
    font-family: 'Nunito', 'Roboto', sans-serif;
}

@mixin font-family-roboto {
    font-family: 'Roboto', sans-serif;
}

@mixin form-control-new {
    @include font-family-nunito;
    font-size: 14px;
    padding: 0 12px;
    border-radius: 5px;
    border: 1px solid rgba(194, 152, 117, 0.3);
    outline: none;
    height: 40px;
    font-weight: 400;
    letter-spacing: 0.01em;
    width: 100%;
    background: $white;
    color: $dark;
    background-clip: padding-box;
    margin-bottom: 0;
}

@mixin box-shadow {
    content: '';
    position: absolute;
    height: 100%;
    border-radius: 20px;
    background: rgba(228, 142, 69, 0.5);
    filter: blur(25px);
    box-sizing: border-box;
    z-index: -1;
}

@mixin html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100vw;
    position: relative;
    background: $dark-purple;
}

@mixin hidden-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin details-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    color: $white;
    padding: 16px;
    text-align: left;
    flex: 1 1 auto;

    .game-info {
        min-width: 0;

        .game-name,
        .provider-name {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .game-name {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 8px;
        }

        .provider-name {
            font-size: 12px;
            opacity: 0.6;
        }
    }

    .btn {
        flex-shrink: 0;
        min-width: 80px;
        width: 30%;
        max-width: 140px;
    }
}

@mixin scroll-container {
    @include hidden-scrollbar;
    display: flex;
    flex-wrap: nowrap;
    overflow-y: auto;
    width: 100%;
}

@mixin reveal-password {
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 0;
    cursor: pointer;
    transform: translate(0, 50%);

    span {
        position: absolute;
        width: 24px;
        height: 4px;
        background: $document-color;
        border-radius: 1px;
        border-top: solid $white 2px;
        transform: rotate(-45deg);
        transition: all ease-in-out 0.3s;
    }

    &.hide {
        span {
            width: 0;
        }
    }
}

@mixin modern-modal-box {
    box-sizing: border-box;
    background: $white;
    color: $step-content;
    padding: 24px 16px;
    width: 100%;
    justify-content: flex-start;
    text-align: left;
}

@mixin game-list {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
}

@mixin game-list-item {
    width: calc(50% - 6px);
    border-radius: 10px;
    box-shadow: 0 5px 20px 0 $card-box-shadow;

    @media screen and (min-width: 768px) {
        width: calc(25% - 10px);
    }
}

@mixin mystery-chest-floating($rotate, $pos1: 5px, $pos2: -5px) {
    --img-rotate: #{$rotate};
    --img-pos1: #{$pos1};
    --img-pos2: #{$pos2};

    animation: floatingAnimation 4s linear infinite;
    transform: translateY(0) var(--img-rotate);

    @keyframes floatingAnimation {
        0% {
            transform: translateY(0) var(--img-rotate);
        }
        25% {
            transform: translateY(var(--img-pos1)) var(--img-rotate);
        }
        50% {
            transform: translateY(0) var(--img-rotate);
        }
        75% {
            transform: translateY(var(--img-pos2)) var(--img-rotate);
        }
        100% {
            transform: translateY(0) var(--img-rotate);
        }
    }
}

@mixin header-back-btn {
    border: none;
    background: transparent;
    padding: 0;
    transform: translate(0, 4px);

    svg {
        vertical-align: middle;
    }
}

@mixin jackpot-value($color: $white) {
    -webkit-text-stroke-color: $color;
    font-weight: 900;
    color: $white;
    paint-order: stroke fill;
    margin: 0;
    display: flex;
    align-items: center;
}

@mixin jackpot-item-mask($radius: 20px) {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: $radius;
    pointer-events: none;
    mask-image: $jackpot-mask;
}

@mixin wof-carousel-card-width($width) {
    margin-inline: -$width;

    .wof-carousel--card {
        padding: 0;
        width: calc(100% - $width * 2);

        > * {
            flex: 1;
            padding: 24px 12px;
        }
    }
}
