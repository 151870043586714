.android-app-guide {
    h4 {
        font-size: 21px;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 16px;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
    }

    .btn.btn-modern {
        width: max-content;
        height: 56px;
        font-size: 18px;
        font-weight: 800;
        padding: 4px;
        border-radius: 28px;

        span {
            margin: 0 18px 0 12px;
        }
    }

    &--banner {
        margin-bottom: 84px;

        .banner {
            height: 340px;

            .offer {
                background: $android-app-lp-banner-gradient;
                padding: 42px 50px;

                &-details {
                    width: max-content;

                    h1 {
                        font-size: 48px;
                        font-weight: 800;
                        line-height: 54px;
                        padding-bottom: 16px;
                        margin-bottom: 16px;
                    }

                    h2 {
                        font-size: 32px;
                        font-weight: 800;
                        line-height: 38px;
                        margin-bottom: 32px;
                    }

                    h3 {
                        font-size: 24px;
                        font-weight: 800;
                        margin-bottom: 16px;
                    }
                }
            }
        }
    }

    &--description {
        margin-bottom: 64px;

        .description-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 50px;

            .description-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 30px;
                max-width: 33%;

                &--visual {
                    &-phone {
                        width: 267px;
                    }
                }
            }
        }

        @media screen and (max-width: 1600px) {
            .description-container {
                gap: 30px;

                .description-item {
                    gap: 10px;

                    &--visual {
                        width: 200px;

                        &-phone {
                            width: 200px;
                        }

                        img {
                            width: inherit;
                        }

                        .slide-promotions,
                        .slide-notifications {
                            width: 168px;
                        }
                    }
                }
            }
        }
    }

    &--try-out {
        margin: 0 -75px 64px;

        .separator {
            height: 30px;
        }

        .try-out-container {
            height: 670px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 70px;

            & > div {
                max-width: 525px;
            }

            .phone {
                &-big {
                    left: 32px;
                    top: -32px;
                    z-index: 3;
                }

                &-small {
                    left: -32px;
                    top: 32px;
                    z-index: 2;
                }
            }

            .circles-animation {
                width: 330px;
                height: 330px;

                .circle1 {
                    width: 330px;
                    height: 330px;
                }

                .circle2 {
                    width: 290px;
                    height: 290px;
                }

                .circle3 {
                    width: 250px;
                    height: 250px;
                }
            }
        }
    }

    &--how-to {
        margin-bottom: 64px;

        .how-to-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            svg {
                min-width: 50px;
            }

            .how-to-item {
                max-width: 28%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                gap: 30px;

                .comment {
                    padding: 16px;
                }
            }
        }

        @media screen and (max-width: 2000px) {
            img {
                width: 180px;
            }
        }

        @media screen and (max-width: 1600px) {
            .how-to-container {
                align-items: flex-start;
                gap: 10px;

                .how-to-item {
                    flex-wrap: wrap;
                    justify-content: center;
                }

                svg {
                    position: relative;
                    top: 125px;
                }
            }
        }
    }

    @keyframes slide-promotions {
        0% {
            opacity: 0;
        }
        5% {
            opacity: 1;
            top: 39.15%;
            left: 7.78%;
        }
        33.33% {
            opacity: 1;
            top: 39.15%;
            left: 7.78%;
        }
        38.89% {
            opacity: 0;
            top: 39.15%;
            left: 7.78%;
        }
        100% {
            opacity: 0;
            top: 39.15%;
            left: 7.78%;
        }
    }
}
