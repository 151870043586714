.MuiSnackbar-root {
    font-family: 'Nunito', 'Roboto', sans-serif;
    top: 70px;
    align-items: flex-start;
    height: fit-content;
    left: 10px;
    right: 10px;
    transform: none;
}

.transaction-snackbar {
    .notification-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            display: flex;
            align-items: center;

            &:first-child {
                margin-right: 12px;

                span {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        svg {
            margin-right: 14px;
            flex-shrink: 0;
        }

        .btn-secondary {
            color: $step-content;
            font-size: 11px;
            font-weight: 800;
            border-radius: 15px;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 26px;
            padding: 0 10px;
            line-height: 12px;
        }

        .notification--close {
            border-radius: 50%;
            width: 24px;
            height: 24px;
            background: transparent;
            border: 1px solid $white;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 0 8px;

            svg {
                margin: 0;
                width: 10px;
                height: 10px;

                path {
                    fill: $white;
                }
            }
        }
    }
}

.toaster-notification {
    width: 100%;
    height: max-content;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 12px;
    color: $white;
    font-size: 12px;
    font-weight: 400;
    z-index: 1301;
    text-align: left;
    cursor: default;

    &--success {
        background: $toast-success;
    }

    &--error {
        background: $toast-error;
    }

    .notification-content--timer {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;

        .notification-timer {
            font-size: 8px;
            margin-top: 8px;
        }
    }
}

.notification-fade {
    animation: notification-fade 0.2s ease-in;
}

@keyframes notification-fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
