.container-casino-guide {
    .casino-guide:hover {
        text-decoration: underline;
    }

    .infos {
        display: block;
        border-radius: 20px;
        overflow: hidden;
        background: $light-peach;
        position: relative;
        text-decoration: none;
        padding: 20px;

        &-iframe-container {
            overflow: hidden;
        }

        .visual {
            font-size: 0;

            img {
                width: 100%;
            }
        }

        .btn {
            position: absolute;
            top: 48px;
            right: 25px;
        }
    }

    &.vip {
        margin: -44px 0 0 0;
    }
}

.casino-guide-loader {
    min-height: inherit;

    &.mobile-loader {
        height: 100vh;
    }

    .form-loader {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        min-height: inherit;
        padding: 0;
    }
}
