.amount.shadows_animation {
    color: $yellow-alt;
    animation:
        shadows 1.2s ease-in 5,
        move 1.2s ease-in 5;
}

@keyframes shadows {
    0% {
        text-shadow: none;
    }
    10% {
        text-shadow: 1px 1px 0 $amount-animation-shadow-1;
    }
    20% {
        text-shadow:
            1px 1px 0 $amount-animation-shadow-1,
            2px 2px 0 $amount-animation-shadow-2;
    }
    30% {
        text-shadow:
            1px 1px 0 $amount-animation-shadow-1,
            2px 2px 0 $amount-animation-shadow-2,
            3px 3px $amount-animation-shadow-3;
    }
    40% {
        text-shadow:
            1px 1px 0 $amount-animation-shadow-1,
            2px 2px 0 $amount-animation-shadow-2,
            3px 3px $amount-animation-shadow-3,
            4px 4px 0 $amount-animation-shadow-4;
    }
    50% {
        text-shadow:
            1px 1px 0 $amount-animation-shadow-1,
            2px 2px 0 $amount-animation-shadow-2,
            3px 3px $amount-animation-shadow-3,
            4px 4px 0 $amount-animation-shadow-4;
    }
    60% {
        text-shadow:
            1px 1px 0 $amount-animation-shadow-1,
            2px 2px 0 $amount-animation-shadow-2,
            3px 3px $amount-animation-shadow-3,
            4px 4px 0 $amount-animation-shadow-4;
    }
    70% {
        text-shadow:
            1px 1px 0 $amount-animation-shadow-1,
            2px 2px 0 $amount-animation-shadow-2,
            3px 3px $amount-animation-shadow-3;
    }
    80% {
        text-shadow:
            1px 1px 0 $amount-animation-shadow-1,
            2px 2px 0 $amount-animation-shadow-2;
    }
    90% {
        text-shadow: 1px 1px 0 $amount-animation-shadow-1;
    }
    100% {
        text-shadow: none;
    }
}

@keyframes move {
    0% {
        transform: translate(0, 0);
    }
    10% {
        transform: translate(-1px, -1px);
    }
    20% {
        transform: translate(-2px, -2px);
    }
    30% {
        transform: translate(-3px, -3px);
    }
    40% {
        transform: translate(-4px, -4px);
    }
    50% {
        transform: translate(-4px, -4px);
    }
    60% {
        transform: translate(-4px, -4px);
    }
    70% {
        transform: translate(-3px, -3px);
    }
    80% {
        transform: translate(-2px, -2px);
    }
    90% {
        transform: translate(-1px, -1px);
    }
    100% {
        transform: translate(0, 0);
    }
}
