.mfa-modal {
    &.modal--modern {
        .modal-box {
            &--content {
                height: 460px;
            }

            .mfa-modal--content-footer {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding-top: 20px;
            }
        }
    }

    &--content {
        height: calc(100% - 56px);
        overflow-y: auto;
    }
}
