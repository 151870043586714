.vip-program {
    &-benefits {
        margin-bottom: 96px;

        .benefits-table {
            font-size: 18px;
            font-weight: 500;
            table-layout: fixed;
            width: 100%;
            border-collapse: separate;
            border-spacing: 5px;
            margin: -5px -5px 32px;

            thead {
                tr {
                    th {
                        padding: 18px 24px;
                        background: $card-border;
                        border-radius: 10px;
                        text-align: left;
                        font-weight: 700;

                        &:first-of-type {
                            width: calc(100% / 6 * 2);
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 24px;
                        border-radius: 10px;

                        &:not(:first-of-type) {
                            text-align: center;
                            background: $light-peach;
                        }

                        &:first-of-type {
                            width: calc(100% / 6 * 2);
                            text-align: left;
                            background: $card-border;
                        }
                    }

                    .vertical-text {
                        writing-mode: vertical-rl;
                        text-orientation: upright;
                    }
                }
            }

            &--level {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 16px;

                &-icon {
                    @include vip-level-icon--wrapper;

                    img {
                        max-width: 45px;
                        max-height: 45px;
                        min-width: 30px;
                        min-height: 30px;
                        width: calc((45 / 1280) * 100vw);
                        height: calc((45 / 1280) * 100vw);
                    }
                }

                @media screen and (max-width: 1599px) {
                    flex-direction: column;
                    text-align: center;

                    span {
                        min-height: 49px;
                    }
                }
            }
        }
    }
}
