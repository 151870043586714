.form {
    &-info {
        margin-bottom: 16px;
    }

    &-group {
        position: relative;
        margin-bottom: 16px;

        label {
            display: block;
            margin-bottom: 5px;
        }

        &-medium {
            width: 188px;
        }

        &--title {
            display: block;
            color: #410043;
            text-transform: none;
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 10px !important;
        }

        &--row {
            display: flex;
            flex-direction: row;
        }

        &--cell {
            width: 188px;
            margin-right: 8px;
            position: relative;

            &:last-child {
                margin-right: 0;
            }

            &-error-marked:after {
                top: 10px;
            }

            &-small {
                width: 90px;
            }
        }

        &--loading {
            .loader {
                position: absolute;
                top: 30px;
                right: 9px;
                z-index: 1;
            }
        }

        &--error-marked,
        &--cell-error-marked {
            &:after {
                content: '';
                position: absolute;
                right: 9px;
                width: 14px;
                height: 14px;
                background-image: url('#{$asset-path}/cross-error.png');
                background-size: 14px 14px;
                background-repeat: no-repeat;
                animation: 0.2s fadeIn ease-in-out;
            }
        }

        &--error-marked {
            &:after {
                top: 34px;
            }
        }

        &--checkbox {
            display: flex !important;
            align-items: flex-start;
        }
    }

    &-control {
        position: relative;
        border: 1px solid $light-yellow;
        float: none;
        border-radius: 4px;
        height: 34px;
        font-size: 13px;

        &:focus {
            border-color: $main-color;
        }
    }

    &-message {
        display: block;
        margin-top: 6px;
        padding-left: 10px;
        padding-right: 0;
        line-height: 15px;
        font-size: 13px;
        clear: both;

        &--error {
            color: $red;
        }
    }

    &-loader {
        padding: 10px 18px;
        width: calc(100% - 10px);

        .top {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 32px;

            .animation {
                width: 100px;
                height: 100px;
                background: url('#{$asset-path}/loader_wild_sultan.png') center no-repeat;
                background-size: 100px 100px;
            }

            .message {
                margin-left: 30px;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
            }
        }

        .bottom {
            border-top: solid 1px $separator;
            padding-top: 32px;

            .title {
                font-weight: 800;
                font-size: 18px;
                line-height: 21px;
                margin-bottom: 20px;
            }

            .news {
                position: relative;

                > div {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    animation: topToBottom 12.5s linear infinite 0s;
                    -ms-animation: topToBottom 12.5s linear infinite 0s;
                    -webkit-animation: topToBottom 12.5s linear infinite 0s;
                    opacity: 0;
                    overflow: hidden;
                    position: absolute;

                    &:nth-child(2) {
                        animation-delay: 2.5s;
                        -ms-animation-delay: 2.5s;
                        -webkit-animation-delay: 2.5s;
                    }

                    &:nth-child(3) {
                        animation-delay: 5s;
                        -ms-animation-delay: 5s;
                        -webkit-animation-delay: 5s;
                    }

                    &:nth-child(4) {
                        animation-delay: 7.5s;
                        -ms-animation-delay: 7.5s;
                        -webkit-animation-delay: 7.5s;
                    }

                    &:nth-child(5) {
                        animation-delay: 10s;
                        -ms-animation-delay: 10s;
                        -webkit-animation-delay: 10s;
                    }

                    span {
                        margin-right: 6px;
                        margin-left: -8px;

                        &.game-name {
                            margin-left: 0;
                            font-size: 18px;
                            font-weight: 800;
                            line-height: 21px;
                        }
                    }
                }
            }
        }
    }
}

/*topToBottom Animation*/
@-moz-keyframes topToBottom {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 0;
        -moz-transform: translateY(-30px);
    }
    10% {
        opacity: 1;
        -moz-transform: translateY(0px);
    }
    25% {
        opacity: 1;
        -moz-transform: translateY(0px);
    }
    30% {
        opacity: 0;
        -moz-transform: translateY(30px);
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@-webkit-keyframes topToBottom {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 0;
        -webkit-transform: translateY(-30px);
    }
    10% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }
    25% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }
    30% {
        opacity: 0;
        -webkit-transform: translateY(30px);
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
@-ms-keyframes topToBottom {
    0% {
        opacity: 0;
    }
    5% {
        opacity: 0;
        -ms-transform: translateY(-30px);
    }
    10% {
        opacity: 1;
        -ms-transform: translateY(0px);
    }
    25% {
        opacity: 1;
        -ms-transform: translateY(0px);
    }
    30% {
        opacity: 0;
        -ms-transform: translateY(30px);
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.mini-form {
    width: 320px;
    margin: 0 auto;
}
