.android-app-guide {
    &--banner {
        .banner {
            background: url('#{$asset-path}/mobile-app-lp/top_banner_en.jpg') center right;
            background-size: cover;
            border-radius: 20px;
            box-shadow: $darkest-purple 0 40px 12px -28px;

            &.fr {
                background: url('#{$asset-path}/mobile-app-lp/top_banner_fr.jpg') center right;
            }

            .offer {
                max-width: 100%;
                height: 100%;
                color: $white;
                border-radius: 20px;

                &-details {
                    h1 {
                        color: $yellow-alt;
                        border-bottom: 1px solid $white;
                    }
                }
            }
        }
    }

    &--description {
        .description-item--visual {
            position: relative;

            .slide-promotions,
            .slide-notifications {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0;
                animation: slide-promotions infinite;
            }

            .slide-promotions {
                animation: slide-promotions 18s infinite;
            }

            .slide-notifications {
                animation: slide-notifications 12s infinite;
            }

            .slide1 {
                animation-delay: 0s;
            }

            .slide2 {
                animation-delay: 6s;
            }

            .slide3 {
                animation-delay: 12s;
            }
        }
    }

    &--try-out {
        color: $white;

        .separator {
            background: url('#{$asset-path}/mobile-app-lp/separation_line.png') 100% 100% no-repeat;
            background-size: cover;

            &.reversed {
                transform: rotate(180deg);
            }
        }

        .try-out-container {
            background: $android-app-lp-how-to-bg;

            .android-app-guide--try-out--visual {
                position: relative;

                .phone {
                    position: relative;

                    &-big {
                        animation: rotateAnimation 10s linear infinite;
                    }

                    &-small {
                        animation: rotateReverseAnimation 10s linear infinite;
                    }
                }
            }
        }

        .circles-animation {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;

            .circle {
                position: absolute;
                border-radius: 50%;
            }

            .circle1 {
                background-color: $android-app-lp-circle_bg1;
                animation: pulsate1 10s linear infinite;
            }

            .circle2 {
                background-color: $android-app-lp-circle_bg2;
                animation: pulsate2 10s linear infinite;
            }

            .circle3 {
                background-color: $android-app-lp-circle_bg3;
                animation: pulsate3 10s linear infinite;
            }
        }
    }

    &--how-to {
        .how-to-container {
            .how-to-item {
                .comment {
                    background: $android-app-lp-comment_bg;
                    color: $android-app-lp-comment_text;
                }
            }
        }

        .reversed {
            transform: rotateX(180deg);
        }
    }

    @keyframes pulsate1 {
        0%,
        100% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.05);
        }
    }

    @keyframes pulsate2 {
        0%,
        100% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
    }

    @keyframes pulsate3 {
        0%,
        100% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.15);
        }
    }

    @keyframes slide-notifications {
        0% {
            opacity: 0;
            top: 0;
            left: 0;
        }
        8.33% {
            opacity: 1;
            top: 13.5%;
            left: 8.5%;
        }
        33.33% {
            opacity: 1;
            top: 13.5%;
            left: 8.5%;
        }
        50% {
            opacity: 1;
            top: 13.5%;
            left: 8.5%;
        }
        75% {
            opacity: 0;
            top: 13.5%;
            left: 8.5%;
        }
        100% {
            opacity: 0;
            top: 0;
            left: 0;
        }
    }

    @keyframes rotateAnimation {
        0%,
        100% {
            transform: rotate(0) translateY(0%);
        }
        50% {
            transform: rotate(5deg) translateY(5%);
        }
    }

    @keyframes rotateReverseAnimation {
        0%,
        100% {
            transform: rotate(0) translateY(0%);
        }
        50% {
            transform: rotate(-5deg) translateY(-5%);
        }
    }
}
