.slider-game {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 24px;

    @media (min-width: 570px) {
        justify-content: space-between;
        border-left: 1px solid $loader-bar-background;
        margin-bottom: 0;
        padding: 0 0 24px 11px;
    }

    &--title {
        font-size: 16px;
        font-weight: 800;
        margin-bottom: 8px;
    }

    &--item {
        &_wrapper {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }

        &_provider {
            font-size: 11px;
            font-weight: 400;
            color: $semi-white;
        }
    }

    &--info {
        display: flex;
        gap: 16px;

        &_thumbnail {
            width: 72px;
            height: 54px;
            border-radius: 8px;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @media (min-width: 570px) {
                width: 104px;
                height: 78px;
            }
        }

        &_status {
            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width: 570px) {
                flex-direction: column;
            }

            .rtp {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                font-size: 12px;
                font-weight: 700;

                .value {
                    color: $new-light-violet;
                    font-size: 14px;
                }

                @media (min-width: 570px) {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            &--separator {
                border-right: 1px solid $loader-bar-background;
                margin: 0 11px;
                height: 28px;

                @media (min-width: 570px) {
                    border-right: 0;
                    border-bottom: 1px solid $loader-bar-background;
                    height: 1px;
                    width: 100%;
                    margin: 7px 0;
                }
            }

            .vlt {
                > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    gap: 8px;
                    font-weight: 700;

                    .vlt {
                        font-size: 12px;
                    }

                    .value {
                        font-size: 14px;
                        color: $new-light-violet;
                    }

                    .progress-bar--outer {
                        width: 60px;
                        margin: 0;
                    }
                }

                @media (min-width: 570px) {
                    > div {
                        align-items: center;
                        gap: 2px;
                    }
                }
            }
        }
    }
}
