.MuiDrawer-paper {
    width: 100%;
    background: $new-dark-violet;

    &.small {
        width: 270px;

        .menu-content {
            padding: 0;
            width: inherit;
        }

        .menu--top {
            padding: 16px;
            position: relative;
            height: auto;

            .logo-container {
                img:first-child {
                    height: 40px;
                    margin-right: 8px;
                }

                img:last-child {
                    height: 36px;
                }
            }
        }
    }

    .menu {
        height: 100%;

        .forgot {
            line-height: 18px !important;

            a {
                color: $new-light-violet;
            }
        }

        &-content {
            padding-top: 50px;
        }

        &--top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid $ultra-semi-white;
            padding: 10px;
            height: 50px;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            background: $new-footer-violet;
            z-index: 1;

            .logo-container {
                display: flex;
                align-items: center;

                img {
                    max-height: 32px;

                    &:first-child {
                        margin-right: 6px;
                    }
                }
            }
        }

        &--close {
            border: 1px solid $white;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            svg {
                width: 8px;
                height: 8px;
            }
        }

        &--section {
            padding: 16px;

            &:not(:first-of-type) {
                border-top: 1px solid $ultra-semi-white;
            }

            &:last-of-type {
                border-bottom: 1px solid $ultra-semi-white;
            }

            &.amount-data {
                color: $white;
                font-size: 12px;
                text-align: center;

                .amount {
                    color: $yellow-alt;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 800;
                }

                &:first-of-type {
                    font-size: 14px;

                    a {
                        font-size: 12px;
                        margin-top: 16px;
                    }
                }
            }

            .form-loader .top {
                justify-content: center;
                margin: 0;
            }

            .first-deposit-info {
                display: flex;
                flex-direction: column;
                text-align: center;
                margin-bottom: 16px;
                font-size: 14px;
                font-weight: 700;

                .gift-icon {
                    img {
                        width: 22px;
                    }
                }

                .gift-text {
                    .timer {
                        color: $yellow-alt;
                        font-weight: 800;
                    }
                }
            }
        }

        .nav-menu {
            > .navigation-list {
                padding: 10px 0 !important;
            }

            .container-btns {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 16px;

                a {
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                    font-size: 12px;

                    &.btn-cancel {
                        color: $white;
                        opacity: 0.7;
                    }
                }
            }

            .navigation-list {
                margin: 0;
                padding: 0;
                list-style-type: none;

                &-item {
                    a {
                        display: flex;
                        align-items: center;
                        text-align: center;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 19px;
                        transition: ease 100ms;
                        color: $white;
                        opacity: 0.5;
                        position: relative;
                        height: 48px;
                        padding: 0 16px;

                        svg {
                            width: 30px;
                            height: 16px;
                            margin-right: 12px;
                            flex-shrink: 0;
                        }

                        &.active {
                            opacity: 1;

                            svg {
                                path {
                                    fill-opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }

        .form-container {
            padding: 32px 16px;
            color: $white;
            overflow-y: auto;

            .form--title {
                font-weight: 800;
                font-size: 24px;
                margin-bottom: 32px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .info {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 22px;
                }
            }

            .form--subtitle {
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 32px;
            }

            .info {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: $semi-white;
            }

            .form--btns {
                display: flex;
                gap: 12px;
                position: fixed;
                bottom: 0;
                margin: 0 -16px;
                padding: 16px 16px 84px;
                width: 100%;
                background: $new-footer-violet;

                .btn {
                    height: 40px;
                    flex: 1 1 0;

                    &-cancel {
                        color: $white;
                    }
                }

                &:before {
                    content: '';
                    background: linear-gradient(180deg, #3c0b6c 0%, rgba(60, 11, 108, 0) 100%);
                    transform: matrix(1, 0, 0, -1, 0, 0);
                    position: absolute;
                    top: -24px;
                    left: 0;
                    width: 100%;
                    height: 24px;
                }
            }

            .text-field {
                @include form-control-new;
            }

            /* Hack to disable changing background color to yellow on field autocomplete */
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            textarea:-webkit-autofill:active,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus,
            select:-webkit-autofill:active {
                transition: background-color 6000s linear 6000s;
                border: 1px solid rgba(194, 152, 117, 0.3);
                -webkit-box-shadow: 0 0 0 1000px #fffbdc inset;
            }

            .form {
                &-info {
                    font-size: 14px;
                    line-height: 18px;
                    margin-bottom: 12px;
                }

                &-group {
                    position: relative;
                    margin: 0 !important;

                    label {
                        text-transform: capitalize;
                    }

                    &.password {
                        .reveal-password {
                            @include reveal-password;
                        }
                    }

                    & + .form-group {
                        margin-top: 24px !important;
                    }

                    .phone-code-selector {
                        margin-right: 16px;
                    }

                    .phone-code-selector,
                    .country-selector {
                        background: $white;
                        border: 1px solid transparent;
                        border-radius: 4px;
                        height: 40px;
                        font-size: 14px;
                        line-height: 17px;
                        padding: 0;
                        flex-basis: 90px;
                        flex-shrink: 0;

                        .MuiSelect-select:not(select) {
                            padding-left: 12px;
                            padding-top: 0;
                            padding-bottom: 0;
                            font-size: 14px;

                            &:focus {
                                background: inherit !important;
                            }
                        }
                    }

                    label {
                        display: block;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 19px;
                        margin-bottom: 12px;
                        opacity: 1;
                    }

                    &--row {
                        display: flex;
                        flex-direction: row;
                        margin: 0 -8px;
                    }

                    &--cell {
                        width: 100%;
                        margin: 0 8px;

                        &-large {
                            width: 70%;
                        }

                        &-small {
                            width: 30%;
                        }
                    }

                    &--loading {
                        .loader {
                            width: 16px;
                            height: 16px;
                            position: absolute;
                            top: 42px;
                            right: 10px;
                            z-index: 1;
                        }
                    }

                    &--success-marked,
                    &--cell-success-marked {
                        position: relative;

                        .text-field,
                        input[type='tel'],
                        .select-wrapper {
                            background: $menu-light-green;
                            border: 1px solid $menu-green;
                        }

                        .text-field,
                        input[type='tel'] {
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus,
                            &:-webkit-autofill:active,
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus,
                            &:-webkit-autofill:active,
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus,
                            &:-webkit-autofill:active {
                                border: 1px solid $menu-green;
                                -webkit-box-shadow: 0 0 0 1000px $menu-light-green inset;
                            }
                        }
                    }

                    &--error-marked,
                    &--cell-error-marked {
                        position: relative;

                        &:after {
                            top: 40px;
                        }

                        .text-field,
                        input[type='tel'],
                        .select-wrapper {
                            background: $menu-peach;
                            border: 1px solid $menu-red;
                        }

                        .text-field,
                        input[type='tel'] {
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus,
                            &:-webkit-autofill:active,
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus,
                            &:-webkit-autofill:active,
                            &:-webkit-autofill,
                            &:-webkit-autofill:hover,
                            &:-webkit-autofill:focus,
                            &:-webkit-autofill:active {
                                border: 1px solid $menu-red;
                                -webkit-box-shadow: 0 0 0 1000px $menu-peach inset;
                            }
                        }
                    }

                    &:last-of-type {
                        margin-bottom: 16px;
                    }

                    a,
                    .link {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 17px;
                        text-decoration: none;
                    }
                }

                &-control {
                    margin-bottom: 0;
                }

                &-message {
                    display: block;
                    margin-top: 8px;
                    line-height: 15px;
                    font-size: 11px;
                    clear: both;
                    font-weight: 500;

                    &::first-letter {
                        text-transform: uppercase;
                    }

                    &--error {
                        color: $menu-red;
                    }

                    &--success {
                        color: $menu-green;
                    }
                }
            }
        }

        .MuiInput-root {
            @include font-family-nunito;
            width: 100%;
            height: 100%;
        }

        .MuiMenuItem-root {
            @include font-family-nunito;
            font-size: 14px;

            &.Mui-selected {
                background-color: rgba(95, 2, 187, 0.1);
            }
        }

        .d-flex {
            display: flex;
        }

        .verification-code {
            display: flex;
            align-items: center;

            &--inputs {
                width: 100% !important;

                input[type='tel'] {
                    font-size: 24px;
                    line-height: 33px;
                    caret-color: auto;

                    &:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }

            &--send-new-code {
                width: 110px;
            }
        }

        .phone-prefix {
            align-self: center;
            font-weight: 700;
            margin-right: 6px;
        }
    }

    .page-message {
        font-size: 14px;
        margin: 0 0 12px;
        background: transparent;
        text-align: left;
        padding: 0;

        &--success,
        &--error {
            font-size: 14px;
            margin: 0 0 12px;
        }

        &--success {
            font-weight: 700;
        }

        &--error {
            color: $menu-red;
        }
    }
}
