.btn {
    border: none;
    padding: 0 16px;
    line-height: 30px;
    letter-spacing: 0.02em;
    vertical-align: top;
    border-radius: 8px;
    font-size: 12px;
    height: 30px;
    outline: 0;
    display: inline-block;
    white-space: nowrap;
    cursor: pointer;

    &:disabled {
        opacity: 0.6;
    }

    &.btn-submit {
        background: $yellow;
        color: $violet-submit;

        &.btn-modern {
            background: $yellow-alt;
            color: $step-content;

            &:hover:not(:disabled) {
                color: $white;
                background: $orange;
            }
        }

        &.btn-send-code {
            position: relative;
            margin-left: 20px;
            min-width: 110px;
        }

        &:hover:not(:disabled) {
            background: $dark-yellow;
        }
    }

    &.btn-cancel {
        background: $white;
        border: solid 1px $default-border-color;
        color: $default-border-color;

        &.btn-modern {
            border: none;
            color: $step-content;
            background: transparent;

            &:hover:not(:disabled) {
                color: $violet-new;
            }
        }

        &.bonus {
            color: $dark-violet;
        }

        &:hover:not(:disabled):not(.btn-modern) {
            color: $light-violet;
            border-color: $light-violet;
        }
    }

    &.btn-purple {
        background: $light-violet;
        color: $white;

        &.btn-modern {
            border: 1px solid $violet-new;
            background: $new-dark-purple;

            &:hover:not(:disabled) {
                background: $violet-new;
            }
        }

        &:hover:not(:disabled) {
            background: $dark-violet;
        }
    }

    &.btn-primary {
        background: $light-violet;
        color: $white;

        &:hover:not(:disabled) {
            background: $dark-violet-submit;
        }
    }

    &.btn-large {
        font-size: 15px;
        padding: 0 22px;
        height: 40px;
        line-height: 40px;
        border-radius: 8px;
    }

    &.btn-modern {
        border-radius: 20px;
        white-space: normal;
        font-weight: 800;
        font-size: 14px;
        line-height: 19px;
        min-width: 140px;
        height: 40px;
    }

    &--rounded {
        @include font-family-nunito;
        background: $yellow-alt;
        text-align: center;
        display: inline-block;
        vertical-align: top;
        box-shadow: 0 10px 25px 0 rgba(187, 126, 0, 0.5);
        border-radius: 30px;
        height: 56px;
        min-width: 200px;
        border: none;
        font-weight: 800;
        font-size: 18px;
        line-height: 25px;
        color: $step-content;
        transition: 0.3s;

        &:hover {
            background-color: $orange;
            color: $white;
        }
    }

    &.btn-copy {
        background: none;
        border: 1px solid $yellow-alt;

        &:hover:not(:disabled) {
            background-color: $light-graphite;
        }
    }

    &.btn-decline {
        background: $orange;
        border: 1px solid $orange;
        color: $white;

        &:hover:not(:disabled) {
            background: $btn-decline-hover;
            border: 1px solid $btn-decline-hover;
        }
    }
}
