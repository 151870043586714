.deposit-modal {
    .modal-box {
        @include modern-modal-box;
        padding-right: 0;
        padding-left: 0;

        &--content {
            position: relative;
            height: 100%;
            padding: 0;
            display: flex;
            width: inherit;
            border-radius: 20px;
            transition:
                width,
                height 0.3s ease-in-out;
            font-size: 14px;

            .form-loader {
                display: flex;
                justify-content: center;
                flex: 1;
            }

            .step-content {
                position: relative;
                overflow-y: auto;
                overflow-x: hidden;
                flex: 1;

                #deposit_form {
                    .form-group {
                        margin-bottom: 32px;

                        &--checkbox-set {
                            > .checkbox-container:not(:last-child) {
                                margin-bottom: 16px;
                            }
                        }
                    }
                }

                .deposit-transition {
                    height: 100%;
                }

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                span[role='link'] {
                    cursor: pointer;
                    color: $violet-new;
                    font-weight: 600;

                    &.disabled {
                        pointer-events: none;
                    }

                    + span[role='link'] {
                        margin-left: 16px;
                    }
                }

                section {
                    margin-bottom: 24px;

                    .section-label {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px solid $card-border;
                        padding-bottom: 8px;
                        font-weight: 700;
                    }
                }
            }

            &--inner {
                display: flex;
                flex-direction: column;
                width: 100%;

                .modal-box {
                    &--header {
                        display: flex;
                        align-items: flex-start;
                        justify-content: space-between;
                        padding: 0 16px 38px;

                        &-back {
                            @include header-back-btn;
                        }

                        > div {
                            display: flex;

                            &:first-child {
                                gap: 16px;
                                align-items: flex-start;
                            }

                            &:last-child {
                                gap: 24px;
                                align-items: center;
                                justify-content: flex-end;

                                .progress-deposit {
                                    width: 120px;
                                    margin: 0;
                                    height: 4px;
                                    border-radius: 4px;

                                    .MuiLinearProgress-bar {
                                        background: $violet-new;
                                        border-radius: 8px;
                                    }
                                }
                            }
                        }

                        .modal-box--title {
                            color: inherit;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 800;
                            line-height: normal;
                            margin: 0;
                        }

                        .modal-box--close {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50px;
                            border: 1px solid $step-content;
                            width: 32px;
                            height: 32px;
                            opacity: 1;
                            background: none;
                            padding: 0;

                            &:before {
                                content: none;
                            }
                        }
                    }
                }
            }
        }

        form {
            .prepend-field {
                @include prepend-field;
                margin: 0 !important;

                &--mark {
                    .payment-provider-method {
                        img {
                            vertical-align: middle;
                            transform: scale(0.8);
                        }
                    }
                }
            }

            .form-group {
                .checkbox-container {
                    font-size: 14px;
                }

                label.checkbox-container {
                    font-weight: 600;
                    display: flex;
                }

                label:not(.checkbox-container) {
                    font-weight: 700;
                    margin-bottom: 16px;

                    &[for='deposit_cvv_field'] + div {
                        position: relative;
                    }
                }

                .form-control {
                    height: 40px;
                    margin-bottom: 0;
                    font-size: 14px;

                    &.error {
                        color: $light-red;
                    }
                }

                &--row,
                &--column,
                &--cell {
                    margin: 0;
                }

                &--column {
                    gap: 24px;
                }

                &--cell {
                    width: auto;
                }
            }

            .form-message {
                font-size: 14px;
                padding-left: 0;
            }

            .bank-card-data {
                flex-wrap: wrap;
                row-gap: 24px;
                column-gap: 16px;

                .form-group--cell {
                    flex-wrap: wrap;

                    &:nth-child(1) {
                        flex-basis: 100%;
                    }

                    &:nth-child(2),
                    &:nth-child(3) {
                        flex: 1;
                    }
                }
            }

            .voucher-item {
                gap: 16px;

                .form-group--cell {
                    flex: 1;
                }
            }

            .btn-voucher-action {
                height: 40px;
                width: 40px;
                background-color: $violet-new;
                color: $white;
            }

            .reveal-cvv {
                @include reveal-password;
                transform: none;
                top: 0;
            }

            .deposit-form--amount {
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                padding-bottom: 16px;
                border-bottom: 1px solid $card-border;
                margin-bottom: 32px;

                > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    flex: 1;

                    &:first-child {
                        padding-right: 32px;
                    }

                    &:last-child {
                        align-items: flex-end;
                        border-left: 1px solid $border-color;
                        padding-left: 32px;
                        text-align: right;
                    }
                }

                .change-amount--active {
                    position: relative;
                    z-index: 2;
                    flex: 1;
                    gap: 8px;
                    right: 0;

                    .prepend-field {
                        width: 100%;
                    }

                    .btn {
                        width: 100%;
                    }

                    .form-group {
                        margin: 0 !important;

                        .form-message {
                            white-space: initial;
                            text-align: left;
                        }
                    }
                }

                .change-amount--inactive {
                    .amount-value {
                        font-size: 21px;
                        font-weight: 800;
                    }
                }
            }
        }

        .deposit-view-container {
            height: 100%;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 16px;

            &:not(.expanded) {
                padding-bottom: 60px;
            }

            &.blurry {
                overflow-y: hidden;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    background: rgba($white, 0.5);
                }
            }

            .toaster-notification {
                position: absolute;
                border-radius: 10px;
                bottom: 0;
                left: 16px;
                width: calc(100% - 32px);
                box-shadow: none;
            }

            ul {
                list-style-type: none;
                width: 100%;
                padding: 0;
                margin: 0;

                &[class*='--list'] {
                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 12px 0;
                        gap: 12px;
                        border-bottom: 1px solid $card-border;

                        &:last-child {
                            border-bottom: none;
                        }

                        .btn {
                            height: 30px;
                            min-width: 60px;
                        }
                    }
                }
            }

            .container-btns {
                display: flex;
                justify-content: center;
                gap: 24px;
                position: absolute;
                background-color: $white;
                width: 100%;
                left: 0;
                bottom: 0;
                padding: 20px 16px 0;
                margin: 0 auto;
                border-radius: 0;
                z-index: 2;

                .btn {
                    height: 40px;
                    flex: 1;

                    &.apple-pay-btn {
                        border-radius: 100px;
                        width: 100%;
                    }
                }
            }
        }

        .deposit-pending-withdrawals {
            &--notification {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $violet-new;
                border-radius: 10px;
                padding: 12px;
                color: $white;
                font-size: 12px;
                font-weight: 400;
                gap: 16px;

                > div:first-child {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    svg {
                        flex-shrink: 0;
                    }

                    strong {
                        font-weight: 800;
                    }

                    br + span {
                        display: none;
                    }
                }

                .btn--check-withdrawals {
                    border-radius: 20px;
                    background: transparent;
                    border: 1px solid $white;
                    font-weight: 800;
                    font-size: 12px;
                    height: auto;
                    min-height: 24px;
                    line-height: normal;
                    width: 110px;
                }
            }

            &--list {
                li {
                    .wdr-primary {
                        position: relative;
                        flex: 1;

                        &--data {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .wdr-amount {
                                display: flex;
                                gap: 12px;
                                align-items: center;
                                font-weight: 800;
                                font-size: 21px;
                                line-height: 19px;
                            }

                            .wdr-extra {
                                font-size: 12px;
                                line-height: 14px;
                                margin-top: 8px;

                                .wdr-status {
                                    color: $blue;
                                }
                            }
                        }

                        &--message {
                            display: flex;
                            align-items: center;
                            text-align: justify;
                            position: absolute;
                            gap: 16px;
                            top: 0;
                            left: 0;
                            width: inherit;
                            height: 100%;
                            z-index: 2;

                            > div:last-child {
                                display: flex;

                                .btn-submit {
                                    width: 90px;
                                    overflow: hidden;
                                    white-space: nowrap;
                                    text-overflow: ellipsis;
                                }
                            }
                        }
                    }
                }
            }
        }

        .deposit-bonus {
            &--selected-bonus {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 0;

                span[role='link'] {
                    text-align: right;
                }
            }

            &--selected-bonus,
            &--list {
                .bonus-card--limits {
                    font-size: 11px;
                    color: $extra-light-bronze;
                    margin-top: 8px;
                }

                .bonus-name {
                    font-size: 16px;
                    font-weight: 800;
                }

                .bonus-description {
                    font-size: 11px;
                    margin-top: 8px;
                }

                .btn-bonus {
                    width: 120px;
                    flex-shrink: 0;
                    color: inherit;

                    &:not(.btn-submit) {
                        border: 1px solid rgba($extra-light-bronze, 0.3);
                        background-color: $white;
                    }

                    &.btn-submit {
                        border: 1px solid $yellow-alt;
                    }
                }
            }
        }

        .first-deposit-bonus-message {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 12px;
            border-radius: 10px;
            background: $violet-new;
            animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97);
            animation-delay: 0.25s;
            color: $white;
            font-size: 12px;

            br {
                display: none;
            }

            .box {
                display: grid;
                grid-template-columns: 36px 1fr;
                grid-column-gap: 12px;
                align-items: center;
                flex: 1;

                > div:last-child {
                    text-align: left;
                }

                .gift-icon {
                    background: linear-gradient(180deg, $blue 0%, $violet-new 100%);
                    box-shadow: 0 5px 12px $box-shadow-gift;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    animation: rotate-gift 1.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
                    animation-delay: 1s;

                    img {
                        position: relative;
                        transform: scale(0.25);
                    }
                }

                .gift-title {
                    font-weight: 800;
                }

                .gift-text {
                    line-height: normal;
                    font-weight: 400;

                    .violet-global {
                        color: $white;
                        font-weight: 800;
                    }

                    &--secondary {
                        align-self: center;
                        color: $scroll-nav-item;
                        font-size: 12px;
                    }
                }
            }

            .gift-time-left {
                font-weight: 800;
                font-size: 21px;
                line-height: normal;
                text-align: center;
                min-width: 90px;
            }
        }

        .deposit-payment {
            form {
                display: flex;
                flex-direction: column;
                gap: 30px;

                > div {
                    flex: 1;
                }
            }

            #deposit_first_step {
                .change-method {
                    span[role='link'] {
                        &:last-child {
                            margin-left: 0;
                            text-align: right;
                        }
                    }
                }
            }

            &--title {
                padding-bottom: 16px;
                margin-bottom: 16px;
            }

            .form-group {
                margin: 0;

                .form-control {
                    margin-bottom: 0;
                }
            }

            &--method {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                justify-content: space-between;
                gap: 16px;

                > *:nth-child(1),
                > *:nth-child(2):not(span[role='link']) {
                    flex: 1;
                }

                > *:nth-child(3) {
                    flex-basis: 100%;
                }

                .no-payment-methods {
                    color: $error-message;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                    flex-basis: 50%;

                    + * {
                        min-width: fit-content;
                        max-width: max-content;
                    }
                }

                .change-method {
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                    align-items: flex-start;

                    span[role='link'] {
                        &.single-link {
                            width: 100%;
                        }
                    }
                }
            }

            &--methods {
                display: flex;
                flex-wrap: wrap;
                gap: 8px;

                &.bank-cards-list {
                    @include scroll-container;
                    padding: 0 16px;
                    margin: 0 -16px;
                    width: calc(100% + 32px);

                    li {
                        &.method-wrapper {
                            width: 45%;
                            align-items: flex-start;

                            > div:first-child {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .bank-card--actions {
                                    align-self: flex-start;

                                    svg {
                                        height: 16px;
                                    }
                                }
                            }

                            .method-name {
                                text-align: left;
                            }
                        }
                    }

                    .payment-option {
                        .payment-provider-method {
                            margin-right: 12px;
                        }
                    }
                }

                li {
                    border-radius: 6px;
                    border: 1px solid $card-border;
                    background: $white;
                    flex-shrink: 0;

                    &.method-wrapper {
                        position: relative;
                        width: calc((100% - 8px) / 2);
                        min-width: 92px;
                        height: 90px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        border: 1px solid $card-border;
                        border-radius: 6px;
                        padding: 12px;
                        transition: all 0.1s;

                        &.active {
                            background-color: $payment-method-hover;
                            border: 1px solid $yellow-alt;
                        }

                        .method-name {
                            text-align: center;
                            font-size: 11px;
                            font-weight: 700;
                        }

                        .x1-hint {
                            position: absolute;
                            top: 8px;
                            right: 8px;
                            cursor: pointer;
                        }
                    }
                }

                .bank-card-icon {
                    > div:first-child {
                        display: none;
                    }
                }
            }

            &--amount {
                display: flex;
                gap: 12px;

                .form-group {
                    flex: 1;
                    max-width: 145px;

                    .prepend-field {
                        margin-top: 0 !important;

                        .form-control {
                            color: inherit;
                            font-size: 21px;
                            font-weight: 800;
                            height: 40px;
                            line-height: 17px;
                        }
                    }

                    .form-message {
                        white-space: nowrap;
                    }
                }

                .amount-limits {
                    white-space: nowrap;
                }

                .transaction-amount-extra {
                    font-size: 11px;
                    color: $extra-light-bronze;
                    display: inline-block;
                    margin-top: 6px;

                    + .transaction-amount-extra:before {
                        content: '|';
                        margin: 0 5px;
                    }
                }

                .amount-range {
                    display: flex;
                    margin-bottom: 12px;
                    gap: 8px;
                    flex: 1.5;

                    .transaction-amount-btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 40px;
                        background: $white;
                        border: 1px solid $card-border;
                        border-radius: 5px;
                        color: inherit;
                        font-weight: 800;
                        font-size: 12px;
                        line-height: 19px;
                        padding: 0;
                        width: 100%;

                        &.active {
                            background: $amount-bg;
                            border: 1px solid $yellow-alt;
                        }
                    }
                }
            }
        }

        .deposit-x1-information {
            > * {
                margin-bottom: 24px;
            }

            .x1-description {
                font-weight: 700;

                .payment-provider-method {
                    margin-bottom: 24px;
                    flex-shrink: 0;

                    img {
                        height: 80px;
                    }
                }
            }
        }

        a {
            color: $violet-new;
        }

        .bank-card-icon {
            display: block;
            font-size: 16px;
            font-weight: 800;

            &--title {
                width: max-content;
                margin-bottom: 8px;
            }

            &--combined {
                display: flex;
                align-items: center;
                gap: 8px;

                .payment-provider-method {
                    img {
                        width: 32px;
                    }

                    &.visa {
                        img {
                            width: 60px;
                        }
                    }
                }
            }

            + .form-group {
                margin: 0;
            }
        }
    }

    .modal-result {
        position: relative;
        width: 100%;

        .deposit-view-container {
            > div:first-child {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;

                h2 {
                    font-size: 24px;
                    font-weight: 800;
                    margin: 16px 0 0;
                }
            }
        }
    }
}

.payment-option {
    height: inherit;
    display: flex;
    align-items: center;
}

.validate-bonus-modal {
    .bonus-card--limits {
        font-size: inherit;
        color: inherit;
        margin: 0;
    }
}

.first-deposit-preview {
    .modal-box--title {
        display: none;
    }

    .modal-box--content {
        height: 100% !important;
    }

    .container-btns {
        flex-direction: column;

        &:before {
            top: -32px !important;
        }
    }

    .first-deposit-offer {
        border-radius: 6px;
        background: $white;
        margin: 0 16px;

        .violet-global {
            color: $violet-new;
            font-weight: 800;
        }

        .box {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 24px 0;
            color: $dark;

            &:first-child {
                width: 100%;
                padding: 0;
            }

            .gift-icon {
                background: linear-gradient(180deg, $blue 0%, $violet-new 100%);
                box-shadow: 0 5px 12px $box-shadow-gift;
                width: 74px;
                height: 74px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 24px;

                img {
                    position: relative;
                    transform: scale(0.52);
                }
            }

            .gift-time-left {
                font-weight: 800;
                font-size: 28px;
                line-height: normal;
                color: $violet-new;
                text-align: center;
                margin-bottom: 16px;
            }

            .gift-title {
                font-weight: 800;
                font-size: 14px;
                line-height: normal;
                margin-bottom: 16px;
                color: $violet-new;
            }

            .gift-text {
                line-height: 21px;

                &--primary {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 21px;
                }

                &--secondary {
                    font-size: 10px;
                    font-weight: 400;
                    color: $scroll-nav-item;
                }
            }
        }
    }
}

.first-deposit-offer-bubble-container {
    position: fixed;
    margin: 10px 20px;
    right: 0;
    bottom: 130px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 1200; // Less then modal z-index

    .first-deposit-offer-bubble {
        border-radius: inherit;
        width: inherit;
        height: inherit;
        background: linear-gradient(180deg, $blue 0%, $violet-new 100%);
        box-shadow: 0 2px 10px rgba(74, 44, 16, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        animation: rotate-gift 3s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
        animation-delay: 1s;

        .gift-timer {
            transform: translate(0, -17px);

            .gift-icon {
                margin-bottom: -5px;

                img {
                    width: 29px;
                    position: relative;
                }
            }
        }
    }

    .gift-time {
        font-weight: 800;
        font-size: 11px;
        color: $white;
        text-align: center;
        transform: translate(0, -25px);
    }
}

@keyframes shake {
    0%,
    100% {
        transform: rotate(0deg);
    }
    15%,
    45% {
        transform: rotate(5deg);
    }
    30%,
    60% {
        transform: rotate(-5deg);
    }
    75% {
        transform: rotate(2deg);
    }
    90% {
        transform: rotate(-2deg);
    }
}

@keyframes rotate-gift {
    70%,
    100% {
        transform: rotate(0deg) scale(1);
    }
    80% {
        transform: rotate(15deg) scale(1.05);
    }
    90% {
        transform: rotate(-15deg) scale(0.95);
    }
}
