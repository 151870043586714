footer {
    background: $new-footer-violet;
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;

    .footer {
        &-links-block {
            &-list {
                column-gap: 30px;
                row-gap: 32px;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                height: fit-content;
                padding: 0 0 32px;
                border-bottom: 1px solid $ultra-semi-white;
                margin: 0;
                list-style-type: none;

                li {
                    overflow: hidden;

                    &:not(:last-of-type) {
                        margin-bottom: 16px;
                    }

                    &:nth-of-type(2) {
                        order: 5;
                    }

                    &:nth-of-type(3) {
                        order: 9;
                    }

                    &:nth-of-type(4) {
                        order: 2;
                    }

                    &:nth-of-type(5) {
                        order: 6;
                    }

                    &:nth-of-type(6) {
                        order: 10;
                    }

                    &:nth-of-type(7) {
                        order: 3;
                    }

                    &:nth-of-type(8) {
                        order: 7;
                    }

                    &:nth-of-type(9) {
                        order: 11;
                    }

                    &:nth-of-type(10) {
                        order: 4;
                    }

                    &:nth-of-type(11) {
                        order: 8;
                    }

                    &:nth-of-type(12) {
                        order: 12;
                    }

                    &:nth-of-type(13) {
                        order: 13;
                    }

                    &:nth-of-type(14) {
                        order: 14;
                    }

                    & > a {
                        display: inline-block;
                        color: $new-light-violet;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 19px;

                        &:hover {
                            color: $white;
                        }
                    }
                }
            }
        }

        &-pre-footer {
            padding: 48px 75px;
            color: $pre-footer-color;

            h1 {
                font-weight: 800;
                font-size: 32px;
                margin-bottom: 32px;
                color: $white;
            }

            &-content {
                font-size: 14px;
                line-height: 21px;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 70px;

                a {
                    color: inherit;
                    text-decoration: underline;
                    font-weight: bold;
                }

                .footer-description {
                    font-size: 16px;
                    font-weight: 400;
                    text-align: justify;
                    color: $white;
                }

                .expandable-data-list {
                    display: flex;
                    flex-direction: column;
                    gap: 12px;
                    min-width: 650px;

                    > div {
                        border-radius: 10px;
                        border: 1px solid $pre-footer-table-border;
                        padding: 16px;
                        cursor: pointer;
                        transition: background-color 0.3s ease-in-out;

                        &:not(.expanded):hover {
                            background: $pre-footer-table-head-bg;
                        }

                        &.expanded {
                            h2 {
                                img {
                                    transform: rotate(-180deg);
                                }
                            }
                        }

                        table {
                            font-size: 12px;
                            text-align: center;
                            width: 100%;
                            margin-bottom: 16px;

                            th,
                            td {
                                border: 1px solid $pre-footer-table-border;
                                padding: 2px;
                            }

                            th {
                                font-weight: 700;
                                background: $pre-footer-table-head-bg;
                            }

                            td {
                                height: 40px;
                            }
                        }

                        h2 {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: 12px;
                            text-align: left;
                            margin: 0;
                            font-size: 18px;
                            font-weight: 700;
                            color: $new-light-violet;

                            img {
                                transition: transform 0.3s ease-in-out;
                            }
                        }

                        .info {
                            margin: 32px 16px 16px;
                        }
                    }

                    ol,
                    ul {
                        padding: 8px 16px;
                        margin: 0 0 16px;
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                    }

                    blockquote {
                        margin: 0;
                    }

                    h3,
                    h4,
                    h5,
                    h6 {
                        margin: 16px 0;
                    }

                    h3 {
                        font-size: 17px;
                    }

                    h4 {
                        font-size: 16px;
                    }

                    h5 {
                        font-size: 15px;
                    }

                    h6 {
                        font-size: 14px;
                        margin-bottom: 6px;
                    }
                }
            }
        }

        &-content {
            color: rgba(246, 236, 221, 0.3);
            border-top: 1px solid $pre-footer-content-border;
            font-size: 12px;
            padding: 48px 75px;
            display: flex;
            gap: 120px;

            .footer-providers-block {
                width: 510px;
                flex-shrink: 0;

                .title {
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 33px;
                    color: $white;
                    margin-bottom: 32px;
                }

                .payment-providers {
                    padding-bottom: 24px;
                    margin-bottom: 32px;
                    border-bottom: 1px solid $ultra-semi-white;

                    ul {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                        display: flex;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        gap: 16px 32px;

                        .payment-provider-method {
                            img {
                                opacity: 0.5;
                                max-width: 80px;
                            }
                        }
                    }
                }

                .mobile-app-reference {
                    padding-bottom: 24px;
                    margin-bottom: 32px;
                    border-bottom: 1px solid $pre-footer-content-border;

                    &--content {
                        display: flex;
                        gap: 30px;
                        font-size: 14px;

                        p {
                            margin: 0 0 12px;
                            color: $white;
                        }

                        .read-more {
                            color: $new-light-violet;
                            font-weight: 500;

                            &:hover {
                                color: $white;
                            }
                        }
                    }
                }

                .select-language {
                    &--list {
                        margin: 0;
                        padding: 0;
                        list-style-type: none;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 12px;

                        button {
                            outline: none;
                            background: transparent;
                            border-radius: 50%;
                            border: 1px solid $violet-new;
                            width: 50px;
                            height: 50px;
                            text-transform: uppercase;
                            font-weight: 800;
                            font-size: 14px;
                            line-height: 19px;
                            color: $white;
                            flex-shrink: 0;
                            padding: 0;

                            &:hover {
                                background: $new-dark-purple;
                            }

                            &.active {
                                background: $violet-new;
                            }
                        }
                    }
                }
            }
        }

        &-licence {
            padding: 32px 0 24px;
            color: $new-light-violet;

            p {
                font-weight: 300;
                font-size: 12px;
                line-height: 21px;
                margin: 0;
                text-align: justify;

                & + p {
                    font-size: 10px;
                    line-height: 18px;
                    margin-top: 24px;
                    color: $pre-footer-color;
                }
            }
        }

        &-icons {
            margin: 0 auto;
            text-align: center;
            display: flex;
            align-items: center;

            &--icon {
                margin: 0 6px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }

            &--gamblersanon {
                background-image: url('#{$asset-path}/gamblersanon.png');
                width: 68px;
                height: 36px;
                display: inline-block;
                opacity: 0.6;
            }

            &--gamecare {
                background-image: url('#{$asset-path}/gamecare.png');
                width: 26px;
                height: 36px;
                opacity: 0.6;
            }

            &--18plus {
                background-image: url('#{$asset-path}/18%2B.png');
                width: 28px;
                height: 36px;
                opacity: 0.6;
            }

            &--curacao {
                img {
                    height: 85px;
                }
            }

            &--inactive {
                pointer-events: none;
            }
        }
    }
}
