.vip-program {
    &-benefits {
        margin-bottom: 32px;

        > ul {
            @include scroll-container;
            list-style-type: none;
            gap: 10px;
            padding: 0 10px;
            margin: 0 -10px;
            width: calc(100% + 20px);

            li {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 240px;
                flex-shrink: 0;
                border-radius: 20px;
                background: $light-peach;
                padding: 32px 16px 16px;
                gap: 32px;

                .vip-program-benefits--level-info {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    img {
                        width: 90px;
                        height: 90px;
                        margin-bottom: 16px;
                    }

                    h1 {
                        text-align: center;
                        margin: 0;
                    }
                }

                .vip-program-benefits--level-buttons {
                    width: 100%;

                    button {
                        height: 40px;
                        width: 100%;

                        &:first-of-type {
                            margin-bottom: 12px;
                        }
                    }
                }

                .no-buttons-text {
                    font-weight: 800;
                    padding: 10px 0;
                }
            }
        }
    }
}

.comparable-levels-modal {
    &.modal--modern {
        .modal-box {
            padding: 24px 10px;

            .modal-box--title {
                text-align: left;
                padding: 0 46px 0 6px;

                .modal-box--close {
                    right: 6px;
                }
            }

            .modal-box--content {
                .data-container {
                    max-height: 100%;
                }

                .container-btns {
                    padding: 0 6px;
                }
            }
        }
    }
}

.benefits-table {
    font-size: 12px;
    font-weight: 500;
    table-layout: fixed;
    width: 100%;
    border-collapse: separate;
    border-spacing: 6px;

    thead {
        tr {
            th {
                padding: 12px;
                background: $card-border;
                border-radius: 10px;
                text-align: left;
                font-weight: 700;

                &:not(:first-of-type) {
                    .benefits-table--level {
                        justify-content: flex-start;
                    }
                }
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 12px;
                border-radius: 10px;

                &:not(:first-of-type) {
                    text-align: center;
                    background: $light-peach;
                }

                &:first-of-type {
                    text-align: left;
                    background: $card-border;
                }
            }
        }
    }

    &--level {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;

        &-icon {
            padding: 3px;
            border-radius: 50%;
            background-color: $light-peach;
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 22px;
                height: 22px;
            }

            + span {
                text-align: center;
            }
        }
    }
}

.comparable-table-widget {
    position: fixed;
    bottom: 60px;
    left: 10px;
    display: flex;
    align-items: center;
    max-height: 48px;
    border-radius: 20px;
    box-shadow: 0 5px 10px 0 $vip-widget-box-shadow;
    background: $light-peach;
    padding: 8px;
    gap: 8px;
    z-index: 1;

    .img-container {
        display: flex;
        align-items: center;

        .img-wrapper {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            width: 30px;
            height: 30px;

            img {
                width: 100%;
            }
        }

        span {
            margin: 0 6px;
        }
    }

    .container-btns {
        .btn {
            height: 30px;
            font-size: 12px;
        }
    }

    &-close {
        border-radius: 50%;
        border: 1px solid $copy-input-color;
        background: transparent;
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        svg {
            width: 8px;
            height: 8px;
        }
    }

    @media screen and (max-width: 400px) {
        .btn {
            min-width: auto;
            max-width: 100px;
        }
    }
}
