.push-notifications {
    position: relative;
    display: flex;
    align-items: center;

    svg {
        width: 34px;
        height: 34px;

        circle,
        path {
            transition: all 0.15s ease-in-out;
        }

        circle {
            fill: $new-footer-violet;
        }
    }

    &:not(.unread-messages) {
        svg {
            path {
                fill-opacity: 0.5;
            }
        }
    }
}

.push-notifications--bubble {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 50%;
    width: 9px;
    height: 9px;
    background-color: $coral;
}

.push-notifications-modal.modal--modern {
    .modal-box {
        background-color: $new-footer-violet;
        backdrop-filter: blur(5px);
        display: block;
        padding: 24px 0 0;

        &--title {
            color: $white;
            padding: 0 16px;
        }

        &--content {
            background: transparent;
            padding: 0 16px 24px;
            overflow-y: auto;
        }

        &--close {
            border: 1px solid $white;
            background: transparent;
            right: 16px;

            svg path {
                fill: $white;
            }
        }

        .push-notifications-empty {
            text-align: center;
            font-size: 16px;
            font-weight: 700;
        }

        .push-notifications--lists {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 24px;
            overflow-y: auto;
            color: $white;

            .push-notifications--list-title {
                font-size: 12px;
                font-weight: 400;
                margin-bottom: 5px;
            }

            .push-notifications--list-unread {
                width: 100%;

                ul {
                    border-top: 1px solid $pre-footer-content-border;
                    border-bottom: 1px solid $pre-footer-content-border;
                    padding: 24px 0;
                }
            }

            ul {
                width: 100%;
                list-style-type: none;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 24px;

                li {
                    display: flex;
                    align-items: flex-start;
                    gap: 12px;

                    .message {
                        &-icon {
                            position: relative;
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                            background-color: $notifications-violet;
                            flex-shrink: 0;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            img {
                                width: 22px;
                            }
                        }

                        &-info {
                            width: 100%;

                            > div:first-child {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                margin-bottom: 12px;
                            }

                            .cta-link {
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }

                        &--title {
                            font-size: 16px;
                            font-weight: 700;
                            margin: 0;
                        }

                        &--time,
                        &--content {
                            font-size: 12px;
                            font-weight: 400;
                        }

                        &--time {
                            flex-shrink: 0;
                            color: rgba($white, 0.5);
                        }
                    }
                }
            }
        }
    }
}
