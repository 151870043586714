.financial-page {
    &--content {
        .no-transactions {
            padding: 0 10px;
            font-size: 14px;
        }

        .transaction {
            &-list {
                margin-bottom: 25px;

                &--item {
                    display: flex;
                    font-size: 14px;
                    min-height: 40px;
                    height: auto;
                    border-bottom: 1px solid $border-color;
                    padding: 12px 0;
                    gap: 20px;

                    > div {
                        flex-basis: 50%;
                    }

                    &:first-of-type {
                        border-top: 1px solid $border-color;
                    }

                    .transaction-info {
                        display: flex;
                        flex-direction: column;
                        text-align: left;

                        &--amount {
                            font-weight: 700;
                            font-size: 16px;
                        }

                        &--data {
                            font-size: 11px;
                            margin-bottom: 4px;

                            .transaction-status {
                                &.success {
                                    color: $status_validated;
                                }

                                &.failure {
                                    color: $menu-red;
                                }

                                &.timeout {
                                    color: $violet-new;
                                }

                                &.pending {
                                    color: $blue;
                                }
                            }
                        }
                    }

                    .transaction-action {
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        gap: 16px;
                        color: $scroll-nav-item;
                        font-size: 12px;

                        .btn {
                            width: 100%;
                            height: 30px;
                            font-size: 12px;
                            line-height: 14px;
                        }
                    }
                }
            }
        }

        .financial-page--data {
            .x1-method {
                display: flex;
                margin-bottom: 0px;
            }

            .transaction-limits {
                font-size: 12px;
                color: $dark;
            }

            .bonus {
                &-list {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin-bottom: 6px;
                }

                &-radio {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-basis: calc(50% - 6px);
                    padding: 10px;
                    border-radius: 6px;
                    min-height: 96px;
                    box-shadow: inset 0 0 0 1px $default-border-color;
                    background: $white;
                    margin: 0 0 6px;

                    .MuiTypography-root {
                        font-size: inherit !important;
                        font-weight: 500 !important;
                    }

                    &.no-bonus {
                        text-transform: uppercase;
                        color: $default-border-color;
                        min-height: 44px;
                        transition: all 0.15s;

                        .MuiTypography-root {
                            display: flex;
                            align-items: center;
                        }

                        .icon-close {
                            width: 22px;
                            height: 22px;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            background: $default-border-color;
                            color: $white;
                            border-radius: 50%;
                            margin-right: 8px;
                            position: relative;
                            transition: all 0.15s;

                            &:before,
                            &:after {
                                position: absolute;
                                left: 10px;
                                content: ' ';
                                height: 14px;
                                width: 2px;
                                background-color: $white;
                            }

                            &:before {
                                transform: rotate(45deg);
                            }

                            &:after {
                                transform: rotate(-45deg);
                            }
                        }

                        &.checked {
                            .icon-close {
                                background: $light-purple;
                            }
                        }
                    }

                    .MuiRadio-root {
                        display: none;
                    }

                    &.checked {
                        box-shadow: inset 0 0 0 1px $light-purple;
                        background: rgba(144, 1, 139, 0.05);
                        border-color: $light-purple;
                        color: $light-purple;
                        transition: all 0.15s;
                    }

                    .MuiTypography-root {
                        line-height: normal;
                        font-size: 13px;
                    }
                }
            }

            .how-its-work {
                display: flex;

                img {
                    width: 50px;
                    margin-right: 12px;
                    height: 32px;
                    margin-top: 12px;
                }

                p {
                    font-weight: 400;
                    font-size: 12px;
                }
            }

            .how-its-work--text p {
                font-weight: 400;
                font-size: 12px;
            }

            a {
                color: $light-violet;
            }

            .form-group--cell {
                margin: 0;
                margin-bottom: 10px;
            }
        }

        .deposit-info {
            margin-top: 12px;
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: $dark-gray-2;
            text-align: center;
        }

        .pending-withdrawals {
            .transaction-list {
                &--item {
                    border: none;
                    padding: 16px 0;
                    font-size: 14px;
                    min-height: 40px;
                    height: auto;
                    border-bottom: 1px solid $border-color;

                    &:first-of-type {
                        border-top: 1px solid $border-color;
                    }

                    & + .transaction-list--item {
                        margin-top: 12px;
                    }

                    .transaction-info {
                        &--data {
                            margin: 0;

                            .transaction-date {
                                font-weight: 300;
                                font-size: 14px;
                                margin-bottom: 4px;
                            }

                            .transaction-status {
                                text-align: left;
                                color: $light-purple;
                                font-size: 10px;
                                line-height: 12px;
                                font-weight: 500;
                            }
                        }

                        &--amount {
                            text-align: right;
                            color: $light-purple;
                            font-weight: 700;
                            font-size: 32px;
                            line-height: 37px;
                        }
                    }

                    .transaction-action {
                        margin-top: 8px;

                        .btn {
                            font-size: 12px;
                            height: 32px;
                            line-height: 32px;
                        }
                    }
                }
            }
        }
    }
}

.extra-content {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 20px;

    &-will-be-lost {
        font-size: 14px;
    }

    &-payout-method {
        font-size: 14px;
    }
}

.transaction-content-withdrawal {
    font-weight: 700;
    font-size: 14px;
}

.wallet-container {
    .form-loader .top {
        justify-content: center;
    }

    .wallet-data--section {
        margin: 24px 0 32px;
        padding: 0 10px;
    }

    .games-history-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
        height: 32px;

        h3 {
            font-weight: 800;
            font-size: 18px;
            margin: 0;
        }

        .filtering-container {
            display: flex;
            gap: 10px;
            align-items: center;
            height: 100%;

            .clear-selected-range {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 14px;
                font-weight: 700;
                background: $violet-new;
                height: 32px;
                color: $white;
                cursor: pointer;
                padding: 10px 15px;
                border-radius: 5px;

                &:hover {
                    background: $white;
                    color: $violet-new;

                    svg path {
                        fill: $violet-new;
                    }
                }

                svg path {
                    fill: $white;
                }
            }

            .filtering-icon {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 32px;
                height: 32px;
                background: $white;
                border-radius: 10px;
                outline: 0;
                border: 0;
                padding: 0;

                &:hover {
                    background: $violet-new;

                    svg path {
                        fill: $white;
                    }
                }

                .dot {
                    position: absolute;
                    top: 4px;
                    right: 4px;
                    width: 6px;
                    height: 6px;
                    background: $coral;
                    border-radius: 50%;
                }
            }
        }
    }

    .games-history-list {
        display: flex;
        flex-direction: column;
        gap: 20px;

        &--item {
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-bottom: 1px solid $border-color;
            padding-bottom: 20px;

            &:first-of-type {
                padding-top: 20px;
                border-top: 1px solid $border-color;
            }

            .row {
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: space-between;

                .date-session {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    height: 16px;

                    .date-time,
                    .session-id {
                        font-size: 12px;
                        font-weight: 400;
                    }

                    .separator {
                        height: 100%;
                        width: 1px;
                        background: $border-color;
                    }
                }

                .bet {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    color: rgba($step-content, 0.5);
                    font-size: 12px;
                    font-weight: 400;

                    .amount {
                        font-weight: 700;
                        color: $step-content;
                    }
                }

                .game-name a {
                    color: $violet-new;
                    font-size: 16px;
                    font-weight: 700;
                }

                .win {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    color: rgba($step-content, 0.5);
                    font-size: 12px;
                    font-weight: 400;

                    .amount {
                        font-size: 16px;
                        font-weight: 700;
                        color: $step-content;
                    }
                }
            }
        }
    }

    .games-history-no-results {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        color: $step-content;
        background: $light-peach;
        padding: 20px 20px 70px 20px;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        border-radius: 20px;
        text-align: center;

        h2 {
            font-size: 24px;
            font-weight: 800;
            line-height: normal;
            margin: 0;
        }
    }

    .wallet-data--title {
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 16px;
    }
}

.wallet-info-card {
    width: 100%;
    aspect-ratio: 3 / 2;
    height: auto;
    background: radial-gradient(at left top, #3a87ff, #ff006f);
    border-radius: 20px;
    z-index: 1;
    position: relative;
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    color: $white;
    box-shadow: 0 24px 24px -12px rgba(95, 2, 187, 0.5);
    max-width: 400px;
    margin: 0 auto;

    &--row {
        display: flex;
        align-items: center;
        padding: 12px 0;
        flex-basis: calc(100% / 3);

        .label {
            font-size: 16px;
        }

        .amount {
            font-weight: 800;
            color: $yellow-alt;
        }

        &:nth-of-type(1),
        &:nth-of-type(2) {
            border-bottom: 1px solid $transparent-white;
        }

        &:nth-of-type(1) {
            justify-content: space-between;
            font-weight: 800;
            font-size: 24px;

            img {
                width: 34px;
                height: 34px;
            }
        }

        &:nth-of-type(2) {
            .amount {
                font-size: 24px;
            }
        }

        &:nth-of-type(3) {
            .wallet-info-card--subrow {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                > div {
                    width: 50%;

                    &:nth-of-type(1) {
                        border-right: 1px solid $transparent-white;
                    }

                    &:nth-of-type(2) {
                        padding-left: 16px;
                    }
                }
            }

            .label {
                font-size: 12px;
            }

            .amount {
                font-size: 16px;
            }
        }
    }

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: radial-gradient(at right bottom, #3a87ff, #ff006f);
        opacity: 0;
        animation: fadeIn 2500ms ease-in-out 0s infinite alternate-reverse;
        z-index: -1;
        border-radius: inherit;
    }

    + .controls {
        display: flex;
        margin: 24px 0;
        gap: 16px;

        .btn {
            height: 48px;
            flex-basis: 50%;
        }
    }
}

.games-history-filter-modal {
    .modal-box {
        &--close {
            opacity: 1;
        }
    }

    &--body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;

        .date-range {
            display: flex;
            gap: 10px;
            align-items: center;

            .range {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 100%;

                label {
                    color: $step-content;
                    font-size: 14px;
                    font-weight: 700;
                }

                .date-picker {
                    gap: 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: 1px solid $card-border;
                    border-radius: 5px;
                    width: 100%;
                    height: 42px;
                    color: $step-content;
                    font-size: 14px;
                    font-weight: 400;
                    padding: 8px;
                    cursor: pointer;

                    .MuiSvgIcon-root {
                        color: $violet-new;
                    }

                    &.active {
                        border: 1px solid $violet-new;
                    }

                    &.disabled {
                        opacity: 0.5;
                        cursor: not-allowed;
                    }
                }
            }
        }

        .date-range-calendar {
            border: 1px solid $card-border;
            border-radius: 5px;
            font-size: 14px;

            .MuiDateCalendar-root {
                button[aria-selected='true'] {
                    background: $violet-new;
                    color: $white;
                    border-radius: 5px;
                }

                button[aria-current='date'] {
                    border-radius: 5px;
                    border: 2px solid $violet-new;
                }

                button[disabled] {
                    color: rgba($step-content, 0.15);
                }
            }
        }

        .predefined-ranges {
            display: flex;
            gap: 10px;
            flex-direction: column;
            height: 100%;

            .header {
                color: $step-content;
                font-size: 14px;
                font-weight: 700;
            }

            .content {
                display: flex;
                align-items: center;
                gap: 2px;
                height: 30px;

                .separator {
                    height: 50%;
                    width: 1px;
                    background: $step-content;
                }

                .predefined-btn {
                    color: $violet-new;
                    font-size: 14px;
                    font-weight: 400;
                    background: transparent;
                    border: 0;
                    outline: 0;
                }
            }
        }

        .buttons {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .btn {
                width: 100%;
            }
        }
    }
}
