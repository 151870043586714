.jackpot-wrapper {
    background: $jackpot-background;
    position: relative;
    z-index: 1;
    background: url('#{$asset-path}/jackpot/jackpot-bg-pattern.png') repeat;
}

.jackpot-main-wrapper {
    position: relative;
    top: -85px;

    .form-loader {
        margin: 80px auto 0;
        width: fit-content;
    }

    &__failed {
        margin-top: 80px;
    }
}

.jackpot-header {
    padding-top: 16px;
    padding-bottom: 80px;
    position: relative;
    z-index: 0;
    box-shadow: 0 50px 50px -20px $jackpot-shadow;

    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background: linear-gradient(to bottom, rgba(37, 0, 89, 0) 0%, rgba(37, 0, 89, 1) 100%);
        pointer-events: none;
        z-index: 999;
    }

    img {
        display: block;
        margin: 0 auto;
        padding-left: 44px;
        padding-right: 44px;
        width: 100%;
        max-width: 700px;
    }

    video {
        position: absolute;
        top: 0;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.jackpot-list {
    display: flex;
    gap: 10px;
    max-width: 100%;
    margin: 0 auto;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    padding: 120px 10px 10px 10px;

    @media (min-width: 765px) {
        padding-top: 174px;
    }

    &.jackpot-banner-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
        padding: 0;
        margin: 0;
        overflow: visible;
        width: 100%;
    }
}

.jackpot-item {
    padding: 64px 0 14px 0;
    background: $jackpot-background;
    border-radius: 20px;
    position: relative;
    min-width: 172px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .jackpot-item__value {
        white-space: nowrap;
        font-size: 24px;
        -webkit-text-stroke-width: 4px;

        & > span {
            margin-bottom: 2px;
        }
    }

    .jackpot-item__amount {
        @include font-family-nunito;
    }

    &.jackpot-banner-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 0 12px 0;
        border-radius: 12px;
        width: 100%;
        min-width: auto;

        .odometer-digit-spacer {
            padding: 0 1px;
        }

        .odometer-value {
            @include font-family-nunito;
            padding: 0 1px;
        }

        &::before {
            @include jackpot-item-mask(12px);
        }

        .jackpot-item__value {
            font-size: 18px;
            -webkit-text-stroke-width: 3px;
        }

        img {
            display: block;
            max-height: 62px;
            object-fit: contain;
            max-width: 90px;
        }
    }

    &::before {
        @include jackpot-item-mask();
    }

    .jackpot-item-logo {
        bottom: 106px;
        position: absolute;

        @media (min-width: 765px) {
            bottom: 130px;
        }
    }

    .jackpot-item-logo-label {
        max-width: calc(100% - 30px);
    }

    img {
        max-width: 100%;
    }

    &.jackpot-item-mini {
        box-shadow: 0px -5px 20px 5px $jackpot-mini-shadow;

        &::before {
            border: 1px solid $jackpot-mini-color;
        }

        .jackpot-item__value {
            @include jackpot-value($jackpot-mini-color);
        }
    }

    &.jackpot-item-minor {
        box-shadow: 0px -5px 20px 5px $jackpot-minor-shadow;

        &::before {
            border: 1px solid $jackpot-minor-color;
        }

        .jackpot-item__value {
            @include jackpot-value($jackpot-minor-color);
        }
    }

    &.jackpot-item-major {
        box-shadow: 0px -5px 20px 5px $jackpot-major-shadow;

        &::before {
            border: 1px solid $jackpot-major-color;
        }

        .jackpot-item__value {
            @include jackpot-value($jackpot-major-color-2);
        }
    }

    &.jackpot-item-grand {
        box-shadow: 0px -5px 20px 5px $jackpot-grand-shadow;

        &::before {
            border: 1px solid $jackpot-grand-color;
        }

        .jackpot-item__value {
            @include jackpot-value($jackpot-grand-color);
        }
    }
}

.jackpot-rain-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: $black;
    margin: 22px auto 32px auto;
    border-radius: 25px;
    padding: 0 10px;
    width: calc(100% - 20px);

    img {
        max-width: 130px;
    }

    &__text {
        color: $white;
        display: flex;
        gap: 2px;

        p {
            margin: 0;
            font-size: 10px;

            &:nth-child(2) {
                font-weight: 800;
            }
        }
    }
}

.jackpot-games-wrapper {
    h3 {
        color: $white;
        font-size: 18px;
        font-weight: 800;
        padding-left: 10px;
    }

    .game-list {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
    }

    .game-list-item {
        width: calc(50% - 6px);
        border-radius: 10px;
        box-shadow: 0 5px 20px 0 rgba(48, 22, 0, 0.3);
    }

    .jackpot-games-wrapper__link {
        color: $jackpot-link-color;
        font-size: 12px;
        font-weight: 800;
        text-align: center;
        display: block;

        &:hover {
            text-decoration: underline;
        }
    }

    .no-results-message {
        color: $white;
    }
}

.jackpot-how-it-works-wrapper {
    max-width: 1216px;
    margin: 32px auto 0;
    border-radius: 20px;
    box-shadow: 0px -5px 20px 5px $jackpot-grand-shadow;
    display: flex;
    flex-direction: column;
    background: $jackpot-background;
    position: relative;
    width: calc(100% - 20px);

    &::before {
        @include jackpot-item-mask();
        border: 1px solid $jackpot-grand-color;
    }

    .jackpot-how-it-works {
        color: $white;
        padding: 32px 24px 24px 32px;
        margin-top: 0;

        h3 {
            font-size: 18px;
            font-weight: 800;
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 12px;

            li {
                font-size: 14px;
                margin: 0;
            }
        }
    }

    img {
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }
}

.jackpot-terms-wrapper {
    color: $white;
    padding: 32px 10px;
    background: $jackpot-mobile-gradient;

    h3 {
        font-size: 18px;
        margin-bottom: 24px;
    }

    & > div {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    ol {
        list-style: none;
        margin: 0;
        padding-left: 16px;
    }

    li {
        counter-increment: item;

        h4 {
            font-size: 14px;
            font-weight: 800;
            margin-bottom: 6px;
            margin-left: -16px;

            &::before {
                content: var(--jackpot-terms-item-count) '.';
                font-weight: 800;
                padding-right: 6px;
            }
        }

        ul {
            padding-left: 0;
            list-style-type: disc;
            margin-bottom: 16px;
            font-size: 12px;
        }

        &:last-of-type {
            ul {
                margin-bottom: 0;
            }
        }
    }
}

.jackpot-failed-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    font-size: 14px;
    font-weight: 500;
    margin-top: 16px;

    &.alt-color {
        color: $jackpot-alt-color;
    }

    img {
        max-width: 140px;
    }

    .jackpot-failed-lobby {
        font-size: 14px;
        font-weight: 500;
        color: $jackpot-mobile-failed-lobby-color;
        padding: 0 10px;
        text-align: left;
        margin-top: 0;
    }

    .jackpot-failed {
        max-width: 400px;
        padding-left: 52px;
        padding-right: 52px;
        text-align: center;
    }
}

.jackpot-banner-container {
    margin-top: 15px;
    padding: 0 10px;
}

.jackpot-banner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 16px;
    background: url('#{$asset-path}/jackpot/jackpot-bg-pattern.png') repeat;
    border-radius: 20px;
    margin-bottom: 33px;
    box-shadow: 0 5px 20px 0 $jackpot-banner-shadow;

    .jackpot-banner-redirect-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
    }

    .btn {
        margin-left: 27px;
    }

    .jackpot-banner-logo {
        max-height: 80px;
    }

    svg {
        flex-shrink: 0;
    }
}

.jackpot-lobby-wrapper {
    margin-top: 20px;
}

.odometer-digit-spacer {
    padding: 0 2px;
}

.odometer-value {
    @include font-family-nunito;
    padding: 0 2px;
}
