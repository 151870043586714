.error-page {
    min-height: 100vh;

    .container {
        padding: 0 15px;
        width: 100%;
        min-height: 100vh;
        max-width: 1490px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;

        & > div {
            flex-basis: 50%;
            flex-shrink: 0;
            flex-grow: 1;
        }
    }

    &--content {
        text-align: left;
        padding-right: 10px;

        &.maintenance {
            text-align: center;

            .error-page--description {
                max-width: none;
                text-align: center;
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: center;

        [class^='btn'] {
            & + [class^='btn'] {
                font-size: 18px;
                margin-left: 20px;
            }
        }
    }

    &--image {
        margin-bottom: 160px;
        text-align: left;
        max-width: 720px;

        img {
            max-width: 100%;
        }

        &--maintenance {
            margin-bottom: 0;
        }
    }

    &--image-main {
        position: relative;
        z-index: 2;
    }

    &--image-shadow {
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    &--image-description {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: $info-text;
        text-align: right;
        padding-right: 10%;
    }

    &--image-container {
        position: relative;
        margin-bottom: 32px;
        cursor: pointer;

        .canvas {
            position: absolute;
            width: 64%;
            height: 60%;
            right: 0;
            bottom: 0;
            z-index: 3;
        }
        &--forbidden,
        &--maintenance {
            cursor: default;
        }
    }

    &--big-title {
        font-weight: 800;
        font-size: 140px;
        line-height: 1.2;
        margin-left: -7px;
        display: inline-block;
        vertical-align: top;
        background: linear-gradient(263.55deg, $blue 0%, #ff006e 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    &--message {
        font-weight: 800;
        font-size: 32px;
        line-height: 44px;
        margin-bottom: 32px;
        color: $step-content;
    }

    &--description {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: $step-content;
        max-width: 710px;
        margin-bottom: 28px;
    }

    &--video {
        mix-blend-mode: screen;
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        visibility: hidden;

        &.visible {
            visibility: visible;
            z-index: 5;
        }

        @media (min-aspect-ratio: 16/9) {
            height: 300%;
            top: -100%;
        }

        @media (max-aspect-ratio: 16/9) {
            width: 300%;
            left: -100%;
        }

        @supports (object-fit: cover) {
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            object-fit: cover;
        }
    }
}
