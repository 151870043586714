.promotions {
    position: relative;
    padding: 24px 10px;

    .panel-tabs {
        margin: 12px 0;

        .MuiTabs-flexContainer {
            gap: 12px;
        }
    }

    .container-promotions {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-auto-rows: 1fr;
        grid-row-gap: 20px;
        margin: 12px 0 32px 0;

        &.vip {
            margin-top: -24px;
        }
    }

    .load-more {
        margin-bottom: 48px;
    }

    .page-header {
        margin: 0;
        padding: 0 0 4px;
        font-size: 18px;
        font-weight: 800;
    }
}

.promotion {
    .visual {
        position: relative;

        .promo-plate-list {
            position: absolute;
            top: 12px;
            right: 12px;
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            gap: 6px;
        }

        img {
            width: 100%;
        }
    }

    .infos {
        padding: 16px;
        gap: 16px;

        &-text-container {
            min-width: 0;
            text-align: left;

            .promo-title {
                font-size: 16px;
                line-height: 30px;
                margin: 0;
            }
        }
    }
}

.no-promotion-content {
    margin-top: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    padding: 0 12px;
}

.no-promotion-content-button {
    display: flex;
    justify-content: center;
    margin-top: 12px;
}

.promotion-modal {
    &.modal--modern {
        .modal-box {
            padding: 24px 0 0;
        }

        .modal-box--title {
            padding: 0 16px;

            > div:first-child {
                text-align: left;
                padding-right: 40px;
                gap: 12px;
            }

            .modal-box--close {
                position: relative;
            }
        }

        .modal-box--content {
            height: 100%;
            font-size: 14px;
        }
    }

    .promotions-modal-data,
    .MuiAccordionSummary-root {
        padding: 0 16px;
    }

    .MuiAccordionDetails-root {
        padding: 24px 16px;

        .game-list {
            @include game-list;
            margin: 0;

            &-item {
                @include game-list-item;
            }
        }
    }

    .container-btns {
        padding: 20px;

        .btn {
            width: 288px;
            height: 40px;
        }
    }
}

.promo-plate-status {
    padding: 6px;
    font-size: 12px;
    line-height: 12px;

    .pulse-dot {
        width: 8px;
        height: 8px;
    }
}

.promo-days-of-week-wrapper {
    position: absolute;
    bottom: 10px;
    padding-left: 8px;
}

.promo-days-of-week-wrapper-modal {
    grid-column: 1 / -1;
    padding-left: 0;
}

.promo-days-of-week {
    display: flex;
    list-style: none;
    gap: 4px;

    li {
        font-size: 10px;
        padding: 4px 6px;
        border-radius: 14px;
        background: $coral-light;
        font-weight: 800;
        color: $coral;
    }
}
