.bonuses-page {
    padding: 24px 10px;
    font-size: 14px;

    h1 {
        font-size: 18px;
        font-weight: 800;
        margin-bottom: 16px;
    }

    &--loader {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
    }

    &--bonus-list {
        list-style-type: none;
        gap: 30px;
        padding: 0;
    }

    &--no-bonuses {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        p {
            font-size: 16px;
            font-weight: 500;
            color: $no-items-message-color;
            margin: 0;
        }
    }
}

//Reusing styles on lobby page
.bonus-card {
    border-radius: 20px;
    background: $light-peach;
    padding: 60px 16px 24px;
    position: relative;
    box-shadow: 0 32px 32px -12px $box-shadow-peach;
    margin: 64px 0;
    text-align: left;

    &:first-of-type {
        margin-top: 46px;
    }

    &--icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 24px;
        border-radius: 10px;
        background: $icon-card;
        width: auto;
        min-width: 60px;
        height: 60px;
        transform: translate(0, -50%);
        box-shadow: 0 5px 20px 0 $card-box-shadow;

        &#icon-blue {
            box-shadow: 0 10px 20px -10px $box-shadow-light-blue;
        }

        &#icon-green {
            box-shadow: 0 10px 20px -10px $box-shadow-light-green;
        }

        &-free-rounds {
            aspect-ratio: 1.77;
        }

        img:not(.game-image) {
            width: 28px;
        }

        .game-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    &--status {
        position: absolute;
        top: 18px;
        right: 16px;
        color: $blue;
        font-size: 11px;
        font-weight: 700;

        &:before {
            content: '';
            position: relative;
            display: inline-block;
            width: 12px;
            height: 12px;
            margin-right: 4px;
            border-radius: 50%;
            top: 2px;
            background: $blue;
        }
    }

    &--title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 12px;
    }

    &--progress {
        .progress-bonus {
            margin: 4px 0;
            background: $border-color;

            .MuiLinearProgress-bar {
                border-radius: 3px;
                background: $blue;
            }
        }

        .progress-label {
            color: $blue;
        }

        .progress-info {
            font-size: 11px;
            display: flex;
            justify-content: space-between;
            color: $scroll-nav-item;
        }
    }

    &--claim-bonus {
        margin: 24px 0 16px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .btn {
            width: 100%;
            font-size: 12px;
            height: 30px;
        }
    }

    &--description,
    &--limits {
        font-size: 12px;
        color: $scroll-nav-item;
    }

    &--limits {
        margin-top: 12px;

        span {
            + span:before {
                content: '|';
                margin: 0 5px;
            }
        }
    }
}
