.global-search {
    max-width: 380px;
    width: 100%;
    position: relative;
    margin: 0 20px;

    .search-input-wrapper {
        position: relative;

        &.open {
            z-index: 9999;
        }

        .search-input {
            box-sizing: border-box;
            height: 40px;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: rgba(255, 255, 255, 0.5);
            background: $new-dark-violet url('#{$asset-path}/search.png') no-repeat 12px center;
            border-radius: 20px;
            padding-left: 42px;
            padding-right: 28px;
            background-size: 16px;
            vertical-align: top;
        }

        .close-wrapper {
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
            width: 12px;
            height: 12px;
            border: none;
            background: none;
            padding: 0;
            outline: none;

            svg path {
                fill: $new-light-violet;
            }

            &:hover {
                cursor: pointer;

                svg path {
                    fill: $white;
                }
            }

            svg {
                width: inherit;
                height: inherit;
            }
        }
    }

    .search-results-wrapper {
        width: 100%;
        z-index: 9999;
        position: absolute;
        top: 44px;
        left: 0;
        background: $new-footer-violet;
        border-radius: 20px;
        overflow: hidden;

        .search-results {
            @include hidden-scrollbar;
            width: 100%;
            max-height: 578px;
            overflow-y: auto;
            overflow-x: hidden;

            .searched-games-no-results {
                display: flex;
                flex-direction: column;

                .no-results {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 8px;
                    padding: 24px 58px 16px 58px;

                    .wildSultan-searching {
                        width: 164px;
                        height: 142px;

                        source,
                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .no-results-text {
                        width: 100%;
                        color: $white;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 20px;
                        text-align: center;
                        text-overflow: ellipsis;
                        overflow: hidden;

                        .search-value {
                            color: $yellow-alt;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 14px;
                        }
                    }

                    .explore-games {
                        color: $new-light-violet;
                        text-align: center;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 14px;

                        &:hover {
                            cursor: pointer;
                            color: $white;
                        }
                    }
                }

                .feeling-lucky {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 8px;
                    padding: 16px;
                    background: $new-deep-violet;

                    &-text {
                        color: $white;
                        font-size: 12px;
                        font-weight: 400;
                        line-height: 12px;
                        text-align: center;
                    }

                    a.btn {
                        width: 100%;
                        max-width: 100%;
                        border: none;

                        svg {
                            path {
                                width: 14px;
                                height: 14px;
                            }

                            ellipse {
                                fill: none;
                            }
                        }
                    }
                }
            }

            &_section {
                .title-container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 40px;
                    margin: 0 14px 0 16px;
                    border-bottom: 1px solid $ultra-semi-white;

                    .title,
                    .subtitle {
                        color: $white;
                        font-size: 12px;
                        line-height: 12px;
                    }

                    .title {
                        font-weight: 700;
                    }

                    .subtitle {
                        font-weight: 400;
                        opacity: 0.5;
                    }
                }

                .items-list {
                    .search-results-item {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        padding: 14px 16px;

                        .item-img {
                            flex-shrink: 0;
                            height: 40px;
                            aspect-ratio: 1.5;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        &:hover {
                            cursor: pointer;
                            background: $new-purple;

                            .arrow-right svg path {
                                fill: $white;
                            }
                        }

                        .item-infos {
                            display: flex;
                            flex-direction: column;
                            gap: 4px;
                            width: 100%;

                            &__title {
                                font-size: 16px;
                                font-weight: 700;
                                line-height: 16px;
                                color: $white;

                                .highlight {
                                    color: $yellow-alt;
                                }
                            }

                            &__subtitle {
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 12px;
                                color: $white;
                                opacity: 0.7;
                            }
                        }

                        .favorite-wrapper {
                            width: 20px;
                            height: 20px;
                            border: none;
                            background: none;
                            padding: 0;
                            outline: none;

                            &:disabled {
                                cursor: not-allowed;
                                pointer-events: none;
                            }

                            &:hover {
                                cursor: pointer;

                                path {
                                    fill: $orange;
                                }
                            }

                            svg {
                                width: inherit;
                                height: inherit;
                            }
                        }

                        .arrow-right {
                            width: 10px;
                            height: 20px;

                            svg {
                                width: inherit;
                                height: inherit;

                                path {
                                    fill: $new-light-violet;
                                }
                            }
                        }
                    }
                }

                .more-items {
                    display: flex;
                    align-items: center;
                    color: $new-light-violet;
                    height: 40px;
                    padding: 0 16px;

                    &:hover {
                        cursor: pointer;
                        background: $new-purple;
                        color: $white;
                    }
                }
            }
        }
    }

    .MuiBackdrop-root {
        background: transparent;
    }
}
