div,
ul,
li {
    box-sizing: border-box;
}
a {
    text-decoration: none;
    cursor: pointer;
}
iframe {
    border: 0;
    overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
}
* {
    box-sizing: border-box;
}
*:focus,
*:active {
    outline: none;
}
