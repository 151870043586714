.welcome-modal {
    @include font-family-nunito;
    color: $step-content;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;

    &--content {
        a {
            color: $violet-new;
        }
    }

    .first-deposit-offer {
        padding: 0 16px;
        margin-top: 46px;
        border-radius: 6px;
        background: $light-peach;
        border: 1px solid $border-color;
        animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97);
        animation-delay: 0.25s;

        .box {
            position: relative;
            padding: 12px 0;

            &:first-child {
                padding-top: 42px;
                border-bottom: 1px solid $card-border;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            &:last-child {
                display: grid;
                grid-template-columns: 60px 1fr;
                grid-column-gap: 12px;
                text-align: left;
            }

            .gift-icon {
                position: absolute;
                top: -30px;
                left: 50%;
                margin-left: -30px;
                background: linear-gradient(180deg, $blue 0%, $violet-new 100%);
                box-shadow: 0 5px 12px $box-shadow-gift;
                width: 60px;
                height: 60px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                animation: rotate-gift 1.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
                animation-delay: 1s;

                img {
                    position: relative;
                    transform: scale(0.35);
                    margin: 0;
                }
            }

            .gift-time-left {
                font-weight: 800;
                font-size: 18px;
                line-height: normal;
                color: $violet-new;
                text-align: center;
                margin: 0;
                align-self: center;
            }

            .gift-title {
                font-weight: 800;
                font-size: 14px;
                margin-bottom: 12px;
                line-height: normal;
                color: $violet-new;
            }

            .gift-text {
                font-size: 12px;
                line-height: 18px;
                font-weight: 400;

                .violet-global {
                    color: $violet-new;
                    font-weight: 800;
                }

                &--secondary {
                    align-self: center;
                    color: $scroll-nav-item;
                    font-size: 10px;
                    line-height: normal;
                }
            }
        }
    }
}
