.refer-a-friend {
    padding: 15px 10px 40px;

    h1,
    h3 {
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
    }

    p,
    li {
        font-size: 12px;
    }

    .main-img--wrapper {
        padding: 20px;

        img {
            width: 100%;
        }
    }

    .share-link--container {
        margin-top: 30px;

        .share-link {
            padding: 15px;

            p {
                margin: 0 0 14px;
            }

            .copy-input {
                margin: 0 0 15px;

                input {
                    height: 35px;
                    font-size: 10px;
                    padding-right: 80px;
                    margin: 0;
                }
            }
        }
    }

    .how-it-works--container {
        margin: 50px 0;
    }

    .how-it-works {
        padding-right: 100px;

        &--content {
            padding: 15px;

            &.phase-1 {
                img {
                    right: -100px;
                }
            }

            &.phase-2 {
                margin-left: 200px;
                padding-left: 25px;

                img {
                    left: -100px;
                }
            }

            &.phase-3 {
                img {
                    right: -150px;
                    bottom: -11px;
                }
            }
        }

        svg {
            position: relative;
            left: 40px;
        }
    }
}
