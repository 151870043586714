.mfa-modal {
    color: $step-content;

    .modal-box {
        border-radius: 20px;

        &--title {
            display: flex;
            justify-content: space-between;
        }

        &--close {
            z-index: 2;
        }

        &--content {
            position: relative;

            a {
                color: $violet-new;
            }
        }
    }

    &--content {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 46px 20px;
        gap: 24px;

        &-authanticator {
            font-size: 14px;
            font-weight: 700;

            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        p {
            margin: 0;
            text-align: justify;
        }

        &-instructions {
            h4 {
                margin: 0;
            }
        }

        &-loader {
            display: flex;
            justify-content: center;
        }

        &-qrcode {
            display: flex;
            align-items: center;
            gap: 15px;

            img {
                width: 200px;
                height: 200px;
                border: 1px solid $violet-new;
                border-radius: 20px;
            }

            .secret-key-wrapper {
                display: flex;
                flex-direction: column;

                p {
                    margin: 0;
                    font-size: 14px;
                }

                h4 {
                    margin-bottom: 8px;
                }
            }

            .secret-key-text {
                display: flex;
                align-items: center;
                gap: 5px;
                font-size: 14px;
                font-weight: 700;
                color: $violet-new;
                cursor: pointer;
            }
        }

        &-code {
            width: 100%;

            &--inputs {
                width: 100% !important;

                > div:first-child {
                    display: flex;
                    justify-content: space-between;
                }

                input {
                    color: $step-content;
                    font-size: 14px;
                    border-radius: 5px;
                    border: 1px solid $card-border;
                    background: $white;
                }

                &-error {
                    input {
                        border-radius: 5px;
                        border: 1px solid $menu-red;
                        background: $white;
                    }
                }
            }

            .mfa-error {
                color: $menu-red;
                margin-top: 6px;
            }
        }

        &-footer {
            display: flex;
            justify-content: center;
            gap: 24px;
            background-color: $white;

            &--login {
                margin-top: 24px;
            }

            .enable-mfa-btn {
                max-width: 226px;
                width: 100%;
            }
        }

        #mfa-authenticator {
            h5 {
                font-size: 14px;
                margin-bottom: 10px;
            }

            .mfa-error {
                margin-top: 5px;
                color: $menu-red;
                font-size: 12px;
                font-weight: 600;
            }
        }

        &-status {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            .status-wrapper {
                text-align: center;

                img {
                    width: 30px;
                    height: 30px;
                }

                p {
                    margin: 0;
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }
    }

    .status-modal {
        padding: 0 6px;
    }
}

.mfa-support {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    font-weight: 600;
    margin-top: 32px;

    h5 {
        color: $new-light-violet;
        margin-bottom: 0;
        font-size: 14px;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
