.favorites-modal {
    padding: 0;

    .modal-content {
        justify-content: flex-start !important;
    }
}

.favorite-panel {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 20px;
    height: 100%;
    background-color: $new-dark-violet;
    backdrop-filter: blur(5px);
    z-index: 0;
    color: $white;

    &_header {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        position: sticky;
        top: 0;
        background-color: $new-dark-violet;
        padding: 0 0 23px 0;

        h2 {
            margin-bottom: 0;
        }

        h3 {
            margin-bottom: 0;
            font-size: 18px;
        }

        .close-btn {
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    &_body {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .favorite-game {
            display: flex;
            width: 100%;
            align-items: center;
            padding: 0 16px;

            &_thumbnail {
                width: 124px;
                height: 92px;
                flex-shrink: 0;
                border-radius: 10px;
                background-color: $game-thumb-text-bg;
                background-size: cover;
                background-position: center;
                margin-right: 16px;

                img {
                    width: 100%;
                }
            }

            .game-info {
                margin: 13px 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                color: $white;

                &--text-wrapper {
                    text-align: left;
                }

                h3 {
                    margin-bottom: 8px;
                    font-size: 16px;
                }

                p {
                    margin: 0;
                    text-transform: capitalize;
                    color: $semi-white;
                    font-size: 11px;
                    margin-bottom: 16px;
                }

                .volatility-info {
                    display: flex;
                    align-items: center;
                    height: 40px;

                    span {
                        font-size: 12px;
                        display: flex;
                        align-items: flex-start;
                        flex-direction: column;
                        font-weight: 700;
                        height: 40px;

                        > div {
                            height: 100%;
                        }
                    }

                    .separator {
                        height: 100%;
                        border-right: 1px solid $ultra-semi-white;
                        margin: 0 16px;
                    }

                    .rtp {
                        display: flex;
                        justify-content: center;
                        font-size: 14px;
                        color: $light_blue;
                    }

                    .vlt {
                        font-size: 12px;
                        display: flex;
                        align-items: flex-start;
                    }

                    .progress-bar--outer {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .add-to-favorites {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 16px 0 32px 0;
            padding: 0 16px;

            button {
                width: 100%;
            }
        }

        .favorite-games-list {
            display: flex;
            flex-direction: column;
            color: $white;

            &_header {
                display: flex;
                border-bottom: 1px solid $semi-white;
                font-weight: 700;
                margin: 0 16px 8px 16px;

                h4 {
                    font-size: 14px;
                    margin-bottom: 16px;
                }
            }

            &_body {
                .favorite-game-item {
                    display: flex;
                    width: 100%;

                    .game-list-item--link {
                        width: 100%;
                    }

                    .favorite-game {
                        display: flex;
                        width: 100%;
                        margin: 8px 0;

                        &_thumbnail {
                            width: 72px;
                            height: 54px;
                            flex-shrink: 0;
                            border-radius: 10px;
                            background-color: $game-thumb-text-bg;
                            background-size: cover;
                            background-position: center;
                            margin-right: 12px;

                            img {
                                width: 100%;
                            }
                        }

                        .game-info {
                            margin: 8px 0;
                            color: $white;

                            p {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            &_no-games {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                img {
                    width: 170px;
                    height: 170px;
                }
            }
        }
    }
}
