.modal {
    padding: 10% 0;

    &-backdrop {
        background-color: rgba(48, 37, 63, 0.5) !important;
    }

    &-box {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        display: inline-block;
        min-width: 500px;
        background: rgba(44, 34, 58, 0.9);
        border-radius: 6px;
        padding: 12px;
        text-align: left;
        color: $footer-bg-violet;

        &.login {
            min-width: 438px;
            min-height: 385px;
        }

        &--title {
            font-size: 16px;
            font-weight: bold;
            color: $white;
            margin: -2px 0 8px 0;
            letter-spacing: 0.04em;
            line-height: 20px;

            &.login {
                margin-top: 12px;
                margin-bottom: 16px;
            }
        }

        &--close {
            float: right;
            border-radius: 10px;
            border: 2px solid $white;
            width: 20px;
            height: 20px;
            position: relative;
            cursor: pointer;
            opacity: 0.25;

            &:hover {
                opacity: 1;
            }

            &:before {
                content: '';
                position: absolute;
                background-image: url('#{$asset-path}/icons.png');
                background-size: 100px;
                background-repeat: no-repeat;
                background-position: 0 -56px;
                width: 11px;
                height: 11px;
                top: 4px;
                left: 4px;
            }
        }

        &--content {
            background: $white;
            border-radius: 4px;
            padding: 18px 20px;

            &.login {
                padding: 18px 14px;

                .forgot-password {
                    color: #4a2c10;
                    font-weight: 300;
                }

                .sign-up {
                    font-weight: 600;
                    font-size: 14px;
                }
            }

            a {
                color: $footer-bg-violet;
            }

            &-buttons {
                margin-top: 12px;

                .btn {
                    margin-right: 12px;
                }
            }
        }
    }

    &.deposit-modal,
    &.withdrawal-flow,
    &.mystery-chests-modal,
    &--status,
    &--modern {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
        padding: 0;

        .modal-box {
            position: static;
            transform: none;
        }
    }

    &--status,
    &--modern {
        .modal-box {
            border-radius: 10px;

            &--close {
                float: right;
                border-radius: 50%;
                border: 1px solid $step-content;
                background: $white;
                width: 32px;
                height: 32px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;

                &:hover {
                    border: 1px solid $violet-new;

                    svg {
                        path {
                            fill: $violet-new;
                        }
                    }
                }

                &:before {
                    display: none;
                }
            }
        }
    }

    &--status {
        @include font-family-nunito;
        overflow-y: auto;

        .modal-box {
            padding: 24px 30px 48px;
            width: 500px;
            background: $white;

            .modal-box {
                &--title {
                    position: relative;
                }

                &--close {
                    position: absolute;
                    right: 0;
                }
            }

            .modal-content {
                color: $step-content;
                text-align: center;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;

                h2 {
                    font-weight: 800;
                    font-size: 24px;
                    line-height: 33px;
                }

                img {
                    margin: 16px 0;
                }

                .container-btns {
                    display: flex;
                    gap: 24px;
                    margin-top: 54px;
                    justify-content: center;

                    .btn {
                        width: 100%;
                        font-weight: 800;
                        font-size: 14px;
                        line-height: 19px;
                        max-width: 168px;
                    }
                }
            }
        }
    }

    &--modern {
        @include font-family-nunito;

        .modal-box {
            box-sizing: border-box;
            background: $white;
            color: $step-content;
            padding: 24px 24px 48px;
            width: 525px;

            &--title {
                color: inherit;
                font-weight: 800;
                font-size: 24px;
                line-height: 33px;
                margin-bottom: 24px;
                letter-spacing: normal;
            }

            &--content {
                padding: 0;
                height: 450px;

                .form-loader {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                }

                > [id*='form'] {
                    position: relative;
                    padding-bottom: 60px;
                    height: 100%;

                    .prepend-field {
                        @include prepend-field;
                    }

                    .form-control {
                        font-family: inherit;
                        height: 40px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        border-radius: 5px;
                        color: inherit;

                        &:not(:focus) {
                            border: 1px solid $card-border;
                        }
                    }

                    .form-group--row {
                        display: flex;
                        column-gap: 12px;
                        flex-wrap: wrap;

                        .form-group--cell {
                            width: calc((100% - 12px) / 2);
                            flex-grow: 0;
                            flex-shrink: 1;
                        }

                        .amount,
                        .cryptocurrency,
                        .email {
                            margin-top: 20px;
                        }

                        .wallet-address {
                            width: 100%;
                        }
                    }

                    .form-group--cell {
                        margin-right: 0;
                    }

                    .form-group--error-marked:after {
                        top: 48px;
                    }

                    .financial-page--data,
                    .data-container {
                        max-height: 100%;
                        padding: 0 46px 32px;
                        overflow-y: auto;

                        &-title {
                            font-weight: 700;
                            margin: 24px 0 16px;
                        }
                    }

                    .container-btns {
                        padding: 0 46px;
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        background-color: $white;
                        display: flex;
                        gap: 24px;

                        &:before {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 32px;
                            top: -52px;
                            left: 0;
                            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $white 100%);
                        }

                        .btn {
                            min-height: fit-content;
                            flex-grow: 1;
                            flex-shrink: 0;
                            max-width: calc((100% - 24px) / 2);
                        }
                    }
                }
            }
        }
    }
}
