.modal,
.mfa-modal {
    &_slide {
        .modal-box {
            height: calc(100% - 32px);
            bottom: 0;
            border-radius: 20px 20px 0 0;

            &.favorites-modal {
                background: $new-footer-violet;
            }
        }
    }

    &-box {
        position: fixed;
        background: $extra-light;
        height: 100%;
        width: 100vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 66px 12px 48px;
        text-align: center;

        &.bonus {
            border-radius: 6px;
            margin-top: calc(100vh - 676px);
            height: calc(100vh - 200px);
            display: flex;
            background: none;
            justify-content: center;
        }

        &--wrapper {
            margin: auto;

            img {
                margin-bottom: 32px;
            }

            a {
                font-weight: bold;
                text-decoration: underline;
            }
        }

        .restricted-modal-btn {
            margin-top: auto;
        }

        &--title {
            font-weight: bold;
            color: $dark-gold;
            margin-top: 0;
        }

        &--content {
            background: $white;
            border-radius: 4px;
            padding: 18px 20px;

            &.bonus {
                height: 70%;
            }

            &-buttons {
                margin-top: 12px;
                display: flex;
                flex-direction: column;
                padding-top: 2%;

                .btn {
                    margin-top: 5px;
                    margin-right: 12px;

                    &.btn-cancel {
                        background: $white;
                        border: solid 1px $default-border-color;
                        color: $default-border-color;

                        &.bonus {
                            color: $dark-violet;
                        }
                    }

                    &.btn-primary {
                        background: $light-purple;
                        color: $white;

                        &:hover:not(:disabled) {
                            background: $dark-violet-submit;
                        }
                    }
                }
            }
        }
    }

    &--status {
        @include font-family-nunito;
        height: 100%;

        .modal-box {
            padding: 24px 16px;
            overflow-y: auto;
            background: $white;

            .modal-content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                color: $step-content;
                text-align: center;
                width: 100%;
                height: 100%;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;

                h2 {
                    font-weight: 800;
                    font-size: 18px;
                    line-height: 25px;
                }

                img {
                    margin-bottom: 16px;
                }

                .wdr-success-content {
                    margin: 0 auto;
                    max-width: 260px;
                }

                .container-btns {
                    display: flex;
                    flex-direction: column-reverse;
                    gap: 16px;
                    margin-top: 54px;
                    width: 100%;

                    .btn {
                        width: 100%;
                        height: 48px;
                        font-size: 14px;
                        line-height: 19px;

                        &-cancel {
                            color: $violet-new;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }

    &--modern {
        @include font-family-nunito;

        .modal-box {
            @include modern-modal-box;

            &--title {
                color: inherit;
                font-weight: 800;
                font-size: 24px;
                line-height: 33px;
                margin-bottom: 24px;
                width: 100%;
                position: relative;
            }

            &--close {
                position: absolute;
                top: 0;
                right: 0;
                float: right;
                border-radius: 50%;
                border: 1px solid $step-content;
                background: $white;
                width: 32px;
                height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                padding: 0;
            }

            &--content {
                padding: 0;
                width: 100%;
                height: calc(100% - 57px);

                .form-loader {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                }

                > [id*='form'] {
                    position: relative;
                    padding-bottom: 60px;
                    height: 100%;

                    .prepend-field {
                        @include prepend-field;
                        margin: 0 !important;
                    }

                    .form-control {
                        font-family: inherit;
                        height: 40px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        border-radius: 5px;
                        color: inherit;
                        margin-bottom: 0;

                        &:not(:focus) {
                            border: 1px solid $card-border;
                        }
                    }

                    .form-group--row {
                        margin: 0;
                        gap: 12px;

                        .form-group--cell {
                            width: 100%;
                            flex-grow: 0;
                            flex-shrink: 1;
                            margin: 0;
                        }
                    }

                    .form-group--cell {
                        margin-right: 0;
                    }

                    .form-group--error-marked,
                    .form-group--cell-error-marked {
                        position: relative;

                        &:after {
                            top: 41px;
                        }
                    }

                    .financial-page--data,
                    .data-container {
                        max-height: calc(100% - 40px);
                        padding: 0 0 32px;
                        overflow-y: auto;

                        &-title {
                            font-weight: 700;
                            font-size: 14px;
                            line-height: 19px;
                            margin: 10px 0 12px;
                        }

                        .page-message--error {
                            margin: 0;
                        }
                    }

                    .container-btns {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        background-color: $white;
                        display: flex;
                        gap: 24px;

                        &:before {
                            content: '';
                            position: absolute;
                            width: 100%;
                            height: 32px;
                            top: -44px;
                            left: 0;
                            background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $white 100%);
                            z-index: 1;
                        }

                        .btn {
                            width: 100%;
                            margin: 0 auto;
                            min-height: 48px;
                        }
                    }
                }
            }

            .empty-title {
                flex-grow: 1;
            }
        }

        &:not(.game-preview-modal) {
            .modal-box--title {
                text-align: center;
            }
        }
    }
}

@media (max-width: 319px) {
    .modal_slide {
        .container-btns {
            .btn {
                font-size: 12px;
            }
        }
    }
}
