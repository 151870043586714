.wheel-of-fortune.page-container {
    background: $wheel-of-fortune-page-bg;
    padding: 0;

    img {
        width: 100%;
    }

    h3 {
        font-size: 32px;
        font-weight: 800;
        text-align: left;
        margin-bottom: 24px;
    }

    .wheel-bg {
        background: url('#{$asset-path}/wheel-of-fortune/wheel_background.jpg') no-repeat center;
        background-size: cover;
        position: relative;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: url('#{$asset-path}/wheel-of-fortune/wheel_bottom_line.png') no-repeat;
            height: 30px;
        }
    }

    .no-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
    }

    .table {
        color: $white;

        &-info {
            display: flex;
            justify-content: space-between;
            gap: 16px;
            width: 100%;

            &--item {
                background: $wheel-of-fortune-table-background;
                width: 100%;
                height: calc(100% - 8px);
                padding: 30px;
                border-radius: 20px;
                margin: 4px;
                text-align: center;

                &--title {
                    font-size: 16px;
                    font-weight: 500;
                    margin-bottom: 12px;
                    line-height: 1;
                }

                &--number {
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 1;
                }
            }

            .refresh {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 128px;
                height: 128px;
                background: $yellow-alt;
                color: $wheel-of-fortune-table-title;
                border-radius: 20px;
                border: none;
                font-size: 14px;
                font-weight: 800;

                &-icon {
                    display: flex;
                    transition: transform 0.5s ease;
                }

                span {
                    margin-top: 10px;
                }
            }
        }

        .table-content {
            width: 100%;
            height: 100%;
            max-width: 100%;
            margin-top: 24px;

            table,
            .table--no-participants {
                width: 100%;
                border-radius: 20px;
                border-collapse: separate;
                border-spacing: 0 4px;
                overflow: hidden;
                position: relative;
                margin: 4px;
                padding: 2px 4px 0;

                &::after {
                    content: '';
                    width: calc(100% + 8px);
                    height: calc(100% + 8px);
                    background: $wheel-of-fortune-table-gradient;
                }
            }

            table {
                .table-header {
                    background: $wheel-of-fortune-table-title;
                }

                tr {
                    background: $wheel-of-fortune-table-row;
                    min-height: 50px;
                    font-weight: 500;
                }

                .current-player {
                    background: $yellow-alt;
                    color: $wheel-of-fortune-table-title;

                    &--edit {
                        display: flex;
                        align-items: center;

                        span {
                            display: flex;
                            margin-left: 5px;
                        }
                    }
                }

                .table-header,
                .current-player {
                    font-weight: 700;
                }

                td,
                th {
                    padding: 16px 30px;

                    &:nth-child(2) {
                        text-align: left;
                    }

                    &:first-child {
                        border-top-left-radius: 10px;
                        border-bottom-left-radius: 10px;
                    }

                    &:last-child {
                        border-top-right-radius: 10px;
                        border-bottom-right-radius: 10px;
                    }
                }
            }

            .table--no-participants {
                .text {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    text-align: center;
                }
            }
        }
    }

    .wheel-of-fortune--prizes-timer {
        margin-top: 60px;

        .card {
            @include carousel-card;
            height: 140px;
        }

        h3 {
            margin-bottom: 0;
        }

        h4 {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 15px;
        }

        .bold {
            color: $wheel-of-fortune-table-background;
            font-size: 24px;
            font-weight: 700;
        }

        .weekly-prizes--carousel {
            margin: 0 -10px;
            width: calc(100% + 20px);

            &-item {
                display: flex;
                align-items: center;

                .gift-img {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background: $violet-new;
                    min-width: 70px;
                    min-height: 70px;

                    img {
                        width: 40px;
                    }
                }

                .gift-text {
                    text-align: left;
                    margin-left: 15px;

                    &-prize {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .slide {
                padding: 0 10px 70px;
            }

            .control-dots {
                bottom: 20px;
            }
        }

        .wheel-of-fortune--timer {
            font-size: 12px;
            font-weight: 400;

            &-container {
                padding: 20px 30px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            h4 {
                text-align: center;
            }

            .timer {
                display: flex;
                justify-content: center;
                align-items: center;
                color: $wheel-of-fortune-timer-text-color;
                font-size: 24px;

                span {
                    position: relative;
                    top: -17px;
                }

                &-data {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    max-width: 60px;
                    width: 100%;

                    &--item {
                        color: $wheel-of-fortune-timer-text-color;
                        font-size: 12px;
                        font-weight: 400;
                        text-transform: uppercase;
                        margin-top: 12px;
                    }
                }
            }
        }
    }
}

.wheel-of-fortune.page-container,
.wheel-of-fortune-modal {
    .gradient-border {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: $wheel-of-fortune-table-gradient;
        border-radius: 25px;

        &--text {
            width: inherit;
            height: 100%;
            background: $wheel-of-fortune-table-background;
            color: $white;
            padding: 12px;
            border-radius: 20px;
            text-align: center;
            display: flex;
            flex-direction: column;
            margin: 4px;
        }
    }

    .content-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;

        .logo-wrapper {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;

            img {
                aspect-ratio: 2.936;
                display: none;

                &.displayed {
                    display: block;
                }
            }
        }
    }

    .wheel-of-fortune-maintenance {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        position: relative;

        .wild-fortunes img {
            width: 100%;
            height: 100%;
        }

        .maintenance-wrapper {
            background: url('#{$asset-path}/wheel-blurred.png') no-repeat center / contain;
            width: 100%;
            height: 100%;
            flex: 1;
            justify-content: center;
            align-items: center;
            display: flex;
        }

        .maintenance-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: auto;
            background: $wheel-of-fortune-table-gradient;
            border-radius: 20px;
            padding: 4px;
            position: relative;
            z-index: 2;

            &--inner {
                width: 100%;
                border-radius: 20px;
                background: rgba(48, 22, 0, 0.7);
                margin: 0;
                color: $white;
                text-align: center;
                font-weight: 800;
            }
        }
    }
}

.wheel-of-fortune-modal,
.win-modal {
    &.modal--modern {
        .modal-box--title {
            position: relative;
            margin: 0;

            .modal-box--close {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
            }
        }
    }
}

.wheel-of-fortune-modal {
    &.modal--modern {
        .modal-box {
            background: url('#{$asset-path}/wheel-of-fortune/wheel_background.jpg') no-repeat center;
            background-size: cover;

            &--title {
                .modal-box--close {
                    border-color: $white;
                    background: transparent;
                    opacity: 1;

                    svg {
                        path {
                            fill: $white;
                        }
                    }

                    &:hover {
                        background: rgba($white, 0.3);
                    }
                }
            }

            &--content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                background: transparent;
                height: 100%;
            }
        }
    }
}

.nickname-modal--comment {
    font-size: 12px;
    opacity: 0.5;
}

.win-modal {
    .modal-box--content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;

        .prize {
            &--bonus {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        h2 {
            font-weight: 800;
        }

        p {
            margin: 0;
        }

        .btn {
            width: 100%;
        }
    }
}

.wheel-widget {
    position: fixed;
    right: 0;
    z-index: 99;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    canvas {
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
    }

    img {
        position: relative;
        z-index: 100;

        &.animated {
            opacity: 1;
            animation: spin 3s ease-in-out infinite;
        }
    }

    .spins-count {
        position: absolute;
        z-index: 999;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $coral;
        color: $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
    }

    &--countdown {
        position: absolute;
        right: 0;
        bottom: 0;
        background: $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: inherit;
        height: inherit;

        .bottom {
            color: rgba(131, 56, 236, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-image: url('#{$asset-path}/wheel-of-fortune/wheel_widget_mono.png');
                background-repeat: no-repeat;
                opacity: 0.2;
                z-index: -1;
                display: flex;
                align-items: center;
                justify-content: center;
                background-size: contain;
            }
        }

        .top {
            animation-duration: 100ms;
            position: absolute;
            transform: scaleX(-1) rotate(-90deg) !important;
            color: $violet-new;

            circle {
                stroke-linecap: round;
            }
        }
    }

    .timer {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
    }
}

.wof-messages-carousel {
    width: inherit !important;

    .carousel {
        display: flex;
        flex-direction: column-reverse;

        &.carousel-slider {
            .slider-wrapper ul {
                .slide {
                    padding: 0 10px 40px;

                    .wof-carousel--card {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                    }
                }
            }
        }
    }
}

.wof-carousel {
    &--card {
        @include carousel-card;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(90deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
