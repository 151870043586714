.browser-not-supported-modal {
    .modal-backdrop {
        backdrop-filter: blur(20px);
    }

    .modal-box {
        min-height: 550px;

        &--title {
            display: none;
        }
    }
}
