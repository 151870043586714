.app-loader {
    text-align: center;
    display: flex;
    height: 100vh;
    justify-content: center;
    flex-direction: column;
    background: radial-gradient(circle, $violet-new, $black 150%);
    position: fixed;
    width: 100%;

    &--logo {
        background: url('#{$asset-path}/loading_logo.png');
        background-size: cover;
        display: inline-block;
        width: 270px;
        height: 206px;
        align-self: center;
    }

    .loading-wrapper {
        align-self: center;
    }

    .progress-bar {
        position: relative;
        width: 270px;
        margin: 50px auto 0;
        height: 4px;
        border-radius: 2px;
        align-self: center;
        background: $loader-bar-background;

        span {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: 4;
            height: inherit;
            background-color: $yellow-alt;
            transition: all 0.4s;
            border-radius: 2px;
        }
    }
}
