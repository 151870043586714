.carousel-root--modern {
    .carousel {
        &-root {
            height: 100%;
            width: 100%;
            overflow: hidden;
        }

        &-slider {
            height: 100%;

            .slider-wrapper {
                height: 100%;

                ul {
                    height: 100%;

                    .slide {
                        height: 100%;
                        padding: 0 10px 50px;
                    }
                }
            }

            .control-dots {
                .dot {
                    border: 1px solid $carousel-dots-border;
                    border-radius: 4px;
                    box-sizing: border-box;
                    background: transparent;
                    margin: 0 5px;
                    opacity: 1;
                    transition: all 0.3s ease-in-out;

                    &.selected {
                        background: $carousel-dots-selected;
                        border: 1px solid $violet-new;
                        width: 24px;
                    }
                }
            }
        }
    }
}
