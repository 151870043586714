//ASSET BASE URL
$asset-path: 'https://assets.wild-endpoint.com/v2/images';

//COLORS
$white: #fff;
$black: #000;
$step-content: #301600;
$main-bg: #f6eddd;
$loader-color: #7a037e;
$light-purple: #90018b;
$violet-submit: #5e0060;
$dark-violet: rgba(67, 7, 69, 1);
$dark-violet-submit: #500052;
$violet: #90018b;
$cms-title: #874984;
$new-dark-purple: rgba(131, 56, 236, 0.3);
$light-violet: #90008b;
$deep-violet: #390041;
$darkest-purple: rgba(57, 0, 65, 0.5);
$violet-new: #8338ec;
$new-purple: #5f02bb;
$new-dark-violet: rgba(60, 11, 108, 0.9);
$new-light-violet: #c398ff;
$new-deep-violet: #320959;
$new-footer-violet: #3c0b6c;
$default-border-color: #ded6c7;
$document-color: #cbbcae;
$semi-white: rgba(255, 255, 255, 0.5);
$menu-peach: #feccb5;
$gray: #cbbcae;
$biege-mask: rgba(222, 214, 199, 0.5);
$light-graphite: rgba(255, 190, 11, 0.1);
$font-play-tab: #666;
$light-peach: #fff5f1;
$progress-bar: #f8edeb;
$extra-light-bronze: #c29875;
$icon-card: #f1fff4;
$border-description: rgba(194, 152, 117, 0.3);
$card-box-shadow: rgba(48, 22, 0, 0.3);
$coral: #ff006e;
$coral-light: #ffebf3;
$blue: #3a86ff;
$green: #29cd39;
$menu-green: #65eb72;
$menu-light-green: #e8ffea;
$orange: #ff6b00;
$game-thumb-text-bg: rgba(60, 11, 108, 0.8);
$box-shadow-peach: rgba(228, 142, 69, 0.5);
$no-items-message-color: rgba(48, 22, 0, 0.6);
$checkbox-border-grey: hsla(27, 39%, 61%, 0.3);
$mui-list-button-grey: rgba(95, 2, 187, 0.1);
$notifications-violet: #300758;
$carousel-dots-border: rgba(131, 56, 236, 0.6);
$carousel-dots-selected: rgba(131, 56, 236, 0.5);
$pre-footer-color: #e1cbff;
$pre-footer-content-border: rgba(255, 255, 255, 0.3);
$pre-footer-table-border: rgba(225, 203, 255, 0.3);
$pre-footer-table-head-bg: rgba(225, 203, 255, 0.1);
$casino-reel-btn-gradient: linear-gradient(248deg, #faba19 38.48%, #f89b38 74.26%);
$casino-reel-btn-border: #ffd500;
$casino-reel-not-free-btn: #55096c;
$casino-reel-rights-free: #ae7fb8;
$casino-reel-rights: #b62ef3;
$toast-success: #219653;
$toast-error: #cc1a1a;
$box-shadow-light-blue: rgba(53, 226, 238, 0.5);
$box-shadow-light-green: rgba(79, 246, 115, 0.5);
$box-shadow-gift: rgba(94, 0, 96, 0.5);
$live-table-closed: #610e62;
$payment-method-hover: rgba(255, 190, 11, 0.3);
$amount-bg: rgba(255, 190, 11, 0.3);
$info-text: rgba(48, 22, 0, 0.5);
$currency-color: rgba(48, 22, 0, 0.6);
$currency-bg: rgba(194, 152, 117, 0.1);
$yellow-alt: #ffbe0b;
$loader-bar-background: rgba(255, 255, 255, 0.3);
$light_orange: #f39316;
$light_blue: #70a7ff;
$light_green: #23e6c2;
$grayish-brown: #ded6c7;
$switch-track: #592522;
$card-border: rgba(194, 152, 117, 0.3);
$menu-red: #fb5607;
$error-message: #a70007;
$red-error: #f21a32;
$copy-input-bg: rgba(194, 152, 117, 0.1);
$copy-input-color: #301600;
$prize-pool-row: #f4ecfd;
$ultra-semi-white: rgba(255, 255, 255, 0.3);
$promo-tag: #006d59;
$wheel-of-fortune-table-background: #663b24;
$wheel-of-fortune-table-title: #301600;
$wheel-of-fortune-table-row: #4f2b0f;
$wheel-of-fortune-timer-text-color: #c29875;
$wheel-of-fortune-page-bg: #f8edeb;
$wheel-of-fortune-table-gradient: linear-gradient(-70deg, #fffb90, #a4631b, #fffb90, #a4631b, #fffb90);
$wheel-of-fortune-hover-bg: #ff6b00;
$android-app-lp-how-to-bg: #6f00c0;
$android-app-lp-circle_bg1: rgba(228, 85, 161, 0.1);
$android-app-lp-circle_bg2: rgba(228, 85, 161, 0.15);
$android-app-lp-circle_bg3: rgba(228, 85, 161, 0.2);
$android-app-lp-comment_bg: rgba(255, 190, 11, 0.15);
$android-app-lp-comment_text: rgba(48, 22, 0, 0.7);
$white-80-opacity: rgba(255, 255, 255, 0.8);
$mystery-chests-modal-bg: #0f060b;
$mystery-chests-yellow: #ffbe0b;

//SWITCH
$switch-thumb-width: 22px;
$switch-track-height: 30px;

//ProgressBar
$progress-bar-background: rgba(131, 56, 236, 0.5);
$progress-bar-low: #4a90ff;
$progress-bar-low-medium: #2cc26c;
$progress-bar-medium: #ffbe0b;
$progress-bar-medium-high: #ff6b00;
$progress-bar-high: #ff006e;
$progress-bar-low-blur: rgba(74, 144, 255, 0.8);
$progress-bar-low-medium-blur: rgba(44, 194, 108, 0.8);
$progress-bar-medium-blur: rgba(255, 190, 11, 0.8);
$progress-bar-medium-high-blur: rgba(255, 107, 0, 0.8);
$progress-bar-high-blur: rgba(255, 0, 110, 0.8);
$progress-bar-tooltip-bg: #2d1311;

//Jackpot
$jackpot-background: #250059;
$jackpot-shadow: rgba(37, 0, 89, 0.9);
$jackpot-mini-shadow: rgba(29, 239, 158, 0.25);
$jackpot-mini-color: #00a395;
$jackpot-minor-shadow: rgba(28, 114, 255, 0.25);
$jackpot-minor-color: #468cff;
$jackpot-major-shadow: rgba(204, 124, 2, 0.25);
$jackpot-major-color: #cc7c02;
$jackpot-major-color-2: #c37505;
$jackpot-grand-shadow: rgba(202, 24, 164, 0.25);
$jackpot-grand-color: #ca18a4;
$jackpot-link-color: #c398ff;
$jackpot-gradient: linear-gradient(180deg, rgba(37, 0, 89, 0) 0%, $jackpot-background 15.99%);
$jackpot-alt-color: #806a5a;
$jackpot-mask: linear-gradient(to bottom, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 70%);
$jackpot-mobile-gradient: linear-gradient(180deg, rgba(37, 0, 89, 0) 0%, #250059 2.14%);
$jackpot-banner-shadow: rgba(48, 22, 0, 0.3);
$jackpot-failed-lobby-color: #301600;
$jackpot-mobile-failed-lobby-color: #806a5a;
