.casino-reel-agent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    min-height: 100vh;
    height: 100%;
    padding: 100px 15px;

    &--free {
        background: url('#{$asset-path}/casino-reel-agent/free_bg-mob.jpg') no-repeat center center;
        background-size: cover;

        .prize--img {
            margin: 35px 0;
        }

        .rights {
            color: $casino-reel-rights-free;
        }
    }

    &--not-free {
        background: url('#{$asset-path}/casino-reel-agent/not_free_bg_mob.jpg') no-repeat center center;
        background-size: cover;
        padding-bottom: 320px;

        .casino-reel-agent--title {
            h1 {
                margin-bottom: 20px;
            }

            h3 {
                margin: 0;
            }
        }

        .reel-agent-btn.btn--not-free {
            background: $casino-reel-btn-gradient;
            color: $casino-reel-not-free-btn;
            margin: 80px 0 100px;
        }

        div.flags {
            margin: 0 0 45px;

            img {
                width: 60px;
                margin: 0 12px;
            }
        }

        .rights {
            color: $casino-reel-rights-not-free;
        }
    }

    .logo {
        width: 150px;
    }

    h1 {
        color: #faba19;
        font-size: 40px;
        font-weight: 800;
        margin: 20px 0 0;
    }

    h3 {
        color: $white;
        font-size: 32px;
        font-weight: 500;
    }

    .reel-agent-btn {
        display: inline-block;
        width: 290px;
        border-radius: 58px;
        border: 1px solid $casino-reel-btn-border;
        background: rgba(31, 3, 43, 0.1);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(4px);
        color: $white;
        font-size: 24px;
        font-weight: 800;
        text-transform: uppercase;
        padding: 15px;
    }

    .flags {
        margin: 65px 0;

        img {
            width: 101px;
        }
    }

    .rights {
        font-size: 12px;
        font-weight: 500;
    }
}
