.bottom-navigation {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    padding: 0 16px;
    background: $new-dark-violet;
    z-index: 3;

    &-list {
        list-style: none;
        display: flex;
        display: -webkit-flex;
        flex-flow: row nowrap;
        -webkit-flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        gap: 5px;
        padding: 0;
        margin: 0;
        position: relative;
        height: inherit;

        &:after {
            content: none;
        }

        &-item {
            padding-top: 5px;

            a {
                text-align: center;
                width: 100%;
                display: block;
                font-weight: 700;
                font-size: 10px;
                line-height: 19px;
                color: $white;
                opacity: 0.5;
                position: relative;

                &.active {
                    opacity: 1;

                    svg {
                        path {
                            fill-opacity: 1;
                        }
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        top: 20px;
                        left: 0;
                        width: 100%;
                        height: 40px;
                        background: $blue;
                        filter: blur(20px);
                        z-index: -1;
                    }
                }

                svg {
                    display: block;
                    flex-shrink: 0;
                    margin: 0 auto;
                    height: 18px;
                }
            }
        }
    }
}

@media (max-width: 319px) {
    .bottom-navigation {
        &-list {
            &-item {
                padding-top: 0;

                a {
                    span {
                        display: none;
                    }
                }
            }
        }
    }
}
