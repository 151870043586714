.header-search-games {
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $new-footer-violet;
    border-radius: 50%;
    border: none;

    img {
        width: 16px;
        height: 16px;
    }

    &-modal {
        &_container {
            display: flex;
            flex-direction: column;
            height: 100%;
            background: $new-footer-violet;

            .search-input-wrapper {
                display: flex;
                align-items: center;
                gap: 8px;
                padding: 4px 14px 4px 16px;
                height: 48px;
                width: 100%;
                background: $white;

                .clear-wrapper,
                .back-arrow-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        width: 20px;
                        height: 20px;

                        path {
                            fill: rgba($new-purple, 0.5);
                            height: 100%;
                            width: 100%;
                        }
                    }
                }

                .search-input {
                    border: none;
                    margin: 0;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 14px;
                    color: $step-content;

                    &::placeholder {
                        color: $step-content;
                        opacity: 0.5;
                    }
                }
            }

            .search-results-wrapper {
                height: 100%;
                overflow-y: auto;

                .search-results {
                    height: inherit;

                    .searched-games-no-results {
                        display: flex;
                        flex-direction: column;
                        height: 100%;

                        .no-results {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 8px;
                            padding: 24px 58px 16px 58px;

                            .wildSultan-searching {
                                width: 164px;
                                height: 142px;

                                source,
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }

                            .no-results-text {
                                width: 100%;
                                color: $white;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 20px;
                                text-align: center;
                                text-overflow: ellipsis;
                                overflow: hidden;

                                .search-value {
                                    color: $yellow-alt;
                                    font-size: 14px;
                                    font-weight: 700;
                                    line-height: 14px;
                                }
                            }

                            .explore-games {
                                color: $new-light-violet;
                                text-align: center;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 14px;
                            }
                        }

                        .feeling-lucky {
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            gap: 8px;
                            padding: 16px;
                            background: $new-deep-violet;

                            &-text {
                                color: $white;
                                font-size: 12px;
                                font-weight: 400;
                                line-height: 12px;
                                text-align: center;
                            }

                            a.btn {
                                width: 100%;
                                max-width: 100%;
                                border: none;

                                svg {
                                    path {
                                        width: 14px;
                                        height: 14px;
                                    }

                                    ellipse {
                                        fill: none;
                                    }
                                }
                            }
                        }
                    }

                    &_section {
                        .title-container {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            height: 40px;
                            margin: 0 14px 0 16px;
                            border-bottom: 1px solid $ultra-semi-white;

                            .title,
                            .subtitle {
                                color: $white;
                                font-size: 12px;
                                line-height: 12px;
                            }

                            .title {
                                font-weight: 700;
                            }

                            .subtitle {
                                font-weight: 400;
                                opacity: 0.5;
                            }
                        }

                        .items-list {
                            .search-results-item {
                                display: flex;
                                align-items: center;
                                gap: 8px;
                                padding: 12px 14px 4px 16px;

                                .item-img {
                                    flex-shrink: 0;
                                    height: 40px;
                                    aspect-ratio: 1.5;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }

                                .item-infos {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 4px;
                                    width: 100%;

                                    &__title {
                                        font-size: 16px;
                                        font-weight: 700;
                                        line-height: 16px;
                                        color: $white;

                                        .highlight {
                                            color: $yellow-alt;
                                        }
                                    }

                                    &__subtitle {
                                        font-size: 12px;
                                        font-weight: 400;
                                        line-height: 12px;
                                        color: $white;
                                        opacity: 0.7;
                                    }
                                }

                                .favorite-wrapper {
                                    width: 20px;
                                    height: 20px;
                                    border: none;
                                    background: none;
                                    padding: 0;
                                    outline: none;

                                    &:disabled {
                                        cursor: not-allowed;
                                        pointer-events: none;
                                    }

                                    svg {
                                        width: inherit;
                                        height: inherit;
                                    }
                                }

                                .arrow-right {
                                    width: 10px;
                                    height: 20px;

                                    svg {
                                        width: inherit;
                                        height: inherit;

                                        path {
                                            fill: $new-light-violet;
                                        }
                                    }
                                }
                            }
                        }

                        .more-items {
                            display: flex;
                            align-items: center;
                            color: $new-light-violet;
                            height: 40px;
                            padding: 0 16px;
                        }
                    }
                }
            }
        }
    }
}
