.switch-wrapper {
    margin: 0;

    .MuiSwitch-root {
        width: 48px;
        height: $switch-track-height;
        padding: 0;

        .MuiSwitch-switchBase {
            padding: 0;
            margin: 4px;
            transition-duration: 300ms;

            &.Mui-checked {
                transform: translateX(18px);
                color: $white;

                .MuiSwitch-thumb {
                    box-sizing: border-box;
                    background-color: $white;
                    transform: scale(1);
                }

                & + .MuiSwitch-track {
                    background-color: $violet-new;
                    opacity: 1;
                    border: 2px solid $violet-new;
                }
            }

            &.Mui-disabled {
                .MuiSwitch-thumb {
                    box-sizing: border-box;
                    background-color: $extra-light-bronze;
                }

                & + .MuiSwitch-track {
                    background-color: rgba($extra-light-bronze, 0.3);
                    border: 2px solid $extra-light-bronze;
                }
            }
        }

        .MuiSwitch-thumb {
            box-sizing: border-box;
            width: $switch-thumb-width;
            height: $switch-thumb-width;
            background-color: $switch-track;
            transform: scale(0.7);
            transition: transform 0.5s;
        }

        .MuiSwitch-track {
            border-radius: $switch-track-height / 2;
            background-color: $white;
            border: 2px solid $switch-track;
            opacity: 1;
            transition: background-color 0.5s;
        }
    }
}
