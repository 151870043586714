.loader {
    font-size: 10px;
    text-indent: -9999em;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: $loader-color;
    background: -moz-linear-gradient(left, $loader-color 10%, rgba(255, 255, 255, 0) 60%);
    background: -o-linear-gradient(left, $loader-color 10%, rgba(255, 255, 255, 0) 60%);
    background: -ms-linear-gradient(left, $loader-color 10%, rgba(255, 255, 255, 0) 60%);
    background: linear-gradient(to right, $loader-color 10%, rgba(255, 255, 255, 0) 60%);
    position: relative;
    animation: load 0.5s infinite linear !important;
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.loader:before {
    width: 50%;
    height: 50%;
    background: $loader-color;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}

.loader:after {
    background: $white;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@keyframes load {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
