.page-container {
    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name {
            font-size: 18px;
            font-weight: 800;

            + a {
                font-size: 12px;
                color: $violet-new;
                font-weight: 600;
            }
        }
    }

    //Styles for marketing pages (vip, mystery-box)
    &-marketing {
        padding: 24px 10px;
        font-size: 14px;

        h1 {
            font-size: 18px;
            font-weight: 800;
            margin-bottom: 16px;
            line-height: normal;
        }

        h2 {
            font-size: 14px;
            font-weight: 800;
            color: $wheel-of-fortune-timer-text-color;
            margin: 0;
            line-height: 18px;
        }

        section {
            margin-bottom: 48px;

            &.page-container-marketing--head-section {
                display: flex;
                flex-direction: column;
                gap: 24px;
            }
        }

        &--header {
            > h1 {
                margin-bottom: 8px;
            }
        }

        &--text-block {
            > a {
                font-weight: 600;
                color: $violet-new;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-image {
            width: 100%;
            object-fit: cover;
            border-radius: 20px;
        }

        .btn.btn-submit.page-container-marketing-btn {
            height: 40px;
            width: 100%;
        }

        &-list {
            display: flex;
            flex-direction: column;
            gap: 16px;
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                padding-left: 18px;

                &:before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 4px;
                    background-color: $yellow-alt;
                }
            }
        }
    }
}
