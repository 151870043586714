.MuiDrawer-paper {
    .menu {
        height: 100%;
        position: relative;

        &-content {
            height: 100%;

            .form-container {
                height: 100%;

                .page-loader-wrapper {
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .form-group {
                    &--error-marked::after {
                        display: none;
                    }

                    .select-wrapper {
                        &::before {
                            display: none;
                        }
                    }
                }

                #register_form_new {
                    padding-bottom: 200px;
                    overflow-y: auto;
                    overflow-x: hidden;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    .form-group {
                        &.address-data {
                            gap: 16px;
                        }

                        .register_mobile {
                            display: flex;
                            flex-direction: column;

                            .btn-send-code {
                                position: relative;
                                margin-top: 10px;
                            }
                        }

                        .phone-number {
                            #register_phone_number {
                                &::placeholder {
                                    opacity: 0.5;
                                }
                            }
                        }

                        .phone-code-selector {
                            select {
                                border: none;
                                min-width: 50px;
                            }
                        }

                        .verification-code {
                            color: $step-content;

                            &--inputs {
                                > div {
                                    display: flex;
                                    justify-content: space-between;

                                    input {
                                        border-radius: 5px;

                                        &:disabled {
                                            background-color: $grayish-brown;
                                        }
                                    }
                                }
                            }

                            a {
                                color: $new-light-violet;
                            }

                            &--send-new-code {
                                color: $new-light-violet;
                            }
                        }

                        #register_firstname,
                        #register_lastname {
                            text-transform: capitalize;
                        }

                        .form-message {
                            &--success {
                                margin-top: 0;
                                font-size: 11px;
                                padding-left: 0;
                            }

                            &--error {
                                margin: 0 0 12px 0px;
                                font-size: 11px;
                                padding-left: 0;
                                display: flex;
                                align-items: center;
                            }
                        }

                        #register_terms {
                            a {
                                color: $new-light-violet;
                            }
                        }

                        .MuiFormGroup-root {
                            gap: 10px;

                            .MuiFormControlLabel-root {
                                display: flex;
                                align-items: center;
                                gap: 10px;

                                .MuiRadio-root {
                                    color: transparent;
                                    background-color: $white;
                                    border-radius: 50%;
                                    width: 25px;
                                    height: 25px;

                                    .MuiSvgIcon-root {
                                        &:nth-child(1) {
                                            path {
                                                display: none;
                                            }
                                        }

                                        &:nth-child(2) {
                                            path {
                                                fill: $violet-new;
                                            }
                                        }
                                    }
                                }

                                .MuiIconButton-colorSecondary:hover {
                                    background-color: transparent;
                                }
                            }
                        }

                        input[type='checkbox'] {
                            & + label {
                                display: block;
                                font-size: 14px;
                                font-weight: 400;
                                line-height: 18px;
                                padding-left: 36px;
                                position: relative;
                                text-transform: none;

                                &::before {
                                    background-color: $white;
                                    border: 1px solid $checkbox-border-grey;
                                    border-radius: 5px;
                                    content: '';
                                    height: 24px;
                                    left: 0;
                                    position: absolute;
                                    top: 1px;
                                    transition: background-color 0.2s ease;
                                    width: 24px;
                                }
                            }

                            &:checked + label::before {
                                background-color: $white;
                                background-image: url(#{$asset-path}/landing/check.svg);
                                background-position: 50%;
                                background-repeat: no-repeat;
                                background-size: 16px auto;
                            }
                        }

                        &--title {
                            color: $white;
                        }

                        &--cell {
                            .MuiSelect-root {
                                border: none;
                            }

                            &-error {
                                &-marked {
                                    &::after {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }

                .register-step {
                    height: calc(100% - 35px);
                    overflow: auto;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    &-container {
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        backdrop-filter: blur(10px);
                        background-color: $game-thumb-text-bg;
                        z-index: 999;
                    }

                    &-loading {
                        height: 100%;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        font-weight: 800;
                        font-size: 21px;
                    }
                }

                .registration-error-view {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;

                    &-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 100%;
                        height: 100%;

                        .logo-wrapper {
                            margin-bottom: 20px;
                        }

                        .registration-error-view-body {
                            font-size: 14px;
                            text-align: center;

                            &-title {
                                font-size: 24px;
                                font-weight: 600;
                                margin-bottom: 20px;
                            }

                            &-content {
                                padding: 0 10px;

                                .content-text {
                                    margin-bottom: 20px;
                                }
                            }
                        }

                        .form--btns {
                            width: 100%;
                            padding: 16px 16px 50px;
                        }
                    }
                }
            }
        }

        .form-container {
            .text-field {
                &--select {
                    height: auto;
                }
            }
        }
    }
}

.MuiListItem {
    &-button:hover {
        background-color: $mui-list-button-grey;
    }

    &-root {
        &.Mui-selected {
            background-color: $mui-list-button-grey;
        }
    }
}

.pac-container {
    z-index: 1301;

    &:after {
        display: none;
    }

    .pac-item {
        @include font-family-nunito;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        padding: 10px 12px;
        border: none;
        color: $dark;

        .pac-icon {
            display: none;
        }

        .pac-matched {
            font-weight: 600;
        }
    }
}
