.games-lobby--featured {
    .game-list-item {
        &--background {
            &.animated {
                background-size: 100% 100%;
                border-radius: 4px 4px 0 0;
                animation: zoomInOut 2s infinite alternate;

                img:last-child {
                    object-fit: contain !important;
                    animation: zoomPerso 2s infinite alternate;
                }
            }
        }
    }
}

//custom animations
@keyframes zoomPerso {
    0% {
        transform: scale(0.8); // Initial scale
    }
    100% {
        transform: scale(1); // Scale factor, adjust as needed
    }
}

@keyframes zoomInOut {
    0% {
        background-size: 110% 110%; // Adjust as needed
    }
    100% {
        background-size: 100% 100%;
    }
}
