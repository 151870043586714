.table,
.transaction-list {
    .payment-provider-method {
        img {
            height: 18px;
        }
    }
}

.transaction-list {
    .transaction-data--reason {
        display: flex;
        align-items: center;
    }
}

.payment-provider-method {
    display: flex;
    align-items: center;

    img {
        width: auto;
        height: 24px;
        max-width: 100%;
    }
}
