select {
    @include form-control;
}

.select {
    width: 100%;

    &:before,
    &:after,
    img {
        display: none;
    }

    input {
        height: 34px;
    }

    .MuiSelect-select {
        background: rgba(255, 255, 255, 1) url('#{$asset-path}/arrow.svg') no-repeat calc(100% - 12px) center;
        height: 38px;

        &:focus {
            border: 1px solid #4a2c10;
            outline: none;
            border-radius: 4px;
            transition:
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
            background: rgba(255, 255, 255, 1) url('#{$asset-path}/arrow.svg') no-repeat calc(100% - 12px) center;
        }
    }

    &.Mui-disabled {
        .MuiSelect-select {
            background-color: #e9ecef;
            opacity: 1;
            cursor: not-allowed;
        }
    }

    .MuiSvgIcon-root {
        fill: #313131;
        width: 1em;
        height: 1em;
        display: inline-block;
        font-size: 1.5rem;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        flex-shrink: 0;
        user-select: none;
        margin-right: 8px;
    }
}

.MuiSelect-select {
    @include font-family-nunito;
}

.MuiSelect-select.MuiSelect-select {
    max-height: 34px;
    border: 1px solid #ded3bf;
    border-radius: 4px;
    padding-left: 12px;
}

.select-wrapper {
    .MuiSelect-select {
        color: #495057;
        padding: 0px;
        padding-left: 10px;
        height: 34px;
        line-height: 34px;
        border-radius: 4px;
        font-weight: 300;
        border: 1px solid #ded3bf;
        font-size: 13px;
        min-width: 250px;
        width: 100%;
    }
}

.MuiAutocomplete-root {
    height: 34px;

    .MuiFormControl-root,
    .MuiInputBase-root {
        height: inherit;
    }

    .MuiInputBase-root {
        padding: 0;

        &.Mui-focused {
            border-color: $main-color !important;
        }
    }

    .Mui-focused {
        fieldset {
            border-color: $main-color;
        }
    }

    fieldset {
        margin: 0;
        border: 1px solid $light-yellow;
    }
}

.MuiAutocomplete-listbox {
    max-height: 170px;
}

.MuiAutocomplete-option,
.MuiAutocomplete-noOptions {
    font-size: 13px;
}
