.casino-games-page {
    .title {
        font-size: 18px;
        font-weight: 800;
        padding: 0 10px;
        margin: 16px 0;
    }

    //TODO Use common style for games / live games
    .top-game-list {
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px;

        .filtering-icon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                content: '';
                border-radius: 8px;
                background: $coral;
                width: 8px;
                height: 8px;
                position: absolute;
                top: -4px;
                right: -4px;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
            }

            &.active:before {
                opacity: 1;
            }
        }

        .title {
            margin: 0;

            .breadcrumbs-container {
                margin-bottom: 0;
            }
        }

        + .title {
            margin-top: 0;
        }
    }

    .game-list {
        @include game-list;

        &-item {
            @include game-list-item;
        }

        + .pagination {
            margin-bottom: 48px;
        }
    }

    .games-list-loader .top {
        justify-content: center;
    }
}

.filtering-tabs {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 32px;

    .MuiTabs-indicator {
        border-radius: 2px;
        height: 2px;
        background: $violet-new;
    }

    &--tab {
        text-transform: capitalize;

        &:not(.Mui-selected) {
            color: $scroll-nav-item;
        }
    }

    + div {
        div[class*='filters--'] {
            margin-bottom: 32px;
        }

        .panel-title {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 16px;
        }

        .filters--volatility {
            .checkbox-group {
                flex-direction: column;
            }
        }
    }
}

.game-filters-list {
    @include hidden-scrollbar;
    list-style-type: none;
    padding: 14px 10px;
    margin: 0;
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 12px;
    font-size: 12px;
    font-weight: 600;

    li {
        a {
            display: flex;
            align-items: center;
            width: max-content;
            height: 40px;
            color: $step-content;
            background: $white;
            padding: 0 16px;
            font-weight: 600;
            position: relative;
            border-radius: 5px;
            transition: color 0.3s ease-in-out;

            > * {
                flex-shrink: 0;
            }

            svg {
                margin-right: 8px;
                max-width: 26px;
            }

            svg path {
                fill: $step-content;
                fill-opacity: 1;
                transition: fill 0.3s ease-in-out;
            }

            &.active {
                background: $violet-new;
                color: $white;

                svg path {
                    fill: $white;
                }
            }
        }
    }
}

.games-lobby {
    .all-games-btn {
        margin: 32px auto;
        width: 200px;
    }
}

.games-lobby,
.lobby--games {
    &--featured {
        .carousel {
            .game-list-item--background {
                aspect-ratio: 4 / 3;
                position: relative;
                width: 100%;
            }
        }

        .game-list-item {
            width: 100%;
            border-radius: 20px;
            box-shadow: 0 24px 24px -12px rgba($game-thumb-text-bg, 0.5);

            &--detail {
                @include details-card;
                background: $game-thumb-text-bg;
                backdrop-filter: blur(5px);
            }
        }
    }

    &--latest,
    &--trending,
    &--favorites,
    &--subcategories {
        margin-bottom: 32px;

        .title {
            &.see-all-hidden {
                .name + a {
                    display: none;
                }
            }
        }

        .game-list {
            display: block;
            margin: 0;
            overflow: hidden;

            .scroll-container {
                @include scroll-container;
                gap: 12px;
                padding: 0 10px;

                .game-list-item {
                    box-shadow: none;
                    min-width: calc(50% - 16px);
                    width: calc(50% - 16px);
                }
            }
        }
    }

    &--providers {
        position: relative;
        display: block;
        margin: 32px 0;
        overflow: hidden;

        .provider-link {
            width: auto;
            display: flex;
            align-items: center;
            position: relative;
            flex-shrink: 0;

            img {
                max-width: 120px;
                max-height: 50px;
                padding: 0 10px;
            }

            &.pragmatic-play {
                img {
                    padding-bottom: 10px;
                }
            }
        }
    }
}

.game-list {
    display: grid;
    grid-template-columns: repeat(3, minmax(92px, 1fr));
    grid-auto-rows: minmax(max-content, auto);
    row-gap: 10px;
    column-gap: 10px;
    align-items: center;
    margin: 4px 12px 16px;
    justify-items: center;

    &--mt-12 {
        margin-top: 12px;
    }

    // COMMON STYLES OF GAME THUMBNAIL
    &-item {
        display: block;
        min-width: 92px;
        width: calc(100vw / 3 - 15px);
        height: auto;
        overflow: hidden;
        position: relative;

        &--jackpot-logo {
            position: absolute;
            padding-top: 6px;
            padding-left: 6px;
            z-index: 1;

            svg {
                width: 26px;
                height: 26px;
            }
        }

        &--table-closed {
            position: absolute;
            z-index: 10;
            width: 100%;
            height: 100%;
            background: $live-table-closed-bg;
            display: flex;
            align-items: center;
            text-align: center;

            p {
                text-align: center;
                width: 100%;
                margin-bottom: 12px;
                color: $live-table-closed;
                font-size: 18px;
                font-weight: 500;
                margin-top: 0;
                text-transform: capitalize;
            }
        }

        &-image {
            border-radius: 10px;
            position: relative;
            top: 0;
            left: 0;
            padding-top: 75%;
            width: 100%;
            background-color: $white;
            background-size: cover;
            background-position: top center;
            background-repeat: no-repeat;
            overflow: hidden;

            img,
            span {
                position: absolute !important;
                top: 0 !important;
                left: 0 !important;
                width: 100% !important;
                height: 100% !important;
            }

            &--live-cover {
                position: absolute;
                z-index: 1;
            }
        }

        &-title {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            font-size: 9px;
            font-weight: 500;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), transparent);
            color: $white;
            padding: 15px 4px 6px;
            border-radius: 0 0 10px 10px;
            z-index: 2;

            &-details {
                display: flex;
                justify-content: center;
                gap: 4px;
                margin-top: 4px;
                font-weight: 300;

                .amount {
                    font-weight: 500;
                }
            }
        }

        // STYLES OF LIVE GAME THUMBNAIL
        &.game-list-item-live {
            width: calc(50% - 6px);
            box-shadow: 0 5px 20px 0 $card-box-shadow;
            border-radius: 10px;

            @media screen and (min-width: 768px) {
                width: calc(25% - 10px);
            }

            .game-list-item-image {
                padding-top: calc(75% - 24px);
                border-radius: 10px 10px 0 0;
            }

            .game-list-item-title {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                padding: 0;
                height: 24px;
                background: $game-thumb-text-bg;
                backdrop-filter: blur(5px);

                .game-name {
                    padding: 0 4px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .game-list-item-title-details {
                    display: none;
                }
            }
        }
    }

    &.horizontal-scroll {
        display: block;
        width: calc(100% + 24px);
        margin-left: -12px;
        margin-right: -12px;
        overflow: hidden;

        .scroll {
            white-space: nowrap;
            padding: 0 6px;
            overflow-x: scroll;
            padding-bottom: 8px;

            .game-list-item {
                display: inline-block;
                margin: 0 6px;
                width: calc(100vw / 3 - 3px);
            }
        }
    }
}

.top-game-list {
    .title {
        padding: 8px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .name {
            font-size: 16px;
            text-transform: capitalize;
            font-weight: 700;
        }
    }
}

.no-items-message {
    padding: 0;
    margin: 0 0 12px;
    text-align: left;
    line-height: 24px;
    opacity: 0.6;

    &--games {
        margin: 0;
        padding: 0 10px;
        text-align: center;
        opacity: 1;

        p {
            color: $scroll-nav-item;
        }
    }
}

.tap-to-play {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    background: $overlay_bg;
    backdrop-filter: blur(5px);
    z-index: 999998;

    .bottom {
        text-align: center;
        text-transform: uppercase;
        padding-bottom: 64px;
        color: $white;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        font-size: 32px;

        .animation {
            &.tap {
                svg {
                    width: 80px;
                    overflow: visible;
                    position: relative;

                    #hand {
                        transform: rotate(-17deg);
                        transform-origin: center;
                        animation: tap 2s linear 2s infinite;
                    }

                    * {
                        fill: $white;
                    }
                }
            }
        }
    }
}

.game-preview {
    &--image {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 22px;

        img {
            max-width: 300px;
            width: 100%;
            height: auto;
            border-radius: 10px;
        }
    }

    &--data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        .favorite-control {
            border: none;
            background: none;
            color: $violet-new;
            padding: 0;

            &.favorite {
                svg {
                    path {
                        fill: $yellow-alt;
                        stroke: $yellow-alt;
                    }
                }
            }

            svg {
                position: relative;
                transform: translate(0, 25%);
                margin-right: 8px;

                path {
                    transition:
                        fill,
                        stroke 0.3s ease-in-out;
                }
            }
        }
    }

    .game-list-item--limits-modern {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .game-list-item--text-details {
            &-minLimit,
            &-maxLimit {
                .label,
                .amount {
                    font-weight: 700;
                    font-size: 14px;
                }

                .label {
                    display: flex;
                    align-items: center;
                    margin-bottom: 6px;

                    svg {
                        margin-right: 6px;
                    }
                }
            }

            &-minLimit {
                .amount {
                    color: $blue;
                }
            }

            &-maxLimit {
                .amount {
                    color: $coral;
                }
            }
        }
    }
}

.subcategories-carousel {
    .playlist-card {
        @include details-card;
        border-radius: 10px;
    }
}

@media screen and (min-width: 769px) {
    .game-list-item-title {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .game-list-item-title {
        font-size: 12px;
    }
}

@media screen and (max-width: 440px) {
    .game-list-item-title {
        font-size: 9px;
    }
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 1;
    }
    75% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes swipe-up {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(-100%);
    }
}

@keyframes tap {
    25% {
        transform: rotate(-17deg) scale(1);
    }
    35% {
        transform: rotate(-17deg) scale(1.3);
    }
    45% {
        transform: rotate(-17deg) scale(1);
    }
    55% {
        transform: rotate(-17deg) scale(1.3);
    }
    65% {
        transform: rotate(-17deg) scale(1);
    }
}
