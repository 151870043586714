.android-app-guide {
    padding: 24px 10px 0;

    h4 {
        font-size: 14px;
        font-weight: 700;
        line-height: 21px;
    }

    p {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
    }

    .btn.btn-modern {
        width: max-content;
        height: 30px;
        font-size: 12px;
        font-weight: 800;
        padding: 3px;
        border-radius: 15px;

        svg {
            width: 24px;
            height: 24px;
        }

        span {
            margin: 0 12px 0 8px;
        }
    }

    &--banner {
        margin-bottom: 48px;

        .banner {
            height: 300px;

            .offer {
                background: $android-app-lp-banner-gradient;
                padding: 24px 16px;

                &-details {
                    h1 {
                        font-size: 24px;
                        font-weight: 800;
                        line-height: 30px;
                        padding-bottom: 12px;
                        margin-bottom: 12px;
                        max-width: 170px;
                    }

                    h2 {
                        font-size: 16px;
                        font-weight: 800;
                        line-height: 30px;
                        margin-bottom: 16px;
                    }

                    h3 {
                        font-size: 14px;
                        font-weight: 800;
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }

    &--description {
        margin-bottom: 28px;

        .description-container {
            display: flex;
            flex-direction: column;
            align-items: baseline;
        }

        .description-item {
            max-width: 720px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;

            img {
                width: 140px;
            }

            &--visual {
                .slide-promotions,
                .slide-notifications {
                    width: 118px;
                }
            }
        }
    }

    &--try-out {
        margin: 0 -10px 28px;

        .try-out-container {
            padding: 0 10px 24px;
        }

        .separator {
            height: 20px;
        }

        &--visual {
            padding: 25px 0 40px;
            width: 300px;
            margin: 0 auto;

            .phone {
                &-big {
                    width: 120px;
                    left: 65px;
                    top: 0;
                    z-index: 2;
                }

                &-small {
                    width: 100px;
                    left: 25px;
                    top: 25px;
                    z-index: 1;
                }
            }

            .circles-animation {
                width: 170px;
                height: 170px;

                .circle1 {
                    width: 170px;
                    height: 170px;
                }

                .circle2 {
                    width: 150px;
                    height: 150px;
                }

                .circle3 {
                    width: 130px;
                    height: 130px;
                }
            }
        }

        &--text {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;

            .name {
                margin-bottom: 16px;
            }

            p {
                margin: 0 0 24px;
            }
        }
    }

    &--how-to {
        .how-to-item {
            display: flex;
            min-height: 250px;
            max-width: 720px;

            img {
                max-width: 120px;
            }

            .comment {
                padding: 10px;
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
            }
        }

        svg {
            transform: rotate(90deg) translate(-50%, -100%);
        }

        .reversed {
            transform: rotateX(180deg) rotateZ(270deg) translate(-50%, 100%);
        }
    }

    @keyframes slide-promotions {
        0% {
            opacity: 0;
        }
        5% {
            opacity: 1;
            top: 38.9%;
            left: 7.78%;
        }
        33.33% {
            opacity: 1;
            top: 38.9%;
            left: 7.78%;
        }
        38.89% {
            opacity: 0;
            top: 38.9%;
            left: 7.78%;
        }
        100% {
            opacity: 0;
            top: 38.9%;
            left: 7.78%;
        }
    }
}
