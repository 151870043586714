.cms-page {
    .form-loader .top {
        justify-content: center;
    }

    &-content {
        min-height: 540px;
        width: 100%;
        border-radius: 4px;
        padding: 0;
        overflow: hidden;

        .printable-content {
            > *:first-child {
                font-weight: 800;
                font-size: 32px;
            }
        }

        a {
            color: $violet-new;
        }

        .content-loading {
            text-align: center;
            margin: 64px 0;

            .loader {
                width: 45px;
                height: 45px;
                margin: 16px auto;
            }
        }

        .no-content {
            text-align: center;
            font-weight: bold;
        }

        .printBtn {
            margin-bottom: 10px;
        }
    }

    &__header {
        padding: 32px 100px 32px 60px;
        display: flex;
        align-items: center;
        height: auto;
        position: relative;
        margin-bottom: 15px;

        &-image {
            margin-right: 30px;

            img {
                max-width: 100px;
                max-height: 104px;
            }
        }

        h1 {
            font-size: 32px;
            line-height: 36px;
            margin: 0;
            font-weight: 300;
            flex-basis: 58%;
        }

        &-title {
            display: flex;
            align-items: center;
            flex-grow: 1;

            &.no-image {
                h1 {
                    flex-basis: 100%;
                }
            }
        }

        &-subheading {
            padding-left: 50px;
            font-size: 15px;
            line-height: 22px;
            flex-basis: 62%;
            font-weight: 300;
        }
    }

    &__content {
        padding: 40px 100px;

        p {
            font-size: 14px;
            line-height: 1.4;

            & + p {
                margin-bottom: 40px;
            }
        }

        .text-center {
            text-align: center;
        }
    }

    &__title {
        color: $cms-title;
    }

    &__banners {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 40px;
        border-bottom: 1px solid $grey;
        margin-bottom: 40px;
    }

    &__table {
        width: 100%;
        margin: 0 0 60px;

        thead {
            th {
                border-bottom: 2px solid $grey;
                padding: 10px 0;
            }
        }

        tr {
            & + tr {
                border-top: 1px solid rgba(128, 128, 128, 0.15);
            }

            td {
                color: $grey;
                padding: 10px 0;

                &:first-child {
                    text-align: left;
                    color: $main-color;
                }
            }
        }
    }

    &.why-us {
        .cms-page__header {
            &-title {
                text-align: center;

                h1 {
                    font-size: 24px;
                    font-weight: 300;
                }
            }
        }

        .cms-page {
            &__content {
                padding: 60px;
                font-size: 14px;
            }
        }
    }

    .why-us {
        &__row {
            width: 600px;
            margin: 0 auto 50px;
        }

        &__icon {
            float: left;
            width: 180px;
            height: 100px;
            margin-right: 20px;
            background-image: url('#{$asset-path}/whyus-icons.png');
            background-repeat: no-repeat;
            mix-blend-mode: multiply;

            &-bonus {
                background-position: center 5px;
            }

            &-payouts {
                background-position: center -107px;
            }

            &-features {
                background-position: center -200px;
                height: 108px;
            }

            &-games {
                background-position: center -315px;
            }

            &-secure {
                background-position: center -420px;
            }
        }

        &__point-title {
            font-weight: 700;
            margin-bottom: 20px;
            font-size: 16px;
        }
    }
}
