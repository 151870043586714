.reality-check-modal {
    @include font-family-nunito;
    display: flex;
    justify-content: center;
    color: $white;
    font-size: 16px;
    line-height: 18px;

    &_body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 6px;
        width: 100%;
        height: 100%;

        .reality-check_body-content {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .reality-check-wrapper {
                display: flex;
                width: 100%;
                flex-direction: column;
                justify-content: space-between;

                .reality-check-row {
                    display: flex;
                    justify-content: space-between;
                    padding: 16px 0;
                    border-bottom: 1px solid rgba(194, 152, 117, 0.3);
                    color: $step-content;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;

                    &:last-child {
                        border-bottom: none;
                    }

                    &--title,
                    &--value {
                        width: 50%;
                    }

                    &--value {
                        font-weight: 700;
                        text-align: right;
                    }
                }
            }
        }

        &_footer {
            .container-btns {
                display: flex;
                justify-content: center;
                gap: 20px;
                padding: 0 50px;

                .btn {
                    width: 100%;
                }
            }
        }
    }
}
