header {
    background: $new-dark-violet;
    color: $white;
    position: relative; // Relative should be applied if sticky is not supported
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 3;

    .accept-privacy-policy {
        &--cookies {
            &-enabled,
            &-disabled {
                @include font-family-nunito;
                width: 100%;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-box-align: end;
                -ms-flex-align: end;
                align-items: flex-end;
                -webkit-box-pack: space-evenly;
                -ms-flex-pack: space-evenly;
                justify-content: space-evenly;
                padding: 20px;
                color: $white;
                font-size: 12px;
                z-index: 999999;
                background: $new-purple;

                a {
                    color: white;
                    text-decoration: underline;
                }

                &--description {
                    margin-top: 0;
                }

                .btn {
                    font-size: 12px;
                }
            }
        }
    }

    .header {
        &-container {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            &-cell {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 16px;
                padding: 0 16px;
                width: 100%;

                .btn {
                    flex: 1 1 0;
                    max-width: 140px;
                    font-size: 12px;
                }
            }

            .menu-button {
                display: flex;
                flex-direction: column;
                flex-shrink: 0;
                gap: 4px;
                padding: 15px 13px;
                background: transparent;
                border: none;
                border-right: 1px solid $ultra-semi-white;

                div {
                    height: 4px;
                    border-radius: 2px;
                    background: $white;
                    transition: width 200ms ease;
                }

                div:nth-child(1) {
                    width: 24px;
                }

                div:nth-child(2) {
                    width: 14px;
                }

                div:nth-child(3) {
                    width: 18px;
                }
            }
        }
    }
}

@media (max-width: 319px) {
    header {
        .header-container {
            &-cell {
                padding-top: 0;

                .btn {
                    font-size: 10px;
                    line-height: 10px;
                }
            }
        }
    }
}
