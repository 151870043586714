.static {
    display: flex;
    flex-direction: column;
    padding: 24px 10px;
    font-size: 14px;

    &--loader {
        padding-top: 24px;

        &.form-loader .top {
            justify-content: center;
        }
    }

    h2 {
        margin-top: 15px;
    }

    h3 {
        margin-top: 15px;
        font-size: 18px;
        color: $font-play-tab;
    }

    a {
        font-weight: bold;
        text-decoration: underline;
    }

    li {
        margin-top: 5px;
    }
}
