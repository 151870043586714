.withdrawal-flow {
    .modal-box {
        &--content {
            position: relative;

            .withdrawal-form-error {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
            }

            #withdraw_form {
                position: initial;
                height: 100%;
                padding-bottom: 124px;
                overflow-y: auto;

                .withdrawal-header-wrapper {
                    position: sticky;
                    top: 0;
                    z-index: 1;
                    background-color: $white;

                    .page-message {
                        margin-bottom: 12px;
                    }

                    .financial-page--data-title {
                        margin-top: 0;
                    }
                }

                .financial-page--data {
                    max-height: unset;
                    height: auto;
                    overflow: initial;
                    padding: 0;

                    form {
                        height: 100%;

                        .form-body {
                            height: 100%;
                        }
                    }
                }

                .container-btns {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    background-color: $white;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    padding-top: 20px;

                    &:before {
                        display: none;
                        content: '';
                        position: absolute;
                        width: 100%;
                        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $white 100%);
                    }

                    .btn-cancel {
                        order: 2;
                    }

                    .btn-submit {
                        order: 1;
                    }
                }
            }

            .methods-list {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                margin: 0 0 20px 0;
            }

            .withdrawal-method {
                position: relative;
            }

            .method-radio {
                border-radius: 5px;
                background-color: $white;
                background-image: none;
                border: 1px solid $card-border;
                width: calc((100% - 24px) / 3);
                height: 48px;
                filter: grayscale(0);
                opacity: 1;
                margin: 0;
                display: flex;
                justify-content: center;
                min-width: 90px;
                position: relative;
                padding: 4px;

                .MuiRadio-root {
                    display: none;
                }

                &.checked {
                    background: $amount-bg;
                    border: 1px solid $yellow-alt;
                    transition: all 0.2s ease-in-out;
                }

                &:after {
                    display: none;
                }

                .wdr-data-select {
                    width: 100%;
                    max-width: 90px;
                    height: 80%;
                    top: 50%;
                    transform: translate(0, -50%);
                }

                &:after {
                    display: none;
                }
            }

            .wdr-data-select {
                width: 100%;

                .MuiSelect-select {
                    font-family: inherit;
                    padding: 0 24px 0 12px;
                    max-height: 40px;
                    min-width: auto;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    &:focus {
                        background-color: $white;
                    }
                }
            }

            .transaction-amount {
                flex-direction: column;
                margin-bottom: 24px;
                gap: 0 !important;
                overflow: hidden;

                .financial-page--data-title {
                    margin-bottom: 12px;
                }

                &-extra {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    margin: 8px 0 0;
                    color: $info-text;

                    .min-max-amounts {
                        display: flex;
                    }

                    + .email {
                        margin: 10px 0 !important;
                    }
                }
            }

            .page-message--error {
                margin: 0;
            }

            .financial-page--data {
                max-height: 100%;
                overflow-y: auto;
                font-size: 14px;

                &-title {
                    font-weight: 700;
                    line-height: 19px;
                    margin: 0 0 16px;
                    font-size: 14px;
                }
            }
        }

        a {
            color: $violet-new;
        }
    }
}

#menu-email {
    .MuiList-root {
        padding: 0;
    }

    .MuiListItem-root {
        @include mui-list-item-root;
        @include font-family-nunito;
    }
}
