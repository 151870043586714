input[type='checkbox'] {
    display: none;
}

input[type='checkbox'] + label {
    padding-left: 18px;
    position: relative;

    a {
        text-decoration: underline;
    }
}

input[type='checkbox'] + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 1px;
    width: 12px;
    height: 12px;
    border: 1px solid #4a2c10;
    border-radius: 3px;
    transition: background-color 0.2s ease;
}

input[type='checkbox']:checked + label:before {
    background-color: #4a2c10;
    background-image: url('#{$asset-path}/check.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px auto;
}

.checkbox-container {
    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}
