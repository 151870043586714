.profile {
    &-container {
        display: flex;
        gap: 70px;

        &--wrap {
            flex-wrap: wrap;
            margin-bottom: 68px;

            .profile-column {
                min-width: max-content;

                .profile-data {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-column {
        flex: 1 1 0;

        &.half-grow {
            flex-grow: 0.5;
        }

        &.bank-section {
            flex: none;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 50px;
        }

        &.change-password {
            min-width: 390px;
        }
    }

    &-data {
        margin-bottom: 68px;

        &--title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 32px;
            font-weight: 800;
            margin-bottom: 24px;
            gap: 20px;
            flex-wrap: wrap;

            &-link {
                font-size: 14px;
                font-weight: 600;
                color: $violet-new;
                cursor: pointer;

                &.show-more {
                    display: flex;
                    justify-content: center;
                    margin-top: 24px;
                }
            }
        }

        &--description {
            margin: 0 0 24px 0;
            font-size: 16px;
        }

        &--details {
            margin-bottom: 24px;
            color: $step-content;
            text-align: justify;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }

        &--content,
        &--mfa-content {
            border-radius: 20px;
            background: $light-peach;
            padding: 24px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            position: relative;

            &:after {
                @include box-shadow;
                width: calc(100% - 48px);
                top: 24px;
                left: 24px;
            }

            &-list {
                padding: 0 24px;
                margin: 0;
                list-style-type: none;
                gap: 0;

                li {
                    padding: 24px 0;

                    &:not(:last-of-type) {
                        border-bottom: 1px solid rgba(194, 152, 117, 0.3);
                    }
                }
            }

            &-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 16px;
                gap: 20px;

                div:first-of-type {
                    text-align: left;
                    text-transform: capitalize;
                }

                div:last-of-type {
                    text-align: right;
                    font-weight: 600;
                    word-break: break-all;
                }

                h3 {
                    font-size: 21px;
                    margin-bottom: 0;
                }
            }

            &-description {
                font-size: 16px;
                font-weight: 400;
                margin: 0;
            }

            .bank-card {
                font-size: 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &_wrapper {
                    display: flex;
                    align-items: center;

                    .payment-option {
                        width: 60px;
                        margin-right: 12px;
                        justify-content: center;
                    }
                }

                &_delete {
                    svg {
                        width: 16px;
                    }
                }
            }

            .document-upload-error {
                margin-top: 16px;
                color: $red-error;
            }

            .profile-card {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 20px;

                .status {
                    font-size: 16px;
                    font-weight: 700;
                    color: $menu-red;

                    &:before {
                        content: '';
                        position: relative;
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        margin-right: 10px;
                        border-radius: 50%;
                        top: 1px;
                        background: $menu-red;
                    }

                    &_to_upload,
                    &_mfa_inactive {
                        color: $violet-new;

                        &:before {
                            background: $violet-new;
                        }
                    }

                    &_to_validate {
                        color: $blue;

                        &:before {
                            background: $blue;
                        }
                    }

                    &_validated,
                    &_mfa_active {
                        color: $status_validated;

                        &:before {
                            background: $status_validated;
                        }
                    }

                    &_refused,
                    &_expired {
                        color: $menu-red;

                        &:before {
                            background: $menu-red;
                        }
                    }
                }

                .name {
                    margin: 16px 0;
                    font-weight: 700;
                    font-size: 21px;
                }

                .infos {
                    font-size: 16px;
                }

                .status span,
                .name {
                    display: inline-block;

                    &:first-letter {
                        text-transform: capitalize;
                    }
                }
            }

            .responsible-gaming-item {
                display: flex;
                flex-direction: column;
                gap: 20px;

                .status {
                    font-size: 16px;
                    font-weight: 700;
                    color: $violet-new;

                    &:before {
                        content: '';
                        position: relative;
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        margin-right: 10px;
                        border-radius: 50%;
                        top: 1px;
                        background: $violet-new;
                    }

                    &.active {
                        color: $menu-green;

                        &:before {
                            background: $menu-green;
                        }
                    }
                }

                .name {
                    margin: 16px 0;
                    font-weight: 700;
                    font-size: 21px;
                }

                .infos {
                    font-size: 16px;
                }

                .status span,
                .name {
                    display: inline-block;

                    &:first-letter {
                        text-transform: capitalize;
                    }
                }

                .btn {
                    width: 204px;
                }
            }

            .communication-preferences-content {
                form {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    .checkbox-container {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 18px;

                        .checkmark {
                            width: 24px;
                            height: 24px;
                            min-width: 24px;
                            min-height: 24px;
                        }
                    }

                    .description {
                        font-size: 16px;
                        font-weight: 400;
                    }

                    .options-container {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 24px;
                    }

                    .btns-container {
                        display: flex;
                        gap: 10px;
                        align-items: center;

                        button {
                            width: 140px;
                            height: 40px;
                        }
                    }
                }
            }
        }

        &--mfa-content {
            gap: 14px;
        }

        &--vip-status {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 16px;

            h1 {
                font-size: 24px;
                font-weight: 700;
                margin: 0;
            }

            img {
                width: 60px;
                height: 60px;
            }

            &-data {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 24px;
            }
        }

        .load-more {
            margin-bottom: 0;
        }

        &.bank-card-data {
            flex: 1;
            min-width: 390px;
            margin-bottom: 0;
        }
    }

    .form-loader {
        width: 100%;

        .top {
            margin: 0;
            justify-content: center;
        }
    }

    .mfa-list {
        min-height: 600px;
        padding: 0;
        position: relative;

        .form-loader {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            background: $light-peach;
            border-radius: 20px;
        }

        &.empty {
            display: flex;
            align-items: center;
            justify-content: center;

            .empty-list-message {
                text-align: center;
                font-weight: 600;
                font-size: 16px;
            }
        }
    }

    .mfa-list {
        min-height: 100%;
    }

    .responsible-gaming-list {
        padding: 0;
        position: relative;
    }

    &-change-password {
        .modal-box {
            &--content {
                display: flex;
                flex-direction: column;

                .container-text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    width: 100%;
                    height: 90%;

                    h4 {
                        width: 73%;
                    }
                }

                .container-btns {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    &--wrapper {
                        width: 73%;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .btn {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.bank-card--actions {
    display: flex;
    align-items: center;
    gap: 12px;

    button {
        display: flex;
        cursor: pointer;
        border: none;
        background: 0;
        margin: 0;
        padding: 0;

        &:not(.active):hover {
            filter: invert(54%) sepia(99%) saturate(7117%) hue-rotate(258deg) brightness(96%) contrast(93%);
        }

        svg {
            height: 22px;
            width: auto;
        }
    }
}

.self-exclusion-modal {
    &--body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 46px;
        height: 100%;

        .header {
            margin: 0;
            color: $step-content;
            font-size: 14px;
            font-weight: 700;
        }

        .details {
            margin: 0;
            color: $step-content;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }

        .self-exclusion-selected-period-details {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 12px 0;
            border-top: 1px solid $card-border;
            border-bottom: 1px solid $card-border;

            .info {
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: space-between;

                &--one,
                &--two {
                    color: $step-content;
                    font-weight: 700;
                }

                &--one {
                    font-size: 14px;
                }

                &--two {
                    font-size: 11px;
                }
            }
        }

        .details-wrapper {
            height: 100%;
        }
    }

    &--periods {
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;

        .periods-list {
            padding: 0;

            label {
                width: auto;

                .MuiFormControlLabel-root .MuiTypography-root {
                    color: $step-content;
                    text-align: justify;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 18px;
                }
            }
        }
    }

    &--confirm {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;

        &.step-2 {
            .btn.btn-submit.btn-modern {
                background: $menu-red;
                color: $white;

                &:hover {
                    background: $yellow-alt;
                    color: $step-content;
                }
            }
        }
    }
}
