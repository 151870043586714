.error-page-play {
    h1 {
        font-size: 32px;
        font-weight: 800;
        margin-bottom: 24px;
    }
}

#play,
#miniPlay,
#evoPlay {
    display: flex;
    justify-content: center;
    margin: 15px 0;

    .game-page {
        position: relative;
        border-radius: 20px;

        &.minimized {
            border-radius: inherit;
        }

        &-bg {
            height: inherit;
            position: absolute;
            border-radius: inherit;
            width: 100%;
        }
    }

    .actions {
        position: absolute;
        top: 0;

        .action {
            cursor: pointer;
            position: relative;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-bottom: 8px;
            border: 1px solid $step-content;
            padding: 0;
            background: none;
            display: flex;
            align-items: center;
            justify-content: center;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-fill-mode: forwards;

            &:disabled {
                pointer-events: none;
                opacity: 0.6;
            }

            svg {
                path {
                    fill: $step-content;
                }
            }

            &:hover {
                border-color: $violet-new;
                background: $white;

                svg {
                    path {
                        fill: $violet-new;
                    }
                }
            }

            &.favorite {
                border: 1px solid $violet-new;
                background: $violet-new;

                svg {
                    path {
                        fill: $yellow-alt;
                    }
                }

                &:hover {
                    background: $favorite-hover;
                }
            }

            &.toggle-game-infos {
                &::before {
                    background-position-x: -15px;
                }

                &.hide::before {
                    background-position-x: -30px;
                }
            }

            &:hover::before {
                background-position-y: -15px;
                opacity: 1;
            }

            &.close_game_icon {
                svg {
                    width: 13px;
                }
            }

            &.opened-modal {
                pointer-events: none;
            }

            &.fullscreen {
                filter: invert(100%);

                &:hover {
                    filter: invert(0%);
                }
            }

            &.animated {
                animation-name: sequentialAnimation;
            }

            &.disabled {
                pointer-events: none;
                cursor: not-allowed;
            }
        }
    }

    #game,
    #miniGame {
        width: inherit;
        height: inherit;
        border: none;
        position: relative;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        transition: all 0.3s ease-in-out;
        background: $black;
        border-radius: 20px;
        box-shadow: 0 24px 24px -12px $box-shadow-dark;

        iframe {
            border-radius: inherit;
        }
    }

    #miniGame {
        border-radius: 0 0 20px 20px;
        height: 100%;
    }

    .play-frame {
        position: relative;
        margin: 15px auto;
        color: $white;
        background: $black;
        height: inherit;
        transition: min-height 0.3s ease-in-out;
    }

    .game-infos {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 270px;
        padding: 24px 16px;
        font-weight: 300;
        overflow-x: hidden;
        margin: 0 0 10px auto;
        z-index: 20;
        opacity: 1;
        transition:
            opacity,
            width 0.3s ease-in-out;
        color: $step-content;
        background: $light-peach;
        border-radius: 20px;
        height: 100%;
        box-shadow: 0 24px 24px -12px $box-shadow-peach;

        > div {
            width: calc(270px - 32px);
        }

        &--row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            font-weight: 700;
        }

        &.hide {
            opacity: 0;
            width: 0;
        }

        .game-indicators {
            > div:last-of-type {
                margin-bottom: 48px;
            }
        }

        h1 {
            font-size: 18px;
            font-weight: 800;
            margin: 0 0 12px;
            line-height: 20px;
        }

        .mode {
            font-size: 12px;
            text-transform: uppercase;
            color: $yellow;
            margin-bottom: 12px;
        }

        .provider {
            margin-bottom: 30px;
        }

        .volatility,
        .rtp {
            margin-bottom: 8px;
            font-weight: bold;

            span {
                text-transform: capitalize;
            }
        }

        .volatility {
            .value {
                color: $violet-new;
            }
        }

        .rtp {
            .value {
                color: $blue;
            }
        }

        .play-table {
            .latest-winners {
                padding: 0;
                margin: 24px 0;
                list-style-type: none;

                li {
                    border-bottom: 1px solid $latest-winner-card-border;
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(
                            90deg,
                            rgba(255, 190, 11, 0) 0%,
                            rgba(255, 190, 11, 0.12) 51.04%,
                            rgba(255, 190, 11, 0) 100%
                        );
                        z-index: -1;
                        opacity: 0;
                        transition: opacity 0.3s;
                    }

                    &:hover:before {
                        opacity: 1;
                    }

                    &:first-of-type {
                        border-top: 1px solid $latest-winner-card-border;
                    }

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        gap: 12px;
                        padding: 16px 0;
                    }

                    .winner-name {
                        font-size: 16px;
                        color: $violet-new;
                    }

                    .win-date {
                        font-size: 12px;
                    }

                    .win-amount {
                        flex-shrink: 0;
                        font-size: 16px;
                        font-weight: 700;
                    }
                }
            }
        }
    }

    @media (max-width: 1130px) {
        display: block;
    }
}

#miniPlay {
    margin: 0;
    padding: 0;
    width: 100%;
    height: calc(100% - 60px);
}

#evoPlay {
    position: absolute;
    width: 100%;
}

#evoErrorPlay {
    position: absolute;
    top: 45px;
    width: 100%;
}

#iframe {
    z-index: 1;
    border-radius: 20px;
}

.progress {
    &-rtp {
        .MuiLinearProgress-bar {
            background: $blue;
        }
    }

    &-volatility {
        .MuiLinearProgress-bar {
            background: $violet-new;
        }
    }
}

.custom-tooltip {
    background-color: $tooltip-brown-bg;
    color: $white;
    border-radius: 4px;
    padding: 8px 8px;
    margin-right: 6px;
}

@keyframes hoverEffect {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes sequentialAnimation {
    0%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
        border-color: $violet-new;
        background: $white;
    }
}
