.mystery-chests {
    line-height: 21px;

    &--open-chest {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        line-height: 21px;
        text-align: center;
    }

    &--treasures-list {
        @include scroll-container;
        list-style-type: none;
        gap: 10px;
        padding: 0 10px;
        margin: 0 -10px;
        width: calc(100% + 20px);

        li {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 240px;
            flex-shrink: 0;
            border-radius: 20px;
            background: $light-peach;
            padding: 32px 16px 16px;

            img {
                height: 90px;
                max-width: 100%;
                margin-bottom: 24px;
            }

            .mystery-chests--treasure-title {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 12px;
            }
        }
    }

    &--welcome {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 48px;
        gap: 32px;

        &-icons {
            display: flex;
            align-items: center;
            gap: 16px;
            flex-shrink: 0;

            img {
                flex-shrink: 0;
                max-width: 100%;

                &:nth-of-type(1),
                &:nth-of-type(5) {
                    height: 32px;
                }

                &:nth-of-type(2),
                &:nth-of-type(4) {
                    height: 48px;
                }

                &:nth-of-type(3) {
                    height: 70px;
                }

                &:nth-of-type(1),
                &:nth-of-type(3),
                &:nth-of-type(4) {
                    transform: rotate(-15deg);
                }

                &:nth-of-type(2),
                &:nth-of-type(5) {
                    transform: rotate3d(-0.1, 1, 0, 180deg);
                }
            }
        }

        &-content {
            text-align: center;
        }
    }

    &--terms {
        ul {
            display: block;
            column-count: 2;
            column-gap: 70px;

            li:not(:last-of-type) {
                margin-bottom: 24px;
            }
        }
    }
}

.mystery-chests-modal {
    .modal-box {
        padding: 0;
        border-radius: 20px;
        background: $mystery-chests-modal-bg;

        .modal-box--title {
            margin: 0;
            z-index: 3;
            position: relative;
            width: 100%;

            .modal-box--close {
                position: absolute;
                top: 24px;
                right: 16px;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: none;
                border: 1px solid $white;
                opacity: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;

                svg {
                    path {
                        fill: $white;
                    }
                }

                &:before {
                    display: none;
                }
            }
        }

        .modal-box--content {
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: inherit;
            background: inherit;
            padding: 24px 16px;
            overflow-y: auto;

            .modal-body {
                position: relative;
                z-index: 2;
                width: 100%;
                min-height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                gap: 30px;

                > * {
                    width: 100%;
                }

                .gradient-border {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    border-radius: 20px;
                    position: relative;
                    margin-bottom: 16px;

                    &::before {
                        content: '';
                        position: absolute;
                        inset: 0;
                        padding: 4px;
                        border-radius: 20px;
                        background: $wheel-of-fortune-table-gradient, linear-gradient(0deg, $white, $white);
                        mask:
                            linear-gradient($white 0 0) content-box,
                            linear-gradient($white 0 0);
                        -webkit-mask:
                            -webkit-linear-gradient($white 0 0) content-box,
                            -webkit-linear-gradient($white 0 0);
                        -webkit-mask-composite: xor;
                        mask-composite: exclude;
                    }

                    &--text {
                        width: inherit;
                        background: $android-app-lp-comment_text;
                        backdrop-filter: blur(5px);
                        color: $white;
                        padding: 20px 24px;
                        border-radius: 20px;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        margin: 4px;
                        font-size: 14px;
                        gap: 12px;

                        > h4 {
                            font-size: 24px;
                            font-weight: 700;
                            margin: 0;
                        }
                    }
                }

                .mystery-chests--animated {
                    position: relative;
                    margin: 35% auto auto;

                    &-loader,
                    &-boxes {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &-loader {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                    }

                    &-boxes {
                        gap: 10px;
                    }

                    .mystery-chests-animated-box {
                        &.mystery-chests-animated-box--disabled {
                            pointer-events: none;
                        }

                        &:nth-of-type(1),
                        &:nth-of-type(3) {
                            width: 30%;

                            img {
                                min-height: 82px;
                                max-width: 100%;
                            }
                        }

                        &:nth-of-type(1) {
                            @include mystery-chest-floating(rotate(-7deg), -5px, 5px);
                        }

                        &:nth-of-type(2) {
                            width: 45%;
                            @include mystery-chest-floating(rotate(0deg));

                            img {
                                min-height: 120px;
                                max-width: 100%;
                            }
                        }

                        &:nth-of-type(3) {
                            @include mystery-chest-floating(rotate3d(-0.1, 1, 0, 180deg), -5px, 5px);
                        }
                    }
                }
            }
        }

        .background-image {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: auto;

            &--fallback {
                z-index: 0;
            }

            &--animated {
                opacity: 0;
                transition: opacity 0.1s ease-in-out;

                &.background-image--loaded {
                    opacity: 1;
                }
            }

            &--fallback,
            &--loaded {
                &:before {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 50px;
                    background: linear-gradient(180deg, rgba(15, 6, 11, 0) 0%, $mystery-chests-modal-bg 80%);
                }
            }

            img {
                object-fit: cover;
                border-radius: 20px 20px 0 0;
                width: 100%;
                height: 100%;
            }
        }

        .container-btns {
            .btn {
                width: 100%;
                height: 40px;
            }

            a.btn.disabled {
                pointer-events: none;
                opacity: 0.6;
            }
        }
    }
}

.mystery-chests-widget {
    position: fixed;
    right: 0;
    z-index: 99;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    bottom: 100px;
    width: 70px;
    height: 70px;

    .mystery-chests-animated-box {
        @include mystery-chest-floating(rotate(0deg));
    }

    img {
        max-width: 100%;
        height: 64px;
    }
}

.mystery-chests-animated-box {
    position: relative;

    &:not(.mystery-chests-animated-box--disabled) {
        cursor: pointer;

        img {
            transition: filter 0.2s ease-in-out;
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $white;
            filter: blur(10px);
            border-radius: 50%;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
    }

    &.mystery-chests-animated-box--disabled {
        img {
            filter: grayscale(1);
        }
    }
}

@media (orientation: landscape) {
    .mystery-chests-modal {
        .modal-box {
            .background-image {
                height: 100%;
            }

            .modal-box--content {
                .modal-body {
                    .mystery-chests--animated {
                        margin: auto;
                    }
                }
            }
        }
    }
}
