.profile {
    &-data {
        margin: 24px 0 32px;
        padding: 0 10px;
        position: relative;
        z-index: 0;

        &--title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;
            font-weight: 800;
            margin-bottom: 16px;
            gap: 20px;
            flex-wrap: wrap;

            &-link {
                font-size: 14px;
                font-weight: 600;
                color: $violet-new;
                cursor: pointer;

                &.show-more {
                    display: flex;
                    justify-content: center;
                    margin-top: 16px;
                }
            }
        }

        &--details {
            margin-bottom: 24px;
            color: $step-content;
            text-align: justify;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }

        &--content,
        &--mfa-content {
            border-radius: 20px;
            background: $light-peach;
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            position: relative;

            &:after {
                @include box-shadow;
                width: calc(100% - 32px);
                top: 16px;
                left: 16px;
            }

            .btn {
                font-size: 12px !important;
                height: 30px;
            }

            &-list {
                padding: 0 16px;
                margin: 0;
                list-style-type: none;
                gap: 0;

                li {
                    padding: 16px 0;

                    &:not(:last-of-type) {
                        border-bottom: 1px solid rgba(194, 152, 117, 0.3);
                    }
                }
            }

            &-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                gap: 20px;

                div:first-of-type {
                    text-align: left;
                    text-transform: capitalize;
                }

                div:last-of-type {
                    word-break: break-all;
                    text-align: right;
                    font-weight: 600;
                }
            }

            .bank-card {
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &_wrapper {
                    display: flex;
                    align-items: center;

                    .payment-option {
                        width: 60px;
                        height: 24px;
                        margin-right: 8px;
                        justify-content: center;
                    }
                }
            }

            .document-upload-error {
                margin-top: 16px;
                color: $red-error;
            }

            .profile-card {
                > div:first-child {
                    display: flex;
                    flex-direction: column;
                }

                .status {
                    font-size: 11px;
                    font-weight: 400;
                    order: 1;
                    margin-bottom: 5px;

                    &:before {
                        content: '';
                        position: relative;
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        margin-right: 5px;
                        border-radius: 50%;
                        background: $violet-new;
                    }

                    &_to_upload,
                    &_mfa_inactive {
                        color: $violet-new;

                        &:before {
                            background: $violet-new;
                        }
                    }

                    &_to_validate {
                        color: $blue;

                        &:before {
                            background: $blue;
                        }
                    }

                    &_validated,
                    &_mfa_active {
                        color: $status_validated;

                        &:before {
                            background: $status_validated;
                        }
                    }

                    &_refused,
                    &_expired {
                        color: $menu-red;
                    }
                }

                .name {
                    font-weight: 700;
                    font-size: 16px;
                    order: 2;

                    &:first-letter {
                        text-transform: capitalize;
                    }
                }

                .infos {
                    font-size: 11px;
                    margin: 8px 0;
                    order: 3;
                }

                .status span,
                .name {
                    display: inline-block;

                    &:first-letter {
                        text-transform: capitalize;
                    }
                }

                .btn-submit {
                    width: 100%;
                    margin-top: 8px;
                }
            }

            .responsible-gaming-item {
                display: flex;
                flex-direction: column;

                gap: 20px;

                .status {
                    font-size: 11px;
                    font-weight: 700;
                    color: $violet-new;

                    &:before {
                        content: '';
                        position: relative;
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        margin-right: 4px;
                        border-radius: 50%;
                        top: 1px;
                        background: $violet-new;
                    }

                    &.active {
                        color: $menu-green;

                        &:before {
                            background: $menu-green;
                        }
                    }
                }

                .name {
                    margin: 8px 0;
                    font-weight: 700;
                    font-size: 21px;
                }

                .infos {
                    font-size: 11px;
                    font-weight: 400;

                    p {
                        margin: 0;
                    }
                }

                .status span,
                .name {
                    display: inline-block;

                    &:first-letter {
                        text-transform: capitalize;
                    }
                }
            }

            .communication-preferences-content {
                form {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    .checkbox-container {
                        font-size: 14px;
                        font-weight: 600;
                        line-height: 18px;

                        .checkmark {
                            width: 24px;
                            height: 24px;
                            min-width: 24px;
                            min-height: 24px;
                        }
                    }

                    .description {
                        font-size: 14px;
                        font-weight: 400;
                    }

                    .options-container {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 24px;
                    }

                    .btns-container {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        align-items: center;

                        button {
                            min-width: 140px;
                            width: 100%;
                            height: 30px;
                        }
                    }
                }
            }
        }

        &--vip-status {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 12px;

            h1 {
                font-size: 16px;
                font-weight: 700;
                margin: 0;
            }

            img {
                width: 60px;
                height: 60px;
            }

            &-data {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                gap: 12px;
            }
        }
    }

    .form-loader {
        width: 100%;

        .top {
            margin: 0;
            justify-content: center;
        }
    }

    .mfa-list {
        min-height: 300px;
        padding: 0;
        position: relative;

        .form-loader {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
            background: $light-peach;
            border-radius: 20px;
        }

        &.empty {
            display: flex;
            align-items: center;
            justify-content: center;

            .empty-list-message {
                text-align: center;
                font-weight: 600;
            }
        }
    }

    .mfa-list {
        min-height: 100%;
    }

    .responsible-gaming-list {
        padding: 0;
        position: relative;
    }

    &-change-password {
        .modal-box {
            &--content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .container-text {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    width: 100%;
                    height: 90%;

                    h4 {
                        width: 100%;
                    }
                }

                .container-btns {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    &--wrapper {
                        width: 100%;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .btn {
                            width: 100%;
                            height: 48px;
                        }
                    }
                }
            }
        }
    }
}

.self-exclusion-modal {
    &--body {
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;

        .header {
            margin: 0;
            color: $step-content;
            font-size: 14px;
            font-weight: 700;
        }

        .details {
            margin: 0;
            color: $step-content;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
        }

        .self-exclusion-selected-period-details {
            display: flex;
            flex-direction: column;
            gap: 4px;
            padding: 12px 0;
            border-top: 1px solid $card-border;
            border-bottom: 1px solid $card-border;

            .info {
                display: flex;
                align-items: center;
                gap: 8px;
                justify-content: space-between;

                &--one,
                &--two {
                    color: $step-content;
                    font-weight: 700;
                }

                &--one {
                    font-size: 14px;
                }

                &--two {
                    font-size: 11px;
                }
            }
        }

        .details-wrapper {
            height: 100%;
        }
    }

    &--periods {
        display: flex;
        flex-direction: column;
        gap: 16px;
        height: 100%;

        .periods-list {
            padding: 0;

            label {
                width: auto;

                .MuiFormControlLabel-root .MuiTypography-root {
                    color: $step-content;
                    text-align: justify;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 18px;
                }
            }
        }
    }

    &--confirm {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;

        .btn.btn-submit.btn-modern {
            width: 100%;
            height: 48px;
        }

        &.step-2 {
            .btn.btn-submit.btn-modern {
                background: $menu-red;
                color: $white;

                &:hover {
                    background: $yellow-alt;
                    color: $step-content;
                }
            }
        }
    }
}

.bank-card--actions {
    display: flex;
    align-items: center;
    gap: 12px;

    button {
        display: flex;
        border: none;
        background: 0;
        margin: 0;
        padding: 0;

        svg {
            height: 22px;
            width: auto;
        }
    }
}
