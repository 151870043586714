.push-notifications {
    position: relative;
    display: flex;
    align-items: center;

    > svg {
        cursor: pointer;

        circle,
        path {
            transition: all 0.15s ease-in-out;
        }

        circle {
            fill: $new-footer-violet;
        }
    }

    > svg:hover,
    &.active > svg {
        circle {
            fill: $new-purple;
        }

        path {
            fill-opacity: 1;
        }
    }

    &:not(.unread-messages) {
        svg:not(:hover) {
            path {
                fill-opacity: 0.5;
            }
        }
    }

    &-empty {
        text-align: center;
        font-size: 14px;
        font-weight: 700;
    }

    &--bubble {
        position: absolute;
        top: 0;
        right: 0;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        background-color: $coral;
    }

    &--lists {
        position: absolute;
        top: 60px;
        right: 0;
        width: 465px;
        max-height: 50vh;
        overflow-y: auto;
        scrollbar-width: thin;
        border-radius: 20px;
        color: $white;
        z-index: 1;

        > div {
            display: flex;
            flex-direction: column;
            gap: 16px;
            padding: 16px 24px;
            background: $new-footer-violet;

            .form-loader .top {
                margin: 0;
            }
        }

        .push-notifications--list-title {
            font-size: 12px;
            font-weight: 400;
            margin-bottom: 5px;
        }

        .push-notifications--list-unread {
            ul {
                padding: 16px 0;
                border-top: 1px solid $pre-footer-content-border;
                border-bottom: 1px solid $pre-footer-content-border;
            }
        }

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 24px;

            li {
                display: flex;
                align-items: flex-start;
                gap: 12px;

                .message {
                    &-icon {
                        position: relative;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-color: $notifications-violet;
                        flex-shrink: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 22px;
                        }
                    }

                    &-info {
                        width: 100%;

                        > div:first-child {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 12px;
                        }

                        .cta-link {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }

                    &--title {
                        font-size: 18px;
                        font-weight: 700;
                        margin: 0;
                    }

                    &--time {
                        flex-shrink: 0;
                        font-size: 12px;
                        font-weight: 400;
                        color: rgba($white, 0.5);
                    }
                }
            }
        }
    }

    .MuiBackdrop-root {
        cursor: initial;
    }
}
