.page-container {
    padding: 32px 75px;
    width: 100%;
    min-height: 100vh;

    .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;

        .name {
            font-size: 32px;
            font-weight: 800;
            margin: 0;

            + a {
                font-size: 14px;
                font-weight: 800;
                color: $violet-new;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    //Styles for marketing pages (vip, mystery-box)
    &-marketing {
        h1 {
            font-size: 32px;
            font-weight: 800;
            margin-bottom: 32px;
            line-height: normal;
        }

        h2 {
            font-size: 21px;
            font-weight: 800;
            color: $wheel-of-fortune-timer-text-color;
            margin: 0;
            line-height: normal;
        }

        &--header {
            margin-bottom: 32px;

            > h1 {
                margin-bottom: 16px;
            }
        }

        &--text-block {
            margin-bottom: 32px;
            font-size: 18px;

            > a {
                font-weight: 600;
                color: $violet-new;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &--row {
            display: flex;
            gap: 70px;
            margin-bottom: 96px;
        }

        &--column {
            flex-basis: 50%;
        }

        &-image {
            width: 100%;
            object-fit: cover;
            border-radius: 20px;
        }

        .btn.btn-submit.page-container-marketing-btn {
            height: 56px;
            min-width: 200px;
            border-radius: 100px;
            font-size: 18px;
        }

        &-list {
            display: flex;
            flex-direction: column;
            gap: 24px;
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                padding-left: 18px;

                &:before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    position: absolute;
                    left: 0;
                    top: 4px;
                    background-color: $yellow-alt;
                }
            }
        }
    }

    &--sidebar {
        padding: 0;
        display: grid;
        grid-template-columns: 250px 1fr;

        .game-sidebar {
            padding: 22px 12px;
            margin: 0;
            background: $light-peach;
            box-shadow: 5px 0 50px 0 rgba(228, 142, 69, 0.25);
            font-size: 14px;

            ul {
                &:before {
                    display: none;
                }

                &:not(.game-filters-list--subcategories) {
                    > li {
                        > a {
                            &.active:not(.static) {
                                animation: fadeActive 0.3s ease-in-out;
                                background-color: $violet-new;
                            }

                            &.static {
                                background-color: $violet-new;
                            }
                        }
                    }
                }
            }

            > div {
                margin-bottom: 32px;
            }

            h2 {
                font-size: 24px;
                font-weight: 700;
                padding: 0 12px;
                margin-bottom: 16px;
            }

            .game-filters-list--subcategories {
                position: relative;
                margin: 16px 0 16px 30px;
                border-left: 1px solid rgba(194, 152, 117, 0.3);
            }

            ul {
                list-style-type: none;
                padding: 0;
                position: relative;
                margin: 0;

                li {
                    a {
                        display: flex;
                        align-items: center;
                        padding: 8px 16px 8px 24px;
                        font-weight: 600;

                        &:hover:not(.active),
                        &.active {
                            color: $violet-new;
                        }
                    }
                }
            }

            > ul {
                margin-bottom: 32px;

                > li {
                    > a {
                        display: grid;
                        align-items: center;
                        grid-template-columns: 50px 1fr;
                        height: 40px;
                        width: 100%;
                        color: $step-content;
                        padding: 0 16px;
                        font-weight: 600;
                        position: relative;
                        border-radius: 5px;
                        transition: color 0.1s ease-in-out;

                        svg path {
                            fill: $step-content;
                            fill-opacity: 1;
                            transition: fill 0.1s ease-in-out;
                        }

                        &:hover:not(.active) {
                            color: $violet-new;

                            svg path {
                                fill: $violet-new;
                            }
                        }

                        &.active {
                            color: $white;
                            transition: color 0.5s ease-in-out;

                            svg path {
                                fill: $white;
                                transition: fill 0.5s ease-in-out;
                            }
                        }
                    }
                }
            }
        }

        .content-box {
            padding: 32px 75px;

            h1 {
                font-size: 32px;
                font-weight: 800;
            }
        }
    }
}

/* extra styles for small screens */
@media screen and (max-height: 800px) {
    .page-container {
        &--sidebar {
            .game-sidebar {
                ul {
                    margin-bottom: 24px;
                }

                .radio-group {
                    label {
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }
}
