.terms-and-conditions {
    ul {
        list-style-type: none;
        padding-left: 0;
        margin: 0;

        li {
            padding-left: 20px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                background-color: $yellow-alt;
                border-radius: 50%;
                margin-right: 8px;
            }

            > ul {
                margin-top: 20px;
            }
        }
    }
}
