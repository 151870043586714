.MuiSnackbar-root {
    font-family: 'Nunito', 'Roboto', sans-serif;
    bottom: 14px;
    width: 100%;
    left: 0;
    padding: 0 75px;
    z-index: 999999; // cover support bubble
}

.transaction-snackbar {
    .notification-content {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > div {
            display: flex;
            align-items: center;

            &:first-child {
                margin-right: 12px;
            }
        }

        svg {
            flex-shrink: 0;
            margin-right: 8px;
        }

        .btn-secondary {
            color: $step-content;
            font-size: 11px;
            font-weight: 800;
            border-radius: 15px;
            background: $white;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            height: 26px;
            padding: 0 10px;
            line-height: 12px;
        }

        .notification--close {
            border-radius: 50%;
            width: 24px;
            height: 24px;
            background: transparent;
            border: 1px solid $white;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 0 8px;

            svg {
                margin: 0;

                path {
                    fill: $white;
                }
            }
        }
    }
}

.toaster-notification {
    width: 100%;
    min-width: 720px;
    height: max-content;
    min-height: 46px;
    bottom: 0;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 14px 18px;
    color: $white;
    font-size: 14px;
    font-weight: 400;
    z-index: 1301;
    text-align: left;
    cursor: default;

    &--success {
        background: $toast-success;
    }

    &--error {
        background: $toast-error;
    }

    .notification-timer {
        font-size: 12px;
    }
}

.notification-fade {
    animation: notification-fade 0.2s ease-in;
}

@keyframes notification-fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
