.refer-a-friend {
    p {
        font-weight: 400;
        margin: 0;
    }

    .share-link {
        background: $white;
        border-radius: 20px;
        box-shadow: 0 40px 45px $box-shadow-peach;

        .copy-input {
            position: relative;

            input {
                background: $copy-input-bg;
                border-radius: 5px 20px 20px 5px;
                color: $copy-input-color;
                text-overflow: ellipsis;
            }

            .copy-btn {
                position: absolute;
                top: 0;
                right: 0;
            }
        }

        .share-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 140px;
            width: 100%;
            gap: 5px;
            background: $violet-new;
            color: $white;
        }

        .social-network-share {
            a {
                display: inline-block;
                width: 30px;
                height: 30px;
                margin-right: 12px;
            }
        }
    }

    .how-it-works {
        display: flex;
        flex-direction: column;
        align-items: center;

        &--content {
            width: 100%;
            background: $light-peach;
            border-radius: 20px;
            position: relative;
            box-shadow: 0 40px 45px $box-shadow-peach;

            img {
                position: absolute;
                bottom: 0;
            }
        }
    }
}
