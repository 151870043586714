.app-loader {
    &--logo {
        width: 188px;
        height: 144px;
    }

    .progress-bar {
        width: 188px;
        margin: 20px auto 0;
    }
}
