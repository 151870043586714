.casino-reel-agent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &--free {
        background:
            url('#{$asset-path}/casino-reel-agent/composition2.png') no-repeat center right,
            url('#{$asset-path}/casino-reel-agent/Free.jpg') no-repeat center right;
        background-size:
            contain,
            100% 100%;
        position: relative;
        padding-top: 10%;

        .logo {
            margin-left: -14px;
        }

        div {
            z-index: 999;
            position: relative;
        }

        div.flags {
            margin-left: -14px;
        }
    }

    &--not-free {
        background: url('#{$asset-path}/casino-reel-agent/not_free_bg.jpg') no-repeat center right;
        background-size: cover;
        padding: 4% 75px;

        h3 {
            margin-bottom: 15px;
        }

        .flags {
            img {
                width: 70px;
                margin-right: 30px;
            }
        }
    }

    h1 {
        color: $golden-yellow;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 80px;
        text-transform: uppercase;
        font-style: normal;
        font-weight: 800;
        line-height: 82px;
        margin: 35px 0 0 0;
    }

    h3 {
        color: $white;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        font-size: 48px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: uppercase;
    }

    .reel-agent-btn {
        border-radius: 58px;
        border: 1px solid $casino-reel-btn-border;
        background: rgba(31, 3, 43, 0.1);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(4px);
        color: $white;
        font-size: 32px;
        font-weight: 800;
        text-transform: uppercase;
        padding: 25px 34px;
    }

    .btn--not-free {
        color: $casino-reel-not-free-btn;
        background: $casino-reel-btn-gradient;
    }

    .rights {
        color: $casino-reel-rights;
        font-size: 14px;
        font-weight: 500;
    }

    .flags {
        margin: 40px 0 15px;
    }
}
