.checkbox-group {
    padding: 0 12px !important;

    li {
        margin-bottom: 24px;
    }
}

.checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: fit-content;
    font-weight: 600;

    &:hover {
        color: $violet-new;
    }

    input[type='checkbox'] {
        display: none;

        &:checked ~ .checkmark {
            border-color: $violet-new;

            svg {
                opacity: 1;
            }
        }
    }

    .checkmark {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border: 1px solid $violet-new;
        border-radius: 5px;
        margin-right: 10px;

        svg {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
    }
}
