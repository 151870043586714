.terms-modal {
    &-content {
        height: inherit;
        position: relative;

        .intro {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
        }

        .new-terms {
            overflow-y: auto;
            height: calc(100% - 120px);
        }

        .container-btns {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: -35px;

            button {
                position: relative;
            }

            .loading-text {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}
