.reality-check-modal {
    @include font-family-nunito;
    display: flex;
    justify-content: center;
    color: $white;
    font-size: 16px;
    line-height: 18px;

    &_body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 6px;
        width: 100%;
        height: 100%;
        background-color: $white;
        color: $step-content;
        padding: 20px;
        gap: 30px;

        .reality-check_body-content {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .reality-check-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;

                .reality-check-row {
                    display: flex;
                    justify-content: space-between;
                    padding: 30px 0;
                    border-bottom: 1px solid rgba(194, 152, 117, 0.3);
                    font-size: 14px;
                    font-weight: 400;

                    &:last-child {
                        border-bottom: none;
                    }

                    &--title,
                    &--value {
                        width: 50%;
                    }

                    &--value {
                        font-weight: 700;
                        text-align: right;
                    }
                }
            }
        }

        &_footer {
            .container-btns {
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;
                align-items: center;
                gap: 20px;

                .btn {
                    margin: 0 8px;
                    width: calc(100% - 16px);
                    height: 42px;

                    &--100 {
                        width: calc(100% - 16px);
                    }
                }
            }
        }
    }
}
