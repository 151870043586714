.mystery-chests {
    &--open-chest {
        display: flex;
        align-items: center;
        gap: 30px;
        line-height: 28px;
        font-size: 18px;
    }

    &--treasures-list {
        display: flex;
        gap: 10px;
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            width: 100%;
            background-color: $light-peach;
            border-radius: 10px;
            padding: 24px 16px;

            img {
                height: 70px;
                max-width: 100%;
                margin-bottom: 24px;
            }

            .mystery-chests--treasure-title {
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 12px;
            }
        }
    }

    &--welcome {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 96px;
        gap: 32px;

        &-icons {
            display: flex;
            align-items: center;
            gap: 20px;
            flex-shrink: 0;

            &:nth-of-type(3) {
                flex-direction: row-reverse;
            }

            img {
                flex-shrink: 0;
                max-width: 100%;

                &:nth-of-type(1),
                &:nth-of-type(3) {
                    transform: rotate(-15deg);
                }

                &:nth-of-type(1) {
                    height: 60px;
                }

                &:nth-of-type(2) {
                    height: 90px;
                    transform: rotate3d(-0.1, 1, 0, 180deg);
                }

                &:nth-of-type(3) {
                    height: 120px;
                }
            }
        }

        &-content {
            text-align: center;
        }
    }

    &--terms {
        ul {
            display: block;
            column-count: 2;
            column-gap: 70px;

            li:not(:last-of-type) {
                margin-bottom: 24px;
            }
        }
    }
}

.mystery-chests-modal {
    .modal-box {
        padding: 0;
        border-radius: 20px;
        width: 600px;
        height: 680px;
        background: $mystery-chests-modal-bg;

        .modal-box--title {
            margin: 0;
            z-index: 3;
            position: relative;

            .modal-box--close {
                position: absolute;
                top: 24px;
                right: 24px;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                background: none;
                border: 1px solid $white;
                opacity: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;

                svg {
                    path {
                        fill: $white;
                    }
                }

                &:hover {
                    background: rgba($white, 0.3);
                }

                &:before {
                    display: none;
                }
            }
        }

        .modal-box--content {
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: inherit;
            background: inherit;
            overflow-y: hidden;

            .modal-body {
                position: relative;
                z-index: 2;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                padding: 30px 0;

                .mystery-chests-info {
                    min-width: 384px;
                    min-height: 160px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                }

                .gradient-border {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    border-radius: 20px;
                    position: relative;
                    margin-bottom: 16px;

                    &::before {
                        content: '';
                        position: absolute;
                        inset: 0;
                        padding: 4px;
                        border-radius: 20px;
                        background: $wheel-of-fortune-table-gradient, linear-gradient(0deg, $white, $white);
                        mask:
                            linear-gradient($white 0 0) content-box,
                            linear-gradient($white 0 0);
                        -webkit-mask:
                            -webkit-linear-gradient($white 0 0) content-box,
                            -webkit-linear-gradient($white 0 0);
                        -webkit-mask-composite: xor;
                        mask-composite: exclude;
                    }

                    &--text {
                        width: inherit;
                        background: $android-app-lp-comment_text;
                        backdrop-filter: blur(5px);
                        color: $white;
                        padding: 24px;
                        border-radius: 20px;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        margin: 4px;
                        gap: 8px;

                        > h4 {
                            font-size: 16px;
                            font-weight: 700;
                            margin: 0;
                        }

                        > div {
                            font-style: italic;
                        }
                    }
                }

                .mystery-chests--animated {
                    position: relative;
                    margin-bottom: 24px;

                    &-loader,
                    &-boxes {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &-loader {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                    }

                    &-boxes {
                        gap: 20px;

                        .mystery-chests-animated-box {
                            &.mystery-chests-animated-box--disabled {
                                pointer-events: none;
                            }

                            &:nth-of-type(1),
                            &:nth-of-type(3) {
                                img {
                                    height: 145px;
                                }
                            }

                            &:nth-of-type(1) {
                                @include mystery-chest-floating(rotate(-7deg), -5px, 5px);
                            }

                            &:nth-of-type(2) {
                                @include mystery-chest-floating(rotate(0deg));

                                img {
                                    height: 210px;
                                }
                            }

                            &:nth-of-type(3) {
                                @include mystery-chest-floating(rotate3d(-0.1, 1, 0, 180deg), -5px, 5px);
                            }
                        }
                    }
                }
            }
        }

        .background-image {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 100%;

            &--fallback {
                z-index: 0;
            }

            &--animated {
                opacity: 0;
                transition: opacity 0.1s ease-in-out;

                &.background-image--loaded {
                    opacity: 1;
                }
            }

            img {
                object-fit: cover;
                border-radius: 20px;
                width: 100%;
                height: 100%;
            }
        }

        .container-btns {
            .btn {
                width: 100%;
            }

            a.btn.disabled {
                pointer-events: none;
                opacity: 0.6;
            }
        }
    }
}

.mystery-chests-widget {
    position: fixed;
    right: 0;
    z-index: 99;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
    bottom: 100px;
    width: 110px;
    height: 110px;

    .mystery-chests-animated-box {
        @include mystery-chest-floating(rotate(0deg));
    }

    img {
        max-width: 100%;
        height: 100px;
    }
}

.mystery-chests-animated-box {
    position: relative;
    cursor: pointer;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $mystery-chests-yellow;
        filter: blur(10px);
        border-radius: 50%;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    &:not(:hover) {
        animation-play-state: running;
    }

    &:not(.mystery-chests-animated-box--disabled) {
        img {
            transition: filter 0.2s ease-in-out;
        }

        &:hover {
            img {
                filter: brightness(1.5);
            }

            &:before {
                opacity: 1;
            }
        }
    }

    &.mystery-chests-animated-box--disabled {
        img {
            filter: grayscale(1);
        }

        &:hover {
            &:before {
                opacity: 1;
            }
        }
    }
}
