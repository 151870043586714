.profile-kyc {
    > .profile-data {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .profile-data--details {
            margin-bottom: 0;
        }

        section {
            font-size: 14px;
            line-height: 20px;

            > h4 {
                font-size: 12px;
                font-weight: 700;
            }
        }
    }

    ul {
        list-style-type: none;
    }

    &--list {
        display: flex;
        gap: 14px;
        flex-wrap: wrap;
    }

    &--document-categories-list {
        @include scroll-container;
        padding: 64px 10px;
        margin: -64px -10px;
        width: calc(100% + 20px);

        .profile-kyc--document-category {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-width: 300px;
            font-size: 12px;

            &-title {
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 10px;
            }
        }
    }

    &--uploaded-documents {
        flex-wrap: nowrap;
        flex-direction: column;
        gap: 24px;

        section {
            ul {
                padding: 0 24px;

                li {
                    font-weight: 700;

                    &:first-letter {
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    &--how-it-works-list {
        @include scroll-container;
        display: flex;
        gap: 14px;
        padding: 64px 10px;
        margin: -64px -10px;
        width: calc(100% + 20px);

        .profile-kyc--how-it-works-step {
            flex-basis: calc(100% / 3);
            min-width: 150px;
            position: relative;
            gap: 16px;

            &-description {
                font-weight: 700;
            }

            &:nth-of-type(1):before,
            &:nth-of-type(2):before {
                content: '';
                background: url('#{$asset-path}/kyc/arrow_step.svg') no-repeat;
                position: absolute;
                width: 50px;
                height: 16px;
                z-index: 1;
                right: 0;
            }

            &:nth-of-type(1):before {
                top: 25%;
                transform: translateX(60%) scale(0.8);
            }

            &:nth-of-type(2):before {
                bottom: 25%;
                transform: translateX(60%) rotate3d(-0.1, 2, 0, 180deg) scale(-0.8, -0.8);
            }
        }
    }
}

.kyc-modal {
    font-size: 14px;

    &.modal--modern {
        .modal-box {
            &--title {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding-right: 48px;

                .modal-box--header-back {
                    @include header-back-btn;
                    transform: none;
                    margin-right: 16px;
                }
            }

            &--content {
                h4 {
                    font-size: 16px;
                    margin-bottom: 24px;
                    font-weight: 700;
                }

                &-step,
                &-view {
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    font-weight: 600;
                }

                &-step {
                    padding-bottom: 32px;
                }

                > [id*='form'] {
                    .container-btns {
                        .btn {
                            flex: 1 1 50%;
                            width: 100%;
                            min-height: 40px;
                        }
                    }
                }
            }
        }
    }

    &.preview-document-modal {
        .modal-box {
            background: $black;
            color: $white;
            display: flex;
            flex-direction: column;
            flex: 1 1 100%;

            &--content {
                background: none;
                color: inherit;
                display: flex;
                flex-direction: column;
                gap: 24px;

                &-view {
                    display: flex;
                    flex-direction: column;
                    flex: 1 1 100%;
                    gap: 24px;
                }
            }

            .modal-box--header-back {
                svg {
                    path {
                        fill: $white;
                    }
                }
            }
        }

        .document-preview {
            &--info {
                &-title {
                    font-size: 16px;
                    font-weight: 800;
                    margin-bottom: 8px;
                }

                &-name {
                    font-size: 11px;
                    font-weight: 400;
                }
            }

            &--file {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: flex-start;
                align-self: center;
                height: 100%;
                width: 100%;

                img {
                    object-fit: contain;
                    object-position: top;
                }

                img,
                embed {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    height: 100%;
                    width: auto;
                    max-width: 100%;
                    transform: translate(-50%, 0);
                }
            }
        }
    }

    &--requirements-list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            position: relative;
            padding-left: 24px;

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            &:before {
                position: absolute;
                left: 0;
                content: '';
                background: url('#{$asset-path}/kyc/kyc_success.svg') no-repeat;
                background-size: contain;
                margin-right: 12px;
                vertical-align: middle;
                width: 16px;
                height: 16px;
            }
        }
    }

    &--uploading-slots-list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 12px;

        .kyc-modal--uploading-slot {
            padding: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            border-radius: 10px;
            width: 100%;
            border: 1px dashed $violet-new;

            &.success {
                .kyc-modal--uploading-slot-drag-n-drop {
                    margin-bottom: 8px;
                    color: $toast-success;
                }
            }

            &.error {
                border-color: $toast-error;

                .kyc-modal--uploading-slot-drag-n-drop {
                    margin-bottom: 8px;
                    color: $toast-error;
                }
            }

            &-loader {
                display: table;
                font-weight: 700;

                .form-loader {
                    .top {
                        margin-bottom: 24px;
                    }
                }
            }

            &-title {
                font-size: 16px;
                font-weight: 800;
            }

            &-drag-n-drop {
                display: flex;
                flex-direction: column;
                align-items: center;
                color: $violet-new;
                text-align: center;
                gap: 6px;

                img {
                    width: 32px;
                    height: 32px;

                    &[alt='drag_n_drop'] {
                        width: 44px;
                        height: 44px;
                    }
                }

                &-content {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                }

                &-title {
                    font-weight: 800;
                }

                &-text {
                    font-size: 11px;
                }
            }

            &-btns {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                gap: 8px;

                button {
                    flex: 1 1 calc(50% - 8px);
                    min-width: auto;
                    height: 30px;
                }
            }
        }
    }

    &--quality-guide {
        &-requirements-list {
            margin-bottom: 28px;

            li:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        &-examples-list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 16px;
        }

        &-example {
            &-file {
                margin-bottom: 5px;
                width: 100%;
            }

            &-description {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 6px;
                font-weight: 800;

                img {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    &--main-loader {
        display: table;
        text-align: center;
        font-weight: 700;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .form-loader {
            .top {
                margin-bottom: 14px;
            }
        }
    }
}
