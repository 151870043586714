.casino-games-page {
    .games-list-loader {
        height: auto;
    }

    .game-list {
        margin-bottom: 64px;
        grid-template-columns: repeat(5, 1fr);

        @media screen and (max-width: 1599px) {
            margin-bottom: 34px;
            grid-template-columns: repeat(4, 1fr);
        }
    }

    .games-lobby {
        &--featured {
            margin-bottom: 64px;

            .game-list {
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(1, 1fr);
                grid-auto-rows: 0; /* set height to 0 for autogenerated grid rows */

                &-item {
                    height: auto !important;

                    &--background {
                        aspect-ratio: 1.33;
                    }
                }
            }
        }

        &--trending,
        &--favorites,
        &--subcategories {
            .title {
                &.see-all-hidden,
                &.nog-equal {
                    .name + a {
                        display: none;
                    }
                }

                &.nog-equal {
                    @media screen and (max-width: 1599px) {
                        .name + a {
                            display: block;
                        }
                    }
                }
            }

            .game-list {
                grid-auto-rows: 0; /* set height to 0 for autogenerated grid rows */

                &-item {
                    box-shadow: 0 5px 20px 0 $card-box-shadow;
                }
            }
        }

        &--trending {
            margin-bottom: 32px;

            .game-list {
                grid-template-rows: repeat(3, 1fr);
            }
        }

        &--favorites,
        &--subcategories {
            margin-bottom: 62px;

            .game-list {
                row-gap: 0;
                grid-template-rows: repeat(1, 1fr);
            }
        }
    }

    .title--game-filters {
        display: flex;
        align-items: flex-start;
        margin-bottom: 24px;
        gap: 24px;

        .name {
            flex-shrink: 0;
            font-weight: 800;
            margin: 0;
        }
    }

    .games-active-filters {
        display: flex;
        gap: 24px;

        label {
            font-weight: 700;
            line-height: 40px;
            height: fit-content;
            padding-left: 24px;
            border-left: 1px solid $active-filters-border;
        }

        ul {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 12px;
            list-style-type: none;
            margin: 0;
            padding: 0;

            .filter {
                .remove-filter {
                    display: flex;
                    align-items: center;
                    height: 40px;
                    border-radius: 5px;
                    background: $violet-new;
                    color: $white;
                    font-weight: 600;
                    padding: 0 18px;

                    &:hover {
                        background: $new-footer-violet;
                    }

                    svg {
                        margin-right: 10px;

                        path {
                            fill: $white;
                        }
                    }
                }
            }

            .clear-all {
                color: $violet-new;
                font-weight: 600;
                text-transform: capitalize;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

//Common for lobbies
.games-lobby--featured {
    .game-list-item {
        width: auto !important;
        aspect-ratio: auto !important;
        border-radius: 20px !important;
        display: flex;
        flex-direction: column;

        &--background {
            position: relative;
            width: 100%;
        }

        &--detail {
            display: flex;
            justify-content: space-between;
            flex: 1 1 auto;
            align-items: center;
            padding: 17px 24px;
            color: $white;
            gap: 8px;

            .game-info {
                min-width: 0;
            }

            .game-name,
            .provider-name {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .game-name {
                font-size: 24px;
                font-weight: 700;
            }

            .provider-name {
                opacity: 0.6;
            }

            .btn {
                flex-shrink: 0;
                min-width: 80px;
                width: 30%;
                max-width: 140px;
            }
        }
    }
}

.games-lobby--providers {
    position: relative;
    width: 100%;
    max-width: calc(100vw - 415px);
    min-width: calc(1285px - 415px);
    height: 78px;
    margin: 64px 0;

    .keen-slider {
        position: relative;
        left: 0;
        top: 0;
        width: inherit;
        height: inherit;
        overflow: hidden;

        &__slide {
            &.provider-link {
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: unset;

                .img-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 143px;
                    height: 65px;
                }

                img {
                    transition: transform 0.3s ease-in-out;
                    max-width: 143px;
                    max-height: 65px;
                    padding: 0 10px;

                    &:hover {
                        transform: scale(1.2);
                    }
                }

                &.pragmatic-play {
                    img {
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
}

.no-games-page {
    display: flex;
    flex-direction: column;
    align-items: center;

    .no-results-message {
        color: $message-description;
    }
}

.game-list {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, $game-thumb-width-default);
    gap: 30px;

    // COMMON STYLES OF GAME THUMBNAIL
    &-item {
        aspect-ratio: 1.33;
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 10px;
        position: relative;
        overflow: hidden;

        &[data-id] {
            box-shadow: 0 5px 20px 0 $card-box-shadow;
        }

        &--jackpot-logo {
            position: relative;
            z-index: 1;
            padding-top: 12px;
            padding-left: 12px;
            aspect-ratio: 1 / 1;

            svg {
                max-width: 22%;
                max-height: 22%;
            }
        }

        &--background {
            position: absolute;
            border-radius: 0;
            bottom: 0;
            top: 0;
            right: 0;
            left: 0;
            background-color: $game-item-bg;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50%;

            img {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        &--link,
        &-favorite--link {
            display: flex;
            position: absolute;
            left: 0;
            right: 0;
            top: 30%;
            overflow: auto;
            z-index: 3;
            align-items: center;
            justify-content: center;
            height: 60px;
            transform: scale(0);
            transition: transform 0.3s ease-in-out;

            &-play {
                width: 48px;
                height: 48px;
                background: $white;
                border-radius: 48px;
                position: relative;
                margin: 30px auto;
                display: block;
                transform: scale(0.9);
                opacity: 0;
                transition: ease 150ms;
            }

            svg {
                width: 30%;
                height: 85%;
                flex-shrink: 0;
            }
        }

        &-favorite--link {
            position: relative;
            height: 100%;
        }

        &--text,
        &--text--live {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            border-radius: 10px;
            background: $white;
            height: 100%;
            background: $game-thumb-text-bg;
            backdrop-filter: blur(5px);
            transition: all 0.3s ease-in-out;
            opacity: 0;
            z-index: 2;

            &-title {
                line-height: 38px;
                overflow: hidden;
                word-wrap: break-word;
                top: 0;
                transform: translate(0, -38px);
                font-size: 14px;
                font-weight: 600;
                height: 38px;
            }

            &-rtp {
                align-items: center;
                bottom: 35%;
                transform: translate(-50px, 0);
                font-size: 12px;
                font-weight: 700;
                height: 50px;
            }

            &-info {
                align-items: center;
                bottom: 0;
                transform: translate(0, 50px);
                font-size: 12px;
                font-weight: 700;
                height: 50px;

                @media screen and (max-width: 1350px) {
                    font-size: 10px;
                }

                .game-volatility {
                    .value {
                        color: $blue;
                    }
                }

                .game-vlt {
                    > div {
                        width: 60px;
                    }

                    &.default {
                        margin-bottom: 10px;
                    }

                    .vlt {
                        font-size: 12px;
                    }
                }

                .game-rtp {
                    .value {
                        color: $coral;
                    }
                }
            }

            &-title,
            &-rtp,
            &-info {
                display: flex;
                width: 100%;
                justify-content: space-between;
                column-gap: 8px;
                padding: 0 12px;
                opacity: 0;
                position: absolute;
                transition:
                    transform 0.3s ease-in-out,
                    opacity 0.5s ease-in-out;
                text-align: left;
                color: $white;
                text-transform: capitalize;

                .value {
                    color: $new-light-violet;
                    font-size: 14px;
                    font-weight: 700;

                    &.promo-game {
                        font-size: 12px;
                    }
                }
            }
        }

        &:hover {
            .game-list-item--link {
                transform: scale(1);

                &-play {
                    transform: scale(1);
                    opacity: 1;
                }
            }

            .game-list-item--text,
            .game-list-item--text--live {
                opacity: 1;

                &-details {
                    opacity: 1;
                    margin-top: -6px;
                }
            }

            .game-list-item--text--live {
                height: 100% !important;
            }

            .game-list-item--text-title,
            .game-list-item--text-rtp,
            .game-list-item--text-info {
                opacity: 1;
                transform: translate(0, 0);
                z-index: 2;
            }
        }

        // STYLES OF LIVE GAME THUMBNAIL
        &.game-list-item-live {
            .game-list-item {
                &--table-closed {
                    position: absolute;
                    z-index: 10;
                    width: 100%;
                    height: 100%;
                    background: rgba(255, 255, 255, 0.6);
                    display: flex;
                    align-items: center;
                    text-align: center;

                    p {
                        text-align: center;
                        width: 100%;
                        margin-bottom: 38px;
                        color: $live-table-closed;
                        font-size: 21px;
                        font-weight: 500;
                        opacity: 0;
                        margin-top: 0;
                        text-transform: capitalize;
                    }

                    &:hover {
                        p {
                            opacity: 1;
                        }
                    }
                }

                &--background {
                    height: calc(100% - 38px);
                    width: 100%;
                }

                &--text,
                &--text--live {
                    height: 38px;

                    &-title {
                        top: initial;
                        padding: 0 16px;
                        opacity: 1;
                        height: 38px;
                        transform: translate(0, -38px);
                        transition: all 0.3s ease-in-out;
                        bottom: -38px;
                        background-color: $game-thumb-text-bg;
                        border-bottom-left-radius: 10px;
                        border-bottom-right-radius: 10px;

                        .game-name {
                            overflow: hidden;
                        }
                    }

                    &--text-title,
                    &--text-details-limits {
                        font-size: 14px;
                        color: $white;
                        font-weight: 700;
                        text-transform: capitalize;
                    }
                }

                &--limits-modern {
                    display: flex;
                    justify-content: space-between;
                    gap: 6px;
                    position: absolute;
                    width: 100%;
                    top: 0;
                    padding: 0 16px;
                    transform: translate(0, 0);
                    opacity: 0;
                    transition: all 0.5s ease-in-out;

                    .game-list-item--text-details {
                        &-minLimit,
                        &-maxLimit {
                            .label,
                            .amount {
                                font-weight: 700;
                                font-size: 12px;

                                @media screen and (min-width: 1800px) {
                                    font-size: 14px;
                                }
                            }

                            .label {
                                display: flex;
                                align-items: center;
                                color: $white;
                                margin-bottom: 6px;

                                svg {
                                    margin-right: 6px;
                                }
                            }
                        }

                        &-minLimit {
                            .amount {
                                color: $blue;
                            }
                        }

                        &-maxLimit {
                            .amount {
                                color: $coral;
                            }
                        }
                    }
                }
            }

            &:hover {
                .game-list-item--limits-modern {
                    transform: translate(0, 16px);
                    opacity: 1;
                    z-index: 2;
                }
            }
        }
    }
}

.id-generated-games {
    display: flex;
    flex-wrap: wrap;

    .game {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin: 20px 0;
        text-align: left;

        &:hover {
            color: $font-play-tab;

            .game-play {
                transform: scale(1);
                opacity: 1;
            }
        }

        &-visual {
            width: 168px;
            height: 127px;
            margin-right: 8px;
            border-radius: 4px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        &-name {
            color: $black;
            margin-top: 5px;
        }

        &-play {
            width: 48px;
            height: 48px;
            background: $white;
            border-radius: 48px;
            position: absolute;
            margin: 30px auto;
            display: block;
            transform: scale(0.9);
            opacity: 0;
            transition: all ease 150ms;

            &:after {
                content: '';
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-left: 13px solid $black;
                position: absolute;
                top: 14px;
                left: 20px;
            }
        }
    }
}

@keyframes fadeActive {
    0% {
        background-color: transparent;
    }
    100% {
        background-color: $violet-new;
    }
}
