select {
    @include form-control;
}

.select {
    width: 100%;

    &:before,
    &:after,
    img {
        display: none;
    }

    input {
        height: 44px;
    }

    .MuiSelect-select {
        background: rgba(255, 255, 255, 1) url('#{$asset-path}/arrow.svg') no-repeat calc(100% - 12px) center;
        height: 44px;
        padding-left: 15px;
        display: flex;
        align-items: center;
        font-size: 16px;

        &:focus {
            border: 1px solid #4a2c10;
            outline: none;
            border-radius: 4px;
            transition:
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
            background: rgba(255, 255, 255, 1) url('#{$asset-path}/arrow.svg') no-repeat calc(100% - 12px) center;
        }
    }

    &.Mui-disabled {
        .MuiSelect-select {
            background-color: #e9ecef;
            opacity: 1;
            cursor: not-allowed;
        }
    }

    .MuiSvgIcon-root {
        fill: #313131;
        width: 1em;
        height: 1em;
        display: inline-block;
        font-size: 1.5rem;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        flex-shrink: 0;
        user-select: none;
        margin-right: 8px;
    }
}

.select-wrapper {
    position: relative;
    background: $white;
    border-radius: $border-radius;

    .MuiInput-root {
        width: 100%;
    }

    &:before {
        content: '';
        position: absolute;
        right: 12px;
        width: 0;
        height: 0;
        display: block;
        border: 4px solid transparent;
        border-top: 8px solid $dark;
        top: 18px;
        z-index: 2;
    }

    select {
        background: transparent;
        margin: 0;
    }
}
