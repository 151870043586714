//COLORS
$dark: #4a2c10;
$purple: #4f0053;
$dark-purple: #3c003f;
$main-purple: #90008b;
$new-light-violet: #c398ff;
$light: #f6eddd;
$extra-light: #faf6ed;
$gray: #e2ded7;
$dark-gray-1: #888;
$dark-gray-2: #a4a4a4;
$overlay_bg: rgba(28, 28, 28, 0.55);
$light-red: #d45858;
$red: #ff0000;
$yellow: #ffc002;
$yellow-alt: #ffbe0b;
$dark-gold: #806103;
$scroll-nav-item: rgba(48, 22, 0, 0.5);
$yellow-alt: #ffc000;
$violet-submit: #5e0060;
$transparent-white: rgba(255, 255, 255, 0.3);
$border-color: rgba(194, 152, 117, 0.3);
$scroll-nav-bg: #fff5f1;
$status_validated: #29cd39;
$extra-light-bronze: #c29875;
$live-table-closed-bg: rgba(255, 255, 255, 0.6);
$casino-reel-rights-not-free: #d17bf8;
$wheel-of-fortune-table-info-item: #521993;
$wheel-of-fortune-table-title: #8338ec;
$wheel-of-fortune-table-row: #6022ac;
$android-app-lp-banner-gradient: linear-gradient(315deg, rgba(45, 8, 82, 0) 0%, #2d0852 77.07%);
$vip-widget-box-shadow: rgba(228, 142, 69, 0.3);

//SIZES
$border-radius: 6px;
$mobile-padding: 12px;
$banner-padding-bottom: 24px;
