.terms-and-conditions {
    li {
        margin-bottom: 12px;
        font-size: 14px;

        &::before {
            top: 3px;
            width: 8px;
            height: 8px;
        }
    }
}
