.terms-and-conditions {
    margin-top: 60px;

    li {
        margin-bottom: 20px;

        &::before {
            top: 4px;
            width: 12px;
            height: 12px;
        }
    }
}
