.btn-send-code {
    position: relative;
    overflow: hidden;

    .text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        transition: transform 0.5s ease;
    }

    .text.move-down {
        animation: moveTextDown 0.5s ease forwards;
    }

    .text.move-up {
        animation: moveTextUp 0.5s ease forwards;
    }

    .text.hide {
        opacity: 0;
    }

    .spinner-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    .spinner-container.show {
        animation: showSpinner 0.5s ease forwards;
        opacity: 1;
    }

    .done-text {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: opacity 0.5s ease;
    }

    .done-text.move-up {
        animation: moveTextUp 0.5s ease forwards;
    }

    .done-text.show {
        animation: showDoneText 0.5s ease forwards;
        opacity: 1;
    }
}

/* Animation for moving "Send Code" text downward */
@keyframes moveTextDown {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}

@keyframes moveTextUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0%);
    }
}

/* Animation for showing the spinner */
@keyframes showSpinner {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Animation for showing "Done" text */
@keyframes showDoneText {
    from {
        transform: translateY(100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
