.form {
    &-info {
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 12px;
    }

    &-group {
        position: relative;
        margin-bottom: 10px;

        label {
            display: block;
        }

        &--row,
        &--column {
            display: flex;
            margin: 0 -5px;
        }

        &--row {
            flex-direction: row;
        }

        &--column {
            flex-direction: column;
        }

        &--cell {
            width: 100%;
            margin: 0 5px;

            &-large {
                width: 70%;
            }

            &-small {
                width: 30%;
            }
        }

        &--loading {
            .loader {
                position: absolute;
                top: 35px;
                right: 15px;
                z-index: 1;
            }
        }

        &--error-marked,
        &--cell-error-marked {
            position: relative;

            &:after {
                content: '';
                position: absolute;
                right: 13px;
                width: 20px;
                height: 20px;
                background-size: 20px 20px;
                background-repeat: no-repeat;
                background-image: url('#{$asset-path}/cross-error.png');
                animation: 0.2s fadeIn ease-in-out;
            }
        }

        &--error-marked:after {
            top: 33px;
        }

        &--cell-error-marked:after {
            top: 13px;
        }

        &:last-of-type {
            margin-bottom: 16px;
        }
    }

    &-control {
        margin-bottom: 0;
    }

    &-message {
        display: block;
        margin-top: 8px;
        padding-left: 10px;
        padding-right: 0;
        line-height: 18px;
        font-size: 16px;
        clear: both;

        &--error {
            color: $light-red;
        }
    }

    &-loader {
        .top {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 16px;

            .animation {
                width: 100px;
                height: 100px;
                background: url('#{$asset-path}/loader_wild_sultan.png') center no-repeat;
                background-size: 100px 100px;
            }

            .message {
                margin-left: 16px;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                width: calc(100% - 116px);
            }
        }

        .bottom {
            border-top: solid 1px $gray;
            padding-top: 16px;

            .title {
                font-weight: 800;
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 16px;
            }

            .news {
                position: relative;
                height: 92px;

                > div {
                    animation: topToBottom 12.5s linear infinite 0s;
                    -ms-animation: topToBottom 12.5s linear infinite 0s;
                    -webkit-animation: topToBottom 12.5s linear infinite 0s;
                    opacity: 0;
                    overflow: hidden;
                    position: absolute;

                    &:nth-child(2) {
                        animation-delay: 2.5s;
                        -ms-animation-delay: 2.5s;
                        -webkit-animation-delay: 2.5s;
                    }
                    &:nth-child(3) {
                        animation-delay: 5s;
                        -ms-animation-delay: 5s;
                        -webkit-animation-delay: 5s;
                    }
                    &:nth-child(4) {
                        animation-delay: 7.5s;
                        -ms-animation-delay: 7.5s;
                        -webkit-animation-delay: 7.5s;
                    }
                    &:nth-child(5) {
                        animation-delay: 10s;
                        -ms-animation-delay: 10s;
                        -webkit-animation-delay: 10s;
                    }

                    span {
                        display: block;
                        font-size: 12px;

                        &.game-name {
                            font-size: 16px;
                            font-weight: 800;
                            line-height: 19px;
                            margin-top: 8px;
                        }
                    }
                }
            }
        }
    }
}
