.pagination {
    margin: 36px 0 12px;
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px;

    &-list {
        display: flex;
    }

    &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 0 3px;
        cursor: pointer;

        &.bordered {
            color: red;
            border: 1px solid $light-purple;
        }

        &.arrow {
            background: none;
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
            cursor: default;
        }

        &.active {
            background: $violet-new;
            color: $white;
        }
    }

    &-control {
        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        &.prev {
            float: left;
        }

        &.next {
            float: right;
        }

        &.prev:before {
            content: '‹ ';
        }

        &.next:after {
            content: ' ›';
        }
    }
}
