.overlay {
    transition: all 0.5s ease-in-out;
    position: absolute;
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    bottom: 0;
    right: 0;
    z-index: 1;

    &.overlay-minimized {
        position: fixed;
        min-width: 336px;
        right: 20px;
        bottom: 70px;
        border-radius: 10px;
        z-index: 1000;
    }

    .overlay-component {
        width: 100%;
        height: 100%;

        .error-page-play {
            min-height: 100%;
            padding: 32px 75px 0 75px;
        }
    }
}

.minimized-game {
    &--component {
        display: flex;
        width: 100%;
    }

    &--actions {
        &-wrapper {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            background-color: $new-footer-violet;
            padding: 12px 24px;
            border-radius: 20px 20px 0 0;
            color: $white;
        }

        &-component {
            display: flex;
            gap: 10px;

            svg {
                width: 32px;
                height: 32px;

                &:hover {
                    fill: $pre-footer-table-border;
                }
            }

            > div {
                cursor: pointer;
            }
        }

        &-title {
            font-size: 24px;
            font-weight: 800;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
