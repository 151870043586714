.communication-preferences-modal {
    &.modal--modern {
        .modal-box {
            &--title {
                padding: 0 35px;
            }

            &--content {
                position: relative;
            }
        }

        .subtitle {
            font-size: 16px;
            line-height: 22px;
            font-weight: 800;
        }

        .profile-data {
            padding: 0;
            position: static;

            &--content {
                background: transparent;
                padding: 0;
                position: static;

                &:after {
                    display: none;
                }

                .description {
                    font-size: 14px;
                    line-height: 21px;
                }

                .options-container {
                    justify-content: space-between;

                    div {
                        width: calc(50% - 20px);
                    }
                }

                .btns-container {
                    position: absolute;
                    bottom: 0;
                    width: 100%;

                    .btn {
                        width: 288px;
                        height: 48px;
                    }
                }
            }
        }
    }
}
