.wheel-of-fortune.page-container {
    padding-bottom: 48px;

    h3 {
        font-size: 18px;
    }

    .wheel-of-fortune-content-mobile {
        display: flex;
        flex-direction: column;
        min-height: calc(100vh - 110px);
        color: $white;
        text-align: center;
        padding: 0 10px 58px;

        &.loading {
            justify-content: center;

            .wheel-of-fortune-loader {
                margin-top: 48px;
                align-self: center;
            }
        }

        &.no-content {
            padding: 20px 0;
            justify-content: flex-start;

            .wheel-of-fortune-maintenance {
                .wild-fortunes {
                    width: 240px;
                }

                .maintenance-wrapper {
                    max-width: 440px;
                    padding: 50px 30px;
                    flex: none;

                    .maintenance-container {
                        max-width: 360px;

                        &--inner {
                            padding: 16px 12px;
                            font-size: 11px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }

    .wheel-of-fortune--prizes-timer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 16px;
        margin-top: 15px;

        .wheel-of-fortune--timer {
            width: 100%;
            padding: 0;
        }

        h4 {
            font-size: 14px;
            font-weight: 400;
        }

        .gift-text-prize {
            font-size: 16px;
        }

        .card {
            padding: 16px;
            width: 100%;
        }
    }

    .wheel-of-fortune--main {
        padding: 0 10px;
    }

    .wheel-of-fortune-info {
        padding: 0;

        &.terms-and-conditions {
            width: 100%;
            margin-top: 48px;
            font-size: 14px;

            h3 {
                margin-top: 48px;
            }
        }
    }

    .side {
        position: fixed;
        right: 0;
        top: 74px;
        background-color: $violet-new;
        z-index: 100;

        &-container {
            position: fixed;
            display: flex;
            width: 0;
            height: 100%;
            transition: all 0.3s ease-in-out;
            transform: translateX(0);
            top: 0;

            &--open {
                transform: translateX(-85%);
                width: 100%;
            }

            h3 {
                text-align: center;
            }
        }

        &-item-wrapper {
            position: fixed;
            right: calc(100% - 1px);
            top: 74px;

            .side-item {
                width: 34px;
                height: 110px;
                background-color: $new-footer-violet;
                color: white;
                border: 0;
                border-radius: 12px 0 0 12px;
                overflow: hidden;
                padding: 0;

                .menu-icon {
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        &-menu {
            &--slider {
                width: 85%;
                background-color: $new-footer-violet;
                padding: 32px 0;
                color: $white;
                transition: all 0.3s ease-in-out;
                overflow: hidden;

                &-open {
                    padding: 32px 16px;
                }
            }

            &--opacity {
                opacity: 0;
                transition: opacity 0.1s ease-in-out;

                &-open {
                    opacity: 1;
                    transition: opacity 0.3s ease-in-out 0.2s;
                }
            }
        }
    }

    .side-menu-body-wrapper {
        .table {
            &-info {
                &--item {
                    background: $wheel-of-fortune-table-info-item;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 16px 10px;
                }
            }

            &-content {
                table {
                    background: $new-footer-violet;

                    .table-header {
                        background: $wheel-of-fortune-table-title;
                    }

                    tr {
                        background: $wheel-of-fortune-table-row;
                        font-size: 10px;
                    }

                    td,
                    th {
                        padding: 14px 8px;
                        text-align: center;
                    }

                    .current-player--edit {
                        svg {
                            width: 10px;
                            height: 10px;
                        }
                    }
                }
            }

            .refresh {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px 30px;
                height: auto;
                margin: 24px auto;

                &-icon {
                    display: flex;
                    transition: transform 0.5s ease;
                    margin-right: 10px;

                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        .table--no-participants {
            background: $new-footer-violet;

            .text {
                height: 300px;
                font-size: 12px;

                h5 {
                    margin: 16px 0;
                    font-size: 18px;
                    font-weight: 800;
                }
            }
        }
    }

    .table-info--item {
        padding: 16px;

        &--number {
            font-size: 24px;
        }

        &--title {
            font-size: 14px;
            font-weight: 400;
        }
    }

    .wof-messages-carousel {
        @include wof-carousel-card-width(10px);
    }
}

.wheel-of-fortune.page-container,
.wheel-of-fortune-modal {
    .content-wrapper {
        padding: 10px 0;

        .logo-wrapper {
            img {
                height: 100px;
                max-width: 100%;
            }
        }
    }

    .wheel-wrapper {
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        margin-top: -50px;
    }
}

.wheel-of-fortune-modal {
    &.modal--modern {
        .modal-box {
            overflow-y: auto;

            &--content {
                .wheel-of-fortune-maintenance {
                    height: 500px;

                    .wild-fortunes {
                        width: 240px;
                    }

                    .maintenance-wrapper {
                        padding: 0 30px;

                        .maintenance-container {
                            max-width: 360px;

                            &--inner {
                                padding: 12px;
                                font-size: 11px;
                                line-height: 18px;
                            }
                        }
                    }
                }

                .modal-info {
                    font-size: 12px;
                    margin: 0 auto;
                }

                .container-btns {
                    padding: 8px 0;

                    .btn-submit {
                        width: 100%;
                        height: 40px;
                        align-self: center;
                    }
                }

                .wof-messages-carousel {
                    @include wof-carousel-card-width(16px);
                }
            }
        }
    }
}

.win-modal {
    &.modal--modern {
        .modal-box {
            &--content {
                position: relative;
                height: 100%;
                overflow-y: auto;
                gap: 32px;

                .prize {
                    margin: 30px auto;

                    &--bonus {
                        img {
                            width: 180px;
                            height: auto;
                        }
                    }
                }

                h2 {
                    font-size: 18px;
                }

                p {
                    font-size: 12px;
                }

                .btn-submit {
                    position: sticky;
                    width: 100%;
                    bottom: 0;
                    height: 40px;
                    flex-shrink: 0;
                }
            }
        }
    }
}

.nickname-modal {
    .modal-box--title {
        padding: 0 50px;
    }
}

.wheel-widget {
    margin: 5px;
    width: 80px;
    height: 80px;

    &:not(.with-ftd) {
        bottom: 130px;
    }

    &.with-ftd {
        bottom: 200px;
    }

    img {
        width: 64px;
        height: 64px;
    }

    .timer {
        font-size: 12px;
    }

    .spins-count {
        width: 24px;
        height: 24px;
        font-size: 16px;
    }
}

.wof-messages-carousel {
    .carousel {
        &.carousel-slider {
            .slider-wrapper ul {
                .slide {
                    height: auto;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    font-weight: 400;
                    padding-inline: 0;
                }
            }
        }
    }
}
