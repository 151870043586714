.MuiLinearProgress {
    &-root {
        background: rgba(135, 0, 139, 0.1);
        height: 10px;
        border-radius: 5px;
        margin: 6px 0 16px;

        &.modern {
            border-radius: 2px;
            height: 4px;
            background: $progress-bar;

            .MuiLinearProgress-bar {
                border-radius: 2px;
            }
        }
    }

    &-bar {
        background: $orange;
    }
}
