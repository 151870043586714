.error-page {
    min-height: 100vh;
    background: $main-bg url('#{$asset-path}/error_page_bg_mobile.jpg') no-repeat center center;
    background-size: cover;

    .container {
        padding: 0 15px;
        width: 100%;
        min-height: 90vh;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &--content {
        text-align: left;
        margin-bottom: 24px;

        &.maintenance {
            text-align: center;

            .error-page--description {
                max-width: none;
                text-align: center;
            }
        }
    }

    &__buttons {
        display: flex;
        align-items: center;

        [class^='btn'] {
            & + [class^='btn'] {
                margin-left: 20px;
            }
        }
    }

    &--image {
        padding-bottom: 24px;
        text-align: left;

        img {
            max-width: 720px;
            width: 100%;
        }
    }

    &--image-main {
        position: relative;
        z-index: 2;
    }

    &--image-shadow {
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    &--image-description {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: $info-text;
        text-align: right;
        padding-right: 16%;

        @media (max-width: 580px) {
            padding-right: 13%;
        }

        @media (max-width: 460px) {
            padding-right: 9%;
        }

        @media (max-width: 360px) {
            padding-right: 6%;
        }

        @media (max-width: 320px) {
            padding-right: 3%;
        }
    }

    &--image-container {
        position: relative;
        margin-bottom: 24px;
        text-align: right;

        .canvas {
            position: absolute;
            width: 70%;
            height: 60%;
            right: 0;
            bottom: 0;
            z-index: 3;
        }
    }

    &--big-title {
        font-weight: 800;
        font-size: 72px;
        line-height: 1.2;
        margin-left: -5px;
        padding-top: 18px;
        display: inline-block;
        vertical-align: top;
        background: linear-gradient(263.55deg, $blue 0%, #ff006e 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    &--message {
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 20px;
        color: $step-content;
    }

    &--description {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: $step-content;
        text-align: justify;
        margin-bottom: 24px;
    }

    &--video {
        mix-blend-mode: screen;
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: -1;
        visibility: hidden;

        &.visible {
            visibility: visible;
            z-index: 5;
        }

        @media (min-aspect-ratio: 16/9) {
            height: 300%;
            top: -100%;
        }

        @media (max-aspect-ratio: 16/9) {
            width: 300%;
            left: -100%;
        }

        @supports (object-fit: cover) {
            object-fit: cover;
        }
    }
}
