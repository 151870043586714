.live-page,
.lobby--lives {
    .game-list {
        gap: 30px;

        &.live-lobby {
            margin-bottom: 64px;
            grid-auto-rows: 0; /* set height to 0 for autogenerated grid rows */

            &:not(.live-lobby--most-played) {
                row-gap: 0;
                grid-template-rows: repeat(1, 1fr);
            }

            &--most-played {
                grid-template-rows: repeat(2, 1fr);

                @media screen and (max-width: 1799px) and (min-width: 1350px) {
                    margin-bottom: 34px;
                }

                @media screen and (max-width: 1349px) {
                    margin-bottom: 4px;
                }

                .game-list-item:first-of-type {
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 1;
                    grid-row-end: 3;
                }
            }
        }
    }
}

.lobby--lives {
    .game-list {
        grid-template-columns: repeat(6, 1fr);

        @media screen and (max-width: 1349px) {
            grid-template-columns: repeat(5, 1fr);
        }
    }
}

.live-page {
    .content-box {
        .title {
            font-size: 32px;
            font-weight: 800;
            display: flex;
            align-items: center;
            justify-content: space-between;

            a {
                font-size: 14px;
                color: $violet-new;

                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .game-list {
            grid-template-columns: repeat(5, 1fr);

            @media screen and (max-width: 1599px) {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }
}
