.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 36px auto 12px;

    &-list {
        display: flex;
    }

    &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        margin: 0 3px;

        @media screen and (max-width: 380px) {
            font-size: 12px;
            width: 24px;
            height: 24px;
        }

        &.bordered {
            background: $white;
        }

        &.active {
            background: $violet-new;
            color: $white;
        }

        &.arrow {
            flex-shrink: 0;

            &:first-of-type {
                margin-right: 15px;
            }

            &:last-of-type {
                margin-left: 15px;
            }
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
