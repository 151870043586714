.cms-page {
    padding: 0 10px;
    font-size: 14px;

    .form-loader .top {
        justify-content: center;
    }

    &__header {
        display: flex;
        align-items: center;

        &-image {
            margin-right: 20px;

            img {
                max-width: 54px;
                max-height: 64px;
            }
        }

        &-title {
            h1 {
                font-size: 24px;
                line-height: 28px;
                padding: 20px 0 0;
                margin: 0;
                font-weight: 400;
            }
        }

        &-subheading {
            padding-top: 5px;
            font-size: 14px;
            line-height: 18px;
        }
    }

    &-content {
        padding: 24px 0 48px;

        .printable-content {
            > *:first-child {
                font-weight: 800;
                font-size: 18px;
            }
        }

        a {
            text-decoration: none;
            color: $violet-new;
        }

        p {
            font-size: 14px;
            line-height: 1.4;

            & + p {
                margin-bottom: 30px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .content-loading {
            text-align: center;
            margin: 64px 0;

            .loader {
                width: 45px;
                height: 45px;
                margin: 16px auto;
            }
        }

        .no-content {
            text-align: center;
            font-weight: bold;
        }

        .printBtn {
            margin-bottom: 10px;
        }
    }

    &__title {
        color: $cms-title;
        font-size: 18px;
    }

    &__table {
        width: 100%;
        margin: 0 0 40px;
        border-collapse: collapse;
        border-spacing: 0;

        tr {
            th {
                border-bottom: 2px solid $dark-gray-1;
            }

            th,
            td {
                padding: 10px 0;
                text-align: center;
                font-size: 14px;
            }
        }

        tbody {
            tr {
                & + tr {
                    border-top: 1px solid rgba(128, 128, 128, 0.15);
                }

                td {
                    padding: 10px;
                    color: $dark-gray-1;

                    &:first-child {
                        color: $dark;
                        text-align: left;
                    }

                    &:last-child {
                        font-weight: 700;
                    }
                }
            }
        }
    }

    &__banners {
        padding-bottom: 20px;
        border-bottom: 1px solid $dark-gray-1;
        margin-bottom: 30px;
    }

    &.vip,
    .text-center {
        text-align: center;
    }
}
