.promotions {
    min-height: auto;

    .page-header {
        margin-top: 0;
        font-size: 32px;
    }

    .panel-tabs {
        margin-bottom: 24px;

        .MuiTabs-flexContainer {
            gap: 24px;
        }
    }

    .load-more {
        margin: 0 auto;
        position: relative;
        padding: 25px 0;

        .btn {
            position: absolute;
            bottom: 50px;
            box-shadow: 0 20px 20px -15px $darkest-purple;
        }
    }

    h3 {
        font-size: 32px;
        font-weight: 800;
        margin-bottom: 24px;
    }
}

.promotion {
    .visual {
        position: relative;

        .promo-plate-list {
            position: absolute;
            top: 16px;
            right: 16px;
            display: flex;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            gap: 8px;
        }
    }

    .infos {
        padding: 18px 25px;
        gap: 24px;

        &-text-container {
            min-width: 0;

            .promo-title {
                font-size: 18px;
                line-height: 30px;
            }
        }
    }
}

.container-promotions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    margin-bottom: 85px;
    position: relative;
}

.no-promotion-content {
    margin-top: 40px;
    font-weight: 700;
    font-size: 18px;
}

.promotion-modal {
    &.modal--modern {
        .modal-box {
            width: $promo-modal-default;
            height: $promo-modal-default;
            padding-bottom: 0;
            display: flex;
            flex-direction: column;
        }

        .modal-box--title {
            > div:first-child {
                gap: 16px;
            }
        }

        .modal-box--content {
            height: 100%;
            margin: 0 -24px;
            padding: 0 24px;
            border-radius: 0 0 10px 10px;
            scrollbar-gutter: stable;
        }
    }

    .promotions-modal-data {
        display: grid;
        gap: 12px;
        grid-template-columns: minmax(200px, 1fr) 2fr;

        &--image {
            position: relative;
            display: block;
            width: inherit;

            img {
                width: 100%;
                height: 100%;
                border-radius: 10px;
                object-fit: cover;
            }

            .promo-plate-status {
                display: none;
            }
        }
    }

    .MuiAccordionSummary-root {
        padding: 0 12px;

        &:hover {
            color: $violet-new;
        }
    }

    .MuiAccordionDetails-root {
        padding: 24px 12px;

        .game-list {
            grid-template-columns: repeat(3, 1fr);
            gap: 12px;
        }
    }

    .slide-btn {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 1px solid $white;
        background: none;
        position: fixed;
        top: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: rgba($white, 0.3);
        }

        svg {
            flex-shrink: 0;
            width: 16px;
            height: 16px;

            path {
                fill: $white;
            }
        }

        &--previous {
            left: calc(50% - $promo-modal-default / 2 - 70px);
        }

        &--next {
            right: calc(50% - $promo-modal-default / 2 - 70px);
        }
    }
}

.promo-plate-status {
    padding: 8px;
    font-size: 16px;
    line-height: 16px;

    .pulse-dot {
        width: 10px;
        height: 10px;
    }
}

.promo-days-of-week-wrapper {
    position: absolute;
    bottom: 20px;
    padding-left: 16px;
}

.promo-days-of-week-wrapper-modal {
    grid-column: 1 / -1;
    padding-left: 0;
    margin: 0;
}

.promo-days-of-week {
    display: flex;
    list-style: none;
    gap: 4px;

    li {
        font-size: 12px;
        padding: 4px 8px;
        border-radius: 14px;
        background: $coral-light;
        font-weight: 800;
        color: $coral;
    }
}

@media screen and (max-width: 1759px) and (max-height: 899px) {
    .promotion-modal {
        .game-list-item {
            @include game-list-item-live-small;
        }
    }
}

@media screen and (min-width: 1760px) and (min-height: 900px) {
    .promotion-modal {
        &.modal--modern {
            .modal-box {
                width: $promo-modal-big;
                height: $promo-modal-big;

                .modal-box--title {
                    .promo-plate-status {
                        display: none;
                    }
                }

                .promotions-modal-data {
                    grid-template-columns: 2fr 1fr;

                    .promotions-modal-data--image {
                        .promo-plate-status {
                            display: flex;
                            position: absolute;
                            top: 16px;
                            right: 16px;
                        }
                    }

                    .promotions-modal-data--timer,
                    .promotions-modal-data--not-available {
                        align-self: center;
                    }
                }
            }

            .slide-btn {
                &--previous {
                    left: calc(50% - $promo-modal-big / 2 - 70px);
                }

                &--next {
                    right: calc(50% - $promo-modal-big / 2 - 70px);
                }
            }
        }
    }
}
