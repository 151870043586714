.lobby-page {
    // Styles for welcome part
    .lobby--welcome {
        display: flex;
        width: 100%;
        gap: 70px;

        &:not(.reduced) > div:first-of-type {
            width: 100%;
            transition: all 0.5s ease-in-out;
        }

        &.reduced {
            > div:first-of-type {
                width: calc((100% - 70px) * 2 / 3);
                animation: reduceBanner 0.5s ease-in-out;
            }

            > div:last-of-type {
                width: calc((100% - 70px) * 1 / 3);
                transform: translate(0, 0);
                animation: growBonuses 0.5s ease-in-out;
            }

            .banner {
                .offer {
                    &-details {
                        h1 {
                            font-size: 36px;
                            padding: 8px 0;
                        }

                        h2 {
                            font-size: 24px;
                        }

                        h3 {
                            font-size: 18px;
                        }
                    }
                }
            }
        }

        .welcome-banner-carousel {
            height: $banner-height + $lobby-bottom-margin;
        }

        .welcome-banner {
            height: $banner-height;
            margin-bottom: $lobby-bottom-margin;
        }

        .banner-carousel {
            position: relative;
            left: -$banner-padding-side;
            height: 100%;
            width: calc(100% + $banner-padding-side * 2);

            .carousel {
                &-slider {
                    display: flex;
                    flex-direction: column;

                    .slider-wrapper {
                        ul {
                            .slide {
                                padding: 0 $banner-padding-side $lobby-bottom-margin;

                                .offer-details {
                                    text-align: left;
                                }

                                .bonus-card--icon:not(.bonus-card--icon-free-rounds) {
                                    img {
                                        width: auto;
                                    }
                                }
                            }
                        }
                    }
                }

                .control-dots {
                    bottom: 20px;
                }
            }
        }

        .banner {
            display: flex;
            height: 100%;
            justify-content: right;
            border-radius: 20px;
            position: relative;
            width: 100%;

            .offer {
                position: relative;
                width: 100%;
                border-radius: 20px 0 0 20px;

                &:after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    width: calc((100vw - 130px) / 3);
                    min-width: calc((1280px - 130px) / 3);
                    transform: translate(calc(100% - 1px), 0);
                    border-radius: inherit;
                    z-index: 0;
                }

                &-details {
                    width: max-content;
                    min-width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    border-radius: inherit;
                    color: $white;
                    padding: 0 50px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    br.mobile {
                        display: none;
                    }

                    h1,
                    h2,
                    h3 {
                        transition: all 0.5s ease-in-out;
                    }

                    h1 {
                        font-size: 48px;
                        font-weight: 800;
                        margin: 0;
                        border-bottom: 1px solid $white;
                    }

                    h2 {
                        font-size: 32px;
                        font-weight: 800;
                        margin: 12px 0 24px;
                    }

                    h3 {
                        font-size: 24px;
                        font-weight: 600;
                        margin: 0;
                    }

                    .btn {
                        height: 56px;
                        border-radius: 100px;
                        font-size: 18px;
                        max-width: 320px;
                    }
                }
            }

            .banner--bg {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 20px;
            }
        }

        .bonus-carousel {
            height: calc($banner-height + $lobby-bottom-margin);
            margin: 0;

            .carousel {
                &-slider {
                    width: calc(100% + 20px) !important;
                    margin: 0 -10px;
                    display: flex;
                    flex-direction: column;

                    .slider-wrapper {
                        ul {
                            .slide {
                                padding: 40px 10px $lobby-bottom-margin;

                                .bonus-card--icon:not(.bonus-card--icon-free-rounds) {
                                    img {
                                        width: auto;
                                    }
                                }
                            }
                        }
                    }
                }

                .control-dots {
                    bottom: 20px;
                }
            }

            .bonus-card {
                height: 100%;
                padding-top: 60px;
                box-shadow: 0 32px 24px -18px rgba(228, 142, 69, 0.5);

                &--claim-bonus {
                    margin: 24px 0 16px;
                }

                &--description {
                    padding: 12px 0;
                }
            }
        }

        .title-welcome {
            .name {
                display: flex;
                align-items: center;

                img {
                    position: relative;
                    top: -2px;
                    margin-right: 12px;
                    max-width: 45px;
                }

                .first-name {
                    color: $new-purple;
                }
            }
        }
    }

    // Styles for games part
    .lobby--games {
        margin-bottom: $lobby-bottom-margin;

        &:not(.tabs-only) {
            display: grid;
            row-gap: 24px;
            grid-template-columns: minmax(300px, 1fr) 2fr;
            grid-template-rows: auto auto;
            position: relative;
            grid-auto-flow: column;
            column-gap: 70px;

            .title {
                margin: 0;
                height: 48px;
            }

            .games-lobby--favorites,
            .games-lobby--latest {
                .game-list {
                    grid-template-columns: repeat(4, 1fr) !important;

                    @media screen and (max-width: 1349px) {
                        grid-template-columns: repeat(3, 1fr) !important;
                    }
                }
            }
        }

        .games-lobby--featured {
            .game-list {
                &-item {
                    height: 100%;
                    margin: 0;

                    &--background {
                        height: 100%;

                        img {
                            aspect-ratio: 1.33;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        .games-lobby--favorites,
        .games-lobby--latest {
            .game-list {
                justify-content: space-between;
                grid-template-columns: repeat(6, 1fr) !important;
                grid-template-rows: repeat(2, 1fr);
                grid-auto-rows: 0; /* set height to 0 for autogenerated grid rows */

                @media screen and (max-width: 1349px) {
                    grid-template-columns: repeat(5, 1fr) !important;
                    margin-bottom: -30px;
                }
            }
        }

        .games-lobby--favorites {
            &:not(.extended) {
                .game-list {
                    grid-template-rows: repeat(1, 1fr);
                }

                &.full-row {
                    .game-list {
                        @media screen and (max-width: 1349px) {
                            grid-template-rows: repeat(2, 1fr);
                        }
                    }
                }
            }
        }
    }

    // Styles for promotions block
    .lobby--promotions {
        .container-promotions {
            margin-bottom: $lobby-bottom-margin;
        }
    }
}

@media screen and (max-width: 1500px) and (min-width: 1350px) {
    .lobby-page {
        .game-list-item {
            @include game-list-item-live-small;
        }
    }
}

@keyframes reduceBanner {
    0% {
        width: 100%;
    }
    100% {
        width: calc((100% - 70px) * 2 / 3);
    }
}

@keyframes growBonuses {
    0% {
        transform: translate(100%, 0);
        opacity: 0;
    }
    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}
