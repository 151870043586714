//TODO Replace form-control with form-control-new to apply the same styles across the app
@mixin form-control {
    @include font-family-nunito;
    font-size: 12px;
    padding: 0 12px;
    border-radius: 8px;
    border: none;
    outline: none;
    height: 30px;
    font-weight: 300;
    letter-spacing: 0.01em;
    width: 100%;
    background: $white;

    &[disabled] {
        background: $input-disabled;
    }

    &:focus::placeholder {
        opacity: 0.4;
    }
}

@mixin form-control-new {
    @include font-family-nunito;
    font-size: 14px;
    padding: 0 12px;
    border-radius: 5px;
    border: 1px solid rgba(194, 152, 117, 0.3);
    outline: none;
    height: 40px;
    font-weight: 400;
    letter-spacing: 0.01em;
    width: 100%;
    background: $white;
    color: $main-color;
    background-clip: padding-box;
}

@mixin font-family-nunito {
    font-family: 'Nunito', 'Roboto', sans-serif;
}

@mixin font-family-roboto {
    font-family: 'Roboto', sans-serif;
}

@mixin prepend-field {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 100%;
    font-weight: 600;
    font-size: 21px;
    line-height: 25px;

    input {
        margin: 0 !important;
        border-radius: 5px 0 0 5px !important;
    }

    .transaction-amount-currency,
    .prepend-field--mark {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $currency-color;
        width: 40px;
        flex-shrink: 0;
        height: inherit;
        background: $currency-bg;
        border: 1px solid $card-border;
        border-radius: 0 5px 5px 0;
        border-left: none;
    }
}

@mixin mui-list-item-root {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    height: 40px;

    &.Mui-selected,
    &:hover {
        background: $light-graphite;
    }
}

@mixin box-shadow {
    content: '';
    position: absolute;
    height: 100%;
    border-radius: 20px;
    background: rgba(228, 142, 69, 0.5);
    filter: blur(25px);
    box-sizing: border-box;
    z-index: -1;
}

@mixin sidebar-slider-transform($index) {
    transform: translate(0, calc(40px * $index));
}

@mixin reveal-password {
    position: absolute;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 10px;
    right: 0;
    cursor: pointer;
    transform: translate(0, 50%);

    span {
        position: absolute;
        width: 24px;
        height: 4px;
        background: $document-color;
        border-radius: 1px;
        border-top: solid $white 2px;
        transform: rotate(-45deg);
        transition: all ease-in-out 0.3s;
    }

    &.hide {
        span {
            width: 0;
        }
    }
}

@mixin hidden-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin game-list-item-live-small {
    .game-list-item--limits-modern {
        .game-list-item--text-details-minLimit,
        .game-list-item--text-details-maxLimit {
            .label {
                font-size: 10px !important;
            }
        }
    }

    &:hover {
        .game-list-item--limits-modern {
            transform: translate(0, 8px) !important;
        }
    }
}

@mixin mystery-chest-floating($rotate, $pos1: 5px, $pos2: -5px) {
    --img-rotate: #{$rotate};
    --img-pos1: #{$pos1};
    --img-pos2: #{$pos2};

    animation: floatingAnimation 4s linear infinite;
    transform: translateY(0) var(--img-rotate);
    animation-play-state: running;

    &:hover {
        animation-play-state: paused;
    }

    @keyframes floatingAnimation {
        0% {
            transform: translateY(0) var(--img-rotate);
        }
        25% {
            transform: translateY(var(--img-pos1)) var(--img-rotate);
        }
        50% {
            transform: translateY(0) var(--img-rotate);
        }
        75% {
            transform: translateY(var(--img-pos2)) var(--img-rotate);
        }
        100% {
            transform: translateY(0) var(--img-rotate);
        }
    }
}

@mixin vip-level-icon--wrapper {
    padding: 8px;
    border-radius: 50%;
    background-color: $light-peach;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin header-back-btn {
    border: none;
    background: transparent;
    padding: 0;

    svg {
        vertical-align: middle;
    }

    &:hover {
        svg {
            path {
                fill: $violet-new;
            }
        }
    }
}

@mixin jackpot-value($color: $white) {
    -webkit-text-stroke-color: $color;
    font-weight: 900;
    color: $white;
    paint-order: stroke fill;
    margin: 0;
    display: flex;
    align-items: center;
}

@mixin jackpot-item-mask($radius: 20px) {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: $radius;
    pointer-events: none;
    mask-image: $jackpot-mask;
}
