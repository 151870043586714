.live-page,
.lobby--lives {
    .content-box {
        margin-bottom: 48px;
    }

    .game-list {
        display: flex;
        flex-wrap: wrap;
        gap: 12px;
    }

    .top-game-list {
        &.with-filter {
            display: flex;
            gap: 8px;
            align-items: center;
            justify-content: space-between;
            padding: 8px 12px;
        }

        .sorting-icon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                content: '';
                border-radius: 8px;
                background: $coral;
                width: 8px;
                height: 8px;
                position: absolute;
                top: -4px;
                right: -4px;
                opacity: 0;
                transition: opacity 0.3s ease-in-out;
            }

            &.active:before {
                opacity: 1;
            }
        }

        .title {
            margin: 0;
            padding: 0;
        }

        .breadcrumbs-container {
            margin-bottom: 0;
        }
    }

    .favorite-page-loader {
        display: flex;
        justify-content: center;
    }
}

.live-lobby,
.lobby--lives {
    .game-list {
        display: block;
        margin: 0;
        overflow: hidden;

        .scroll-container {
            @include scroll-container;
            gap: 12px;
            padding: 0 10px;

            .game-list-item {
                box-shadow: none;
                min-width: calc(50% - 16px);
                width: calc(50% - 16px);
            }
        }
    }

    .top-game-list {
        .title {
            padding: 8px 10px;

            .name {
                font-size: 18px;
                font-weight: 800;
            }

            a {
                font-size: 12px;
                color: $violet-new;
                font-weight: 600;
            }
        }
    }
}

.game-preview-modal {
    .modal-box--title {
        text-align: left;
        padding-right: 50px;
    }
}

.sorting-panel {
    .panel-title {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 16px;
    }
}
