input:not(.ReactInputVerificationCode__input) {
    @include form-control;
    line-height: 44px;
}

/* Hack to disable changing background color to yellow
on field autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 6000s linear 6000s;
}
