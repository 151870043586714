.communication-preferences-modal {
    &.modal--modern {
        .modal-box {
            width: $promo-modal-default;
            height: $promo-modal-default;
            padding-bottom: 0;
            display: flex;
            flex-direction: column;

            &--content {
                height: calc(100% - 100px);
                position: relative;
            }

            .subtitle {
                font-size: 16px;
                line-height: 22px;
                font-weight: 800;
            }

            .profile-data {
                &--content {
                    background: transparent;
                    padding: 0;
                    position: static;

                    &:after {
                        display: none;
                    }

                    .description {
                        font-size: 14px;
                        line-height: 21px;
                    }

                    .btns-container {
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        justify-content: center;
                        flex-direction: row-reverse;
                    }
                }
            }

            @media screen and (min-width: 1760px) and (min-height: 900px) {
                width: $promo-modal-big;
                height: $promo-modal-big;
            }
        }
    }
}
