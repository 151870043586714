#deposit-button {
    position: relative;
    padding-left: 40px;

    .plus-icon {
        font-size: 21px;
        font-weight: 800;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 4px;
        left: 4px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        color: $white;
        background-color: $light-orange;

        .plus {
            width: 12px;
            height: 20px;
        }
    }

    .btn-label.with-plus {
        padding-left: 30px;
        white-space: nowrap;
    }

    &.balance-increased::after {
        position: absolute;
        content: '';
        left: -125px;
        top: -180px;
        min-width: 265px;
        min-height: 265px;
        pointer-events: none;
        transition:
            background-size ease-in-out 0.5s,
            background-position ease-in-out 0.5s;
        background-repeat: no-repeat;
        overflow: hidden;
        z-index: -1;
        animation: bubblesBtnAnimation linear 2s forwards;
        background-image: radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%),
            radial-gradient(circle, rgba(255, 190.00000000000017, 10.99999999999999, 1) 45%, transparent 45%);
    }
}

@keyframes bubblesBtnAnimation {
    0% {
        background-size:
            25px 25px,
            26px 26px,
            20px 20px,
            18px 18px,
            26px 26px,
            21px 21px,
            24px 24px,
            27px 27px,
            19px 19px,
            17px 17px,
            21px 21px,
            17px 17px,
            26px 26px,
            21px 21px,
            18px 18px,
            27px 27px,
            19px 19px,
            22px 22px,
            26px 26px,
            27px 27px;
        background-position:
            232px 195px,
            225px 208px,
            218px 221px,
            207px 228px,
            189px 226px,
            177px 227px,
            163px 219px,
            152px 207px,
            150px 199px,
            148px 186px,
            148px 170px,
            157px 159px,
            163px 145px,
            177px 141px,
            193px 140px,
            202px 138px,
            219px 148px,
            227px 157px,
            232px 167px,
            233px 181px;
    }
    3.3% {
        background-size:
            25px 25px,
            26px 26px,
            20px 20px,
            18px 18px,
            25px 25px,
            20px 20px,
            24px 24px,
            26px 26px,
            18px 18px,
            17px 17px,
            21px 21px,
            16px 16px,
            25px 25px,
            21px 21px,
            18px 18px,
            26px 26px,
            19px 19px,
            22px 22px,
            26px 26px,
            27px 27px;
        background-position:
            240px 191px,
            221px 212px,
            203px 223px,
            197px 228px,
            181px 227px,
            168px 234px,
            153px 208px,
            149px 205px,
            146px 195px,
            152px 174px,
            155px 159px,
            165px 154px,
            158px 144px,
            184px 138px,
            191px 137px,
            208px 135px,
            219px 138px,
            228px 152px,
            237px 171px,
            234px 195px;
    }
    6.6% {
        background-size:
            24px 24px,
            26px 26px,
            20px 20px,
            18px 18px,
            25px 25px,
            20px 20px,
            24px 24px,
            26px 26px,
            18px 18px,
            17px 17px,
            21px 21px,
            16px 16px,
            25px 25px,
            21px 21px,
            18px 18px,
            26px 26px,
            19px 19px,
            22px 22px,
            25px 25px,
            27px 27px;
        background-position:
            242px 187px,
            212px 211px,
            187px 216px,
            187px 221px,
            176px 222px,
            162px 236px,
            151px 194px,
            153px 199px,
            151px 191px,
            163px 166px,
            167px 155px,
            178px 157px,
            159px 149px,
            192px 143px,
            189px 141px,
            210px 138px,
            216px 135px,
            223px 153px,
            235px 176px,
            229px 206px;
    }
    10% {
        background-size:
            24px 24px,
            26px 26px,
            20px 20px,
            18px 18px,
            24px 24px,
            20px 20px,
            23px 23px,
            26px 26px,
            17px 17px,
            17px 17px,
            21px 21px,
            15px 15px,
            24px 24px,
            21px 21px,
            18px 18px,
            26px 26px,
            18px 18px,
            22px 22px,
            25px 25px,
            27px 27px;
        background-position:
            239px 183px,
            199px 202px,
            173px 195px,
            180px 201px,
            173px 209px,
            159px 234px,
            156px 179px,
            163px 190px,
            162px 187px,
            179px 166px,
            184px 160px,
            193px 174px,
            165px 160px,
            199px 158px,
            188px 155px,
            210px 147px,
            211px 137px,
            213px 160px,
            226px 182px,
            218px 213px;
    }
    13.3% {
        background-size:
            23px 23px,
            26px 26px,
            21px 21px,
            19px 19px,
            23px 23px,
            20px 20px,
            23px 23px,
            25px 25px,
            17px 17px,
            17px 17px,
            21px 21px,
            15px 15px,
            24px 24px,
            21px 21px,
            18px 18px,
            25px 25px,
            18px 18px,
            22px 22px,
            25px 25px,
            26px 26px;
        background-position:
            229px 180px,
            184px 174px,
            167px 167px,
            178px 170px,
            175px 183px,
            158px 229px,
            168px 168px,
            180px 177px,
            180px 183px,
            199px 186px,
            200px 187px,
            206px 206px,
            176px 181px,
            202px 189px,
            189px 184px,
            205px 166px,
            205px 145px,
            198px 179px,
            211px 188px,
            203px 213px;
    }
    16.6% {
        background-size:
            23px 23px,
            26px 26px,
            21px 21px,
            19px 19px,
            23px 23px,
            19px 19px,
            23px 23px,
            25px 25px,
            17px 17px,
            17px 17px,
            21px 21px,
            14px 14px,
            24px 24px,
            21px 21px,
            18px 18px,
            25px 25px,
            17px 17px,
            22px 22px,
            25px 25px,
            26px 26px;
        background-position:
            212px 179px,
            172px 156px,
            168px 149px,
            181px 155px,
            183px 161px,
            161px 222px,
            185px 170px,
            201px 177px,
            203px 187px,
            212px 206px,
            210px 211px,
            213px 220px,
            193px 199px,
            200px 213px,
            193px 214px,
            195px 195px,
            197px 162px,
            181px 198px,
            189px 186px,
            186px 202px;
    }
    20% {
        background-size:
            22px 22px,
            26px 26px,
            21px 21px,
            19px 19px,
            22px 22px,
            19px 19px,
            23px 23px,
            25px 25px,
            16px 16px,
            16px 16px,
            22px 22px,
            13px 13px,
            23px 23px,
            21px 21px,
            18px 18px,
            25px 25px,
            17px 17px,
            22px 22px,
            24px 24px,
            26px 26px;
        background-position:
            189px 183px,
            166px 155px,
            173px 142px,
            188px 158px,
            194px 164px,
            167px 210px,
            202px 192px,
            213px 188px,
            218px 193px,
            219px 211px,
            215px 220px,
            216px 223px,
            207px 193px,
            196px 219px,
            197px 222px,
            183px 205px,
            189px 198px,
            169px 196px,
            170px 173px,
            173px 173px;
    }
    23.3% {
        background-size:
            22px 22px,
            26px 26px,
            21px 21px,
            19px 19px,
            21px 21px,
            19px 19px,
            23px 23px,
            24px 24px,
            16px 16px,
            16px 16px,
            22px 22px,
            13px 13px,
            23px 23px,
            21px 21px,
            18px 18px,
            24px 24px,
            17px 17px,
            22px 22px,
            24px 24px,
            26px 26px;
        background-position:
            170px 189px,
            165px 165px,
            181px 142px,
            194px 182px,
            201px 190px,
            179px 190px,
            212px 203px,
            217px 194px,
            224px 193px,
            220px 207px,
            215px 222px,
            215px 218px,
            214px 176px,
            191px 211px,
            199px 217px,
            173px 191px,
            185px 221px,
            164px 183px,
            160px 171px,
            166px 152px;
    }
    26.6% {
        background-size:
            22px 22px,
            26px 26px,
            21px 21px,
            20px 20px,
            21px 21px,
            19px 19px,
            23px 23px,
            24px 24px,
            15px 15px,
            16px 16px,
            22px 22px,
            12px 12px,
            23px 23px,
            21px 21px,
            18px 18px,
            24px 24px,
            16px 16px,
            22px 22px,
            24px 24px,
            26px 26px;
        background-position:
            161px 188px,
            171px 184px,
            189px 151px,
            198px 211px,
            203px 207px,
            196px 170px,
            216px 201px,
            214px 193px,
            222px 190px,
            215px 195px,
            211px 218px,
            209px 203px,
            213px 167px,
            186px 184px,
            200px 197px,
            171px 169px,
            183px 228px,
            167px 171px,
            158px 175px,
            165px 143px;
    }
    30% {
        background-size:
            21px 21px,
            26px 26px,
            21px 21px,
            20px 20px,
            20px 20px,
            18px 18px,
            23px 23px,
            24px 24px,
            15px 15px,
            16px 16px,
            22px 22px,
            12px 12px,
            22px 22px,
            21px 21px,
            18px 18px,
            24px 24px,
            16px 16px,
            22px 22px,
            24px 24px,
            26px 26px;
        background-position:
            161px 186px,
            183px 198px,
            198px 177px,
            200px 218px,
            200px 202px,
            210px 174px,
            212px 190px,
            204px 184px,
            212px 185px,
            203px 178px,
            203px 205px,
            199px 177px,
            205px 171px,
            187px 159px,
            197px 167px,
            176px 163px,
            183px 228px,
            177px 172px,
            164px 183px,
            169px 143px;
    }
    33.3% {
        background-size:
            21px 21px,
            26px 26px,
            22px 22px,
            20px 20px,
            20px 20px,
            18px 18px,
            23px 23px,
            23px 23px,
            15px 15px,
            16px 16px,
            22px 22px,
            11px 11px,
            22px 22px,
            21px 21px,
            17px 17px,
            23px 23px,
            16px 16px,
            22px 22px,
            23px 23px,
            26px 26px;
        background-position:
            168px 183px,
            196px 189px,
            201px 209px,
            199px 210px,
            194px 177px,
            217px 190px,
            202px 175px,
            187px 177px,
            196px 184px,
            187px 178px,
            192px 176px,
            187px 171px,
            192px 189px,
            189px 161px,
            191px 162px,
            185px 177px,
            185px 219px,
            192px 188px,
            177px 189px,
            175px 150px;
    }
    36.6% {
        background-size:
            20px 20px,
            26px 26px,
            22px 22px,
            20px 20px,
            19px 19px,
            18px 18px,
            23px 23px,
            23px 23px,
            14px 14px,
            16px 16px,
            22px 22px,
            11px 11px,
            22px 22px,
            21px 21px,
            17px 17px,
            23px 23px,
            15px 15px,
            22px 22px,
            23px 23px,
            25px 25px;
        background-position:
            182px 182px,
            204px 169px,
            201px 221px,
            196px 184px,
            186px 167px,
            216px 202px,
            187px 175px,
            174px 186px,
            180px 192px,
            177px 194px,
            180px 162px,
            180px 188px,
            179px 193px,
            191px 186px,
            187px 182px,
            196px 196px,
            189px 196px,
            206px 193px,
            195px 183px,
            184px 171px;
    }
    40% {
        background-size:
            20px 20px,
            26px 26px,
            22px 22px,
            21px 21px,
            19px 19px,
            18px 18px,
            23px 23px,
            22px 22px,
            14px 14px,
            15px 15px,
            22px 22px,
            10px 10px,
            21px 21px,
            21px 21px,
            17px 17px,
            23px 23px,
            15px 15px,
            22px 22px,
            23px 23px,
            25px 25px;
        background-position:
            201px 186px,
            205px 164px,
            199px 222px,
            190px 162px,
            181px 182px,
            209px 203px,
            176px 192px,
            170px 192px,
            173px 193px,
            175px 202px,
            173px 171px,
            180px 205px,
            172px 182px,
            194px 201px,
            187px 206px,
            204px 191px,
            196px 168px,
            212px 185px,
            208px 174px,
            196px 199px;
    }
    43.3% {
        background-size:
            20px 20px,
            26px 26px,
            22px 22px,
            21px 21px,
            18px 18px,
            17px 17px,
            23px 23px,
            22px 22px,
            13px 13px,
            15px 15px,
            22px 22px,
            10px 10px,
            21px 21px,
            21px 21px,
            17px 17px,
            22px 22px,
            15px 15px,
            22px 22px,
            23px 23px,
            25px 25px;
        background-position:
            212px 188px,
            200px 179px,
            195px 212px,
            184px 167px,
            183px 200px,
            197px 186px,
            173px 197px,
            173px 188px,
            174px 188px,
            179px 194px,
            172px 192px,
            186px 205px,
            174px 174px,
            195px 191px,
            190px 205px,
            205px 176px,
            202px 166px,
            209px 176px,
            212px 176px,
            204px 202px;
    }
    46.6% {
        background-size:
            19px 19px,
            26px 26px,
            22px 22px,
            21px 21px,
            18px 18px,
            17px 17px,
            23px 23px,
            22px 22px,
            13px 13px,
            15px 15px,
            22px 22px,
            9px 9px,
            21px 21px,
            20px 20px,
            17px 17px,
            22px 22px,
            14px 14px,
            22px 22px,
            22px 22px,
            25px 25px;
        background-position:
            215px 185px,
            190px 194px,
            191px 185px,
            183px 192px,
            190px 198px,
            183px 173px,
            176px 188px,
            184px 180px,
            182px 184px,
            190px 179px,
            178px 203px,
            196px 186px,
            182px 179px,
            192px 172px,
            194px 180px,
            199px 173px,
            205px 188px,
            200px 177px,
            209px 186px,
            207px 185px;
    }
    50% {
        background-size:
            19px 19px,
            26px 26px,
            22px 22px,
            21px 21px,
            17px 17px,
            17px 17px,
            22px 22px,
            21px 21px,
            13px 13px,
            15px 15px,
            22px 22px,
            9px 9px,
            20px 20px,
            20px 20px,
            17px 17px,
            21px 21px,
            14px 14px,
            22px 22px,
            22px 22px,
            25px 25px;
        background-position:
            209px 183px,
            179px 186px,
            187px 153px,
            186px 205px,
            197px 177px,
            174px 180px,
            186px 174px,
            199px 184px,
            198px 188px,
            203px 183px,
            188px 192px,
            207px 179px,
            196px 190px,
            189px 178px,
            197px 165px,
            189px 189px,
            201px 207px,
            186px 187px,
            199px 191px,
            203px 167px;
    }
    53.3% {
        background-size:
            18px 18px,
            26px 26px,
            23px 23px,
            22px 22px,
            17px 17px,
            17px 17px,
            22px 22px,
            21px 21px,
            12px 12px,
            15px 15px,
            22px 22px,
            8px 8px,
            20px 20px,
            20px 20px,
            17px 17px,
            21px 21px,
            14px 14px,
            22px 22px,
            22px 22px,
            25px 25px;
        background-position:
            196px 184px,
            176px 171px,
            184px 143px,
            191px 192px,
            200px 177px,
            173px 194px,
            198px 181px,
            206px 191px,
            209px 192px,
            209px 196px,
            197px 169px,
            212px 191px,
            206px 184px,
            189px 195px,
            198px 178px,
            180px 196px,
            195px 201px,
            177px 185px,
            185px 179px,
            193px 169px;
    }
    56.6% {
        background-size:
            18px 18px,
            25px 25px,
            23px 23px,
            22px 22px,
            16px 16px,
            17px 17px,
            22px 22px,
            21px 21px,
            12px 12px,
            15px 15px,
            22px 22px,
            8px 8px,
            20px 20px,
            20px 20px,
            17px 17px,
            21px 21px,
            14px 14px,
            22px 22px,
            22px 22px,
            24px 24px;
        background-position:
            182px 186px,
            179px 172px,
            182px 143px,
            194px 169px,
            198px 193px,
            180px 198px,
            204px 194px,
            206px 187px,
            211px 189px,
            208px 196px,
            201px 169px,
            209px 201px,
            207px 179px,
            191px 188px,
            195px 200px,
            177px 186px,
            190px 177px,
            176px 180px,
            177px 173px,
            184px 191px;
    }
    60% {
        background-size:
            18px 18px,
            25px 25px,
            23px 23px,
            22px 22px,
            16px 16px,
            16px 16px,
            22px 22px,
            20px 20px,
            12px 12px,
            14px 14px,
            23px 23px,
            7px 7px,
            19px 19px,
            20px 20px,
            17px 17px,
            20px 20px,
            13px 13px,
            22px 22px,
            21px 21px,
            24px 24px;
        background-position:
            177px 186px,
            188px 188px,
            183px 154px,
            194px 171px,
            193px 194px,
            192px 187px,
            203px 191px,
            198px 180px,
            206px 186px,
            200px 184px,
            199px 188px,
            200px 195px,
            201px 182px,
            194px 174px,
            190px 199px,
            182px 174px,
            189px 172px,
            183px 181px,
            176px 182px,
            179px 197px;
    }
    63.3% {
        background-size:
            17px 17px,
            25px 25px,
            23px 23px,
            22px 22px,
            15px 15px,
            16px 16px,
            22px 22px,
            20px 20px,
            11px 11px,
            14px 14px,
            23px 23px,
            7px 7px,
            19px 19px,
            20px 20px,
            17px 17px,
            20px 20px,
            13px 13px,
            22px 22px,
            21px 21px,
            24px 24px;
        background-position:
            180px 185px,
            198px 188px,
            185px 182px,
            192px 192px,
            189px 178px,
            205px 175px,
            195px 178px,
            186px 186px,
            194px 190px,
            189px 183px,
            192px 197px,
            191px 183px,
            189px 188px,
            194px 186px,
            189px 177px,
            192px 182px,
            191px 191px,
            195px 184px,
            183px 192px,
            180px 184px;
    }
    66.6% {
        background-size:
            17px 17px,
            25px 25px,
            23px 23px,
            23px 23px,
            15px 15px,
            16px 16px,
            22px 22px,
            20px 20px,
            11px 11px,
            14px 14px,
            23px 23px,
            7px 7px,
            19px 19px,
            20px 20px,
            17px 17px,
            20px 20px,
            13px 13px,
            22px 22px,
            21px 21px,
            24px 24px;
        background-position:
            191px 186px,
            201px 176px,
            192px 208px,
            187px 193px,
            191px 182px,
            212px 181px,
            184px 181px,
            181px 192px,
            185px 191px,
            183px 194px,
            185px 182px,
            188px 191px,
            181px 182px,
            191px 192px,
            191px 173px,
            200px 197px,
            197px 202px,
            202px 181px,
            194px 186px,
            187px 171px;
    }
    70% {
        background-size:
            17px 17px,
            25px 25px,
            23px 23px,
            23px 23px,
            14px 14px,
            16px 16px,
            22px 22px,
            19px 19px,
            11px 11px,
            14px 14px,
            23px 23px,
            6px 6px,
            19px 19px,
            20px 20px,
            17px 17px,
            19px 19px,
            12px 12px,
            22px 22px,
            21px 21px,
            24px 24px;
        background-position:
            203px 186px,
            197px 175px,
            197px 210px,
            186px 176px,
            196px 195px,
            211px 194px,
            180px 192px,
            182px 185px,
            185px 188px,
            184px 194px,
            183px 169px,
            193px 199px,
            181px 182px,
            189px 178px,
            195px 194px,
            203px 192px,
            201px 188px,
            201px 183px,
            201px 173px,
            195px 182px;
    }
    73.3% {
        background-size:
            17px 17px,
            25px 25px,
            24px 24px,
            23px 23px,
            14px 14px,
            16px 16px,
            22px 22px,
            19px 19px,
            10px 10px,
            14px 14px,
            23px 23px,
            6px 6px,
            18px 18px,
            20px 20px,
            17px 17px,
            19px 19px,
            12px 12px,
            22px 22px,
            21px 21px,
            24px 24px;
        background-position:
            207px 186px,
            188px 186px,
            200px 192px,
            189px 175px,
            200px 187px,
            203px 196px,
            183px 188px,
            191px 180px,
            192px 188px,
            193px 183px,
            186px 180px,
            201px 188px,
            188px 188px,
            191px 184px,
            196px 205px,
            200px 177px,
            200px 176px,
            192px 185px,
            201px 180px,
            198px 195px;
    }
    76.6% {
        background-size:
            16px 16px,
            25px 25px,
            24px 24px,
            23px 23px,
            13px 13px,
            15px 15px,
            22px 22px,
            18px 18px,
            10px 10px,
            14px 14px,
            23px 23px,
            6px 6px,
            18px 18px,
            20px 20px,
            17px 17px,
            19px 19px,
            12px 12px,
            22px 22px,
            20px 20px,
            24px 24px;
        background-position:
            202px 186px,
            179px 183px,
            197px 165px,
            192px 194px,
            198px 181px,
            191px 181px,
            192px 178px,
            199px 191px,
            204px 189px,
            203px 188px,
            192px 194px,
            207px 184px,
            199px 183px,
            195px 195px,
            197px 191px,
            191px 179px,
            196px 189px,
            183px 181px,
            195px 193px,
            195px 187px;
    }
    80% {
        background-size:
            16px 16px,
            25px 25px,
            24px 24px,
            24px 24px,
            13px 13px,
            15px 15px,
            22px 22px,
            18px 18px,
            10px 10px,
            13px 13px,
            23px 23px,
            5px 5px,
            18px 18px,
            20px 20px,
            17px 17px,
            18px 18px,
            12px 12px,
            22px 22px,
            20px 20px,
            23px 23px;
        background-position:
            190px 186px,
            179px 177px,
            191px 162px,
            194px 192px,
            193px 193px,
            181px 174px,
            199px 186px,
            201px 189px,
            209px 189px,
            205px 194px,
            197px 183px,
            206px 196px,
            203px 182px,
            196px 183px,
            194px 171px,
            186px 195px,
            193px 193px,
            181px 183px,
            187px 185px,
            188px 174px;
    }
    83.3% {
        background-size:
            16px 16px,
            25px 25px,
            24px 24px,
            24px 24px,
            13px 13px,
            15px 15px,
            22px 22px,
            18px 18px,
            9px 9px,
            13px 13px,
            23px 23px,
            5px 5px,
            17px 17px,
            20px 20px,
            17px 17px,
            18px 18px,
            11px 11px,
            22px 22px,
            20px 20px,
            23px 23px;
        background-position:
            182px 186px,
            186px 182px,
            186px 184px,
            191px 174px,
            191px 188px,
            178px 185px,
            199px 190px,
            196px 180px,
            206px 190px,
            200px 188px,
            195px 173px,
            199px 196px,
            199px 188px,
            192px 176px,
            191px 179px,
            186px 192px,
            195px 183px,
            188px 185px,
            184px 175px,
            183px 184px;
    }
    86.6% {
        background-size:
            15px 15px,
            25px 25px,
            24px 24px,
            24px 24px,
            12px 12px,
            15px 15px,
            21px 21px,
            17px 17px,
            9px 9px,
            13px 13px,
            23px 23px,
            4px 4px,
            17px 17px,
            20px 20px,
            17px 17px,
            18px 18px,
            11px 11px,
            22px 22px,
            20px 20px,
            23px 23px;
        background-position:
            182px 188px,
            196px 184px,
            184px 202px,
            187px 176px,
            195px 185px,
            182px 197px,
            193px 181px,
            188px 189px,
            195px 189px,
            191px 186px,
            190px 185px,
            194px 185px,
            190px 184px,
            189px 191px,
            191px 198px,
            191px 178px,
            198px 193px,
            196px 179px,
            187px 185px,
            184px 192px;
    }
    90% {
        background-size:
            15px 15px,
            25px 25px,
            25px 25px,
            24px 24px,
            12px 12px,
            15px 15px,
            21px 21px,
            17px 17px,
            9px 9px,
            13px 13px,
            23px 23px,
            4px 4px,
            17px 17px,
            19px 19px,
            17px 17px,
            17px 17px,
            11px 11px,
            22px 22px,
            20px 20px,
            23px 23px;
        background-position:
            190px 188px,
            199px 179px,
            185px 194px,
            187px 192px,
            199px 192px,
            192px 193px,
            185px 184px,
            187px 191px,
            188px 190px,
            187px 193px,
            185px 188px,
            195px 193px,
            185px 182px,
            190px 188px,
            193px 194px,
            198px 185px,
            197px 189px,
            197px 185px,
            195px 191px,
            190px 180px;
    }
    93.3% {
        background-size:
            15px 15px,
            25px 25px,
            25px 25px,
            25px 25px,
            11px 11px,
            15px 15px,
            21px 21px,
            17px 17px,
            8px 8px,
            13px 13px,
            23px 23px,
            4px 4px,
            17px 17px,
            19px 19px,
            17px 17px,
            17px 17px,
            11px 11px,
            22px 22px,
            19px 19px,
            23px 23px;
        background-position:
            201px 189px,
            193px 182px,
            189px 170px,
            189px 185px,
            197px 185px,
            201px 177px,
            184px 189px,
            192px 181px,
            189px 191px,
            190px 188px,
            186px 177px,
            202px 196px,
            188px 190px,
            194px 179px,
            195px 173px,
            199px 195px,
            194px 189px,
            190px 184px,
            199px 179px,
            196px 178px;
    }
    96.6% {
        background-size:
            15px 15px,
            25px 25px,
            25px 25px,
            25px 25px,
            11px 11px,
            14px 14px,
            21px 21px,
            16px 16px,
            8px 8px,
            13px 13px,
            24px 24px,
            4px 4px,
            16px 16px,
            19px 19px,
            16px 16px,
            17px 17px,
            10px 10px,
            22px 22px,
            19px 19px,
            23px 23px;
        background-position:
            204px 187px,
            184px 182px,
            193px 169px,
            191px 171px,
            194px 193px,
            204px 181px,
            190px 181px,
            198px 189px,
            198px 190px,
            199px 187px,
            192px 185px,
            204px 187px,
            196px 184px,
            194px 190px,
            195px 173px,
            195px 184px,
            196px 191px,
            185px 177px,
            196px 180px,
            195px 189px;
    }
    100% {
        background-size:
            14px 14px,
            25px 25px,
            25px 25px,
            25px 25px,
            11px 11px,
            14px 14px,
            21px 21px,
            16px 16px,
            8px 8px,
            12px 12px,
            24px 24px,
            3px 3px,
            16px 16px,
            19px 19px,
            16px 16px,
            16px 16px,
            10px 10px,
            22px 22px,
            19px 19px,
            23px 23px;
        background-position:
            199px 186px,
            182px 180px,
            195px 189px,
            192px 183px,
            196px 186px,
            201px 195px,
            197px 185px,
            198px 190px,
            207px 192px,
            202px 192px,
            194px 187px,
            202px 195px,
            200px 182px,
            191px 182px,
            194px 194px,
            189px 183px,
            199px 187px,
            186px 187px,
            189px 190px,
            190px 183px;
    }
}
