.draggable-menu-body-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.draggable-menu-body,
.draggable-menu-body--unauthorized {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    background-color: $loader-bar-background; /* This color will show through where we want gaps */
    width: 100%;

    &__item {
        background-color: $new-footer-violet;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 24px 0;
        text-align: center;
        font-size: 12px;
        font-weight: 700;

        &:nth-child(1),
        &:nth-child(2) {
            border-top: 1px solid $loader-bar-background;
            border-bottom: 1px solid $loader-bar-background;
        }

        &:nth-child(3),
        &:nth-child(4) {
            border-bottom: 1px solid $loader-bar-background;
        }

        &:nth-child(odd) {
            border-right: 1px solid $loader-bar-background;
        }

        .items-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &__search,
            &__notifications,
            &__close-game {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 32px;
                margin-bottom: 8px;
                padding: 0;

                img,
                svg {
                    width: 18px;
                    height: 18px;
                }
            }

            p {
                margin: 0;
            }

            .action {
                cursor: pointer;
                position: relative;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin-bottom: 8px;
                border: 1px solid transparent;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background: transparent;

                &:disabled {
                    pointer-events: none;
                    opacity: 0.6;
                }

                svg {
                    width: 18px;
                    height: 18px;

                    path {
                        fill: $white;
                    }
                }

                &.favorite {
                    border: 1px solid $violet-new;
                    background: $violet-new;

                    svg {
                        path {
                            fill: $yellow-alt;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 570px) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);

        &__item {
            &:nth-child(3),
            &:nth-child(4) {
                border-top: 1px solid $loader-bar-background;
            }

            &:nth-child(3) {
                border-left: 1px solid $loader-bar-background;
            }
        }
    }
}

.draggable-menu-body--unauthorized {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);

    @media (min-width: 570px) {
        max-width: 234px;
    }
}
