.table {
    width: 100%;
    text-align: center;

    th {
        padding: 6px;
        font-size: 14px;
        font-weight: 300;
    }

    td {
        padding: 8px 6px;
    }

    tbody {
        tr:nth-child(odd) {
            background: $input-disabled;
        }
    }
}
