.footer {
    background: $new-footer-violet;
    padding-bottom: 62px;
    font-size: 10px;
    line-height: 14px;
    text-align: justify;
    color: $ultra-semi-white;
    font-weight: 400;

    &-pre-footer {
        color: $pre-footer-color;
        padding: 24px 10px;
        border-bottom: 1px solid $pre-footer-content-border;

        h1 {
            font-weight: 800;
            font-size: 18px;
            margin-bottom: 24px;
            color: $white;
        }

        &-content {
            font-size: 12px;
            line-height: 21px;

            a {
                color: inherit;
                text-decoration: underline;
                font-weight: bold;
            }

            .footer-description {
                font-size: 14px;
                font-weight: 400;
                margin-bottom: 24px;
                color: $white;
            }

            .expandable-data-list {
                display: flex;
                flex-direction: column;
                gap: 12px;

                > div {
                    border-radius: 10px;
                    border: 1px solid $pre-footer-table-border;
                    padding: 12px;
                    transition: background-color 0.3s ease-in-out;

                    table {
                        text-align: center;
                        width: 100%;
                        margin-bottom: 16px;
                        font-size: 10px;
                        line-height: 12px;

                        th,
                        td {
                            border: 1px solid $pre-footer-table-border;
                            padding: 2px;
                        }

                        th {
                            font-weight: 700;
                            background: $pre-footer-table-head-bg;
                        }

                        td {
                            height: 40px;
                        }
                    }

                    &:not(.expanded):hover {
                        cursor: pointer;
                        background: $pre-footer-table-head-bg;
                    }

                    &.expanded {
                        h2 {
                            img {
                                transform: rotate(-180deg);
                            }
                        }
                    }

                    h2 {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        text-align: left;
                        gap: 12px;
                        margin: 0;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 21px;
                        color: $new-light-violet;

                        img {
                            width: 12px;
                            transition: transform 0.3s ease-in-out;
                        }
                    }

                    .info {
                        margin: 24px 4px 4px;
                    }
                }

                ul,
                ol {
                    padding: 0 0 0 16px;
                    margin: 0 0 16px;
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }

                blockquote {
                    margin: 0;
                }

                h3,
                h4,
                h5,
                h6 {
                    margin: 16px 0 8px;
                }

                h3 {
                    font-size: 16px;
                }

                h4 {
                    font-size: 15px;
                }

                h5 {
                    font-size: 14px;
                }

                h6 {
                    font-size: 13px;
                }
            }
        }
    }

    .footer-content {
        padding: 0 10px;
    }

    .title {
        font-weight: 800;
        font-size: 18px;
        line-height: 33px;
        color: $white;
        margin-bottom: 24px;
    }

    .payment-providers,
    .footer-links-block-list,
    .select-language--list {
        border-bottom: 1px solid $ultra-semi-white;
    }

    .payment-providers {
        padding: 24px 0;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 8px 20px;

            .payment-provider-method {
                img {
                    opacity: 0.5;
                    max-width: 60px;
                }
            }
        }
    }

    .mobile-app-reference {
        padding: 24px 0;
        border-bottom: 1px solid $pre-footer-content-border;

        .title {
            line-height: 24px;
        }

        .mobile-app-reference--content {
            display: flex;
            flex-direction: column;
        }

        .mobile-app-reference--content--img {
            img {
                width: 140px;
            }
        }

        .mobile-app-reference--description {
            color: $white;
            font-size: 14px;
            order: -1;
            position: relative;

            .read-more {
                color: $new-light-violet;
                font-weight: 500;
                position: absolute;
                left: 170px;
                bottom: -28px;
            }

            p {
                margin: 0 0 16px;
                line-height: 21px;
            }
        }
    }

    &-links-block-list {
        padding: 24px 0;
        margin: 0;
        list-style-type: none;

        li {
            &:not(:last-of-type) {
                margin-bottom: 16px;
            }
        }

        a {
            display: block;
            font-size: 14px;
            font-weight: 500;
            color: $new-light-violet;
        }
    }

    .select-language {
        &--list {
            margin: 0;
            padding: 24px 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            gap: 12px;

            button {
                outline: none;
                background: transparent;
                border-radius: 50%;
                border: 1px solid $violet-new;
                width: 36px;
                height: 36px;
                text-transform: uppercase;
                font-weight: 800;
                font-size: 12px;
                line-height: 16px;
                color: $white;
                flex-shrink: 0;
                padding: 0;

                &.active {
                    background: $violet-new;
                }
            }
        }
    }

    &-licence {
        padding: 24px 0;
        color: $new-light-violet;
        line-height: 186%;

        p {
            font-weight: 300;
            font-size: 10px;
            margin: 0;
            text-align: justify;

            & + p {
                font-size: 9px;
                line-height: 18px;
                margin-top: 24px;
                color: $pre-footer-color;
            }
        }
    }

    &-icons {
        padding-top: 5px;
        display: flex;

        &--curacao {
            img {
                height: 85px;
            }
        }

        &--inactive {
            pointer-events: none;
        }
    }
}
