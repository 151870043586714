.promotion {
    display: block;
    border-radius: 20px;
    overflow: hidden;
    background: $deep-violet;
    position: relative;
    text-decoration: none;
    box-shadow: 0 40px 40px -15px $darkest-purple;

    .visual {
        font-size: 0;

        img {
            width: 100%;
            max-height: fit-content;
        }
    }

    .infos {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-text-container {
            display: inline-block;
            white-space: nowrap;
            text-align: left;

            .promo-title {
                color: $white;
                font-weight: 700;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .subtitle {
                color: $semi-white;
                font-size: 12px;
                font-weight: 400;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

&.vip {
    margin: -44px 0 0 0;
}

.promotion-loader.form-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.promotion-modal {
    &.modal--modern {
        .modal-box--title {
            display: flex;
            justify-content: space-between;
            line-height: normal;

            > div:first-child {
                display: flex;
                align-items: center;
            }
        }

        .modal-box--content {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            position: relative;
            overflow-y: auto;
        }
    }

    .promotions-modal-data {
        margin-bottom: 8px;

        > div:not(.promotions-modal-data--image) {
            flex: 1;
            padding: 12px;
            border-radius: 10px;
            text-align: center;

            .info {
                justify-content: center;
            }

            &.promotions-modal-data--timer,
            &.promotions-modal-data--not-available {
                min-height: 102px;
                border: 1px solid $coral;
                background: $coral-light;
                color: $coral;
            }

            &.promotions-modal-data--not-available {
                display: flex;
                align-items: center;
                justify-content: center;

                .title {
                    width: 150px;
                }
            }

            &:not(.promotions-modal-data--not-available) {
                .title {
                    margin-bottom: 10px;
                }
            }

            .title {
                font-weight: 800;
            }

            .info {
                display: flex;

                .info-text {
                    > div:first-child {
                        font-size: 24px;
                        font-weight: 800;
                        margin-bottom: 4px;
                    }

                    > div:last-child {
                        font-size: 8px;
                        font-weight: 700;
                        text-transform: uppercase;
                    }
                }

                .info-separator {
                    font-size: 24px;
                    font-weight: 800;
                    width: 30px;
                }
            }
        }
    }

    .MuiAccordion-root {
        box-shadow: none;

        &:before {
            display: none;
        }
    }

    .MuiAccordionSummary-root {
        font-size: 16px;
        font-weight: 800;
        color: $copy-input-color;
        border-bottom: 1px solid $border-description;
    }

    .MuiAccordionSummary-content {
        margin: 16px 0;
    }

    .MuiAccordionDetails-root {
        color: $copy-input-color;

        .accordion-content {
            white-space: break-spaces;
        }

        .promo-prize-table {
            width: 100%;
            text-align: center;
            border-collapse: separate;
            border-spacing: 4px;
            margin: -4px;

            th,
            td {
                width: 50%;
                height: 30px;
                border-radius: 4px;
            }

            th {
                color: $white;
                font-size: 14px;
                font-weight: 800;
                background: $violet-new;
            }

            tbody {
                tr:nth-child(even) {
                    td {
                        background: $prize-pool-row;
                    }
                }
            }
        }
    }

    .container-btns {
        position: sticky;
        bottom: -1px;
        left: 0;
        padding: 18px 0 30px;
        margin-top: auto;
        display: flex;
        justify-content: center;
        background: $white-80-opacity;
        backdrop-filter: blur(3px);
    }
}

.promo-plate-status {
    border-radius: 8px;
    background: $copy-input-color;
    font-weight: 800;
    color: $white;
    display: flex;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;

    &--ongoing {
        background: $coral;
    }

    &--tag {
        background: $promo-tag;
    }

    .pulse-dot {
        border-radius: 50%;
        background-color: $white;
        animation: pulse 2s linear infinite;
    }
}

@keyframes pulse {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}
