.welcome-modal {
    @include font-family-nunito;
    color: $step-content;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;

    &--title {
        br:last-child {
            display: none;
        }
    }

    &--content {
        a {
            color: $violet-new;
        }
    }
}
