.casino-guide-tab {
    position: relative;
    margin: 24px 0 48px;
    padding: 10px 10px 0;
}

.container-casino-guide {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-auto-rows: 1fr;
    grid-row-gap: 12px;

    .infos {
        padding: 0;
        box-shadow: 0 2px 5px 0 $card-box-shadow;

        a {
            display: flex;
            align-items: center;
            padding: 16px;
            height: 100%;
            color: inherit;
        }

        .title {
            font-size: 18px;
            margin: 0;
            font-weight: 500;
            width: 100%;
        }
    }

    .casino-guide {
        .visual img {
            width: 100%;
        }

        .infos {
            padding: 12px;

            .title {
                font-size: 16px;
                line-height: 19px;
                font-weight: 700;
                margin: 0;
            }
        }
    }

    &.vip {
        margin-top: -24px;
    }
}

.casino-guide-back {
    width: 8px;
    height: 8px;
    border-top: 2px solid #4a2c10;
    border-left: 2px solid #4a2c10;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    position: absolute;
    top: 0;
    left: 20px;
}

.casino-guide-content {
    overflow: hidden;

    .visual {
        width: calc(100% + 24px);
        max-width: none;
        margin: -12px -12px 0 -12px;

        img {
            width: 100%;
        }
    }

    iframe {
        overflow: hidden !important;
        width: 100%;
        min-height: 100%;
    }

    * {
        max-width: 100%;
    }
}

.no-casino-guide-content {
    margin-top: 40px;
    text-align: center;
    font-weight: 700;
    font-size: 18px;
    padding: 0 12px;
}

.casino-guide-games {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
    justify-content: center;

    .game {
        width: 130px;
        height: 98px;
        margin: 20px 0;
        text-align: center;

        &:hover {
            color: $font-play-tab;
        }

        .visual {
            margin: 0;
            width: 130px;
            height: 98px;
            border-radius: 4px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
            }
        }

        .name {
            color: $black;
            margin-top: 5px;
        }
    }
}
