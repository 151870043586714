.deposit-modal {
    .modal-box {
        box-sizing: border-box;
        background: transparent;
        padding: 0;
        width: 640px;
        height: 680px;
        color: $step-content;

        &--content {
            position: relative;
            height: 100%;
            padding: 24px 0 32px;
            display: flex;
            width: inherit;
            border-radius: 20px;
            transition:
                width,
                height 0.3s ease-in-out;

            .form-loader {
                display: flex;
                justify-content: center;
            }

            .step-content {
                position: relative;
                overflow-y: auto;
                overflow-x: hidden;
                flex: 1;

                #deposit_form {
                    .form-group {
                        margin-bottom: 38px;

                        &--checkbox-set {
                            > .checkbox-container:not(:last-child) {
                                margin-bottom: 24px;
                            }
                        }
                    }
                }

                .deposit-transition {
                    height: 100%;
                }

                iframe {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, 0);
                    height: 100%;

                    &.iframe--x1,
                    &.iframe--cashlibv2 {
                        left: 0;
                        width: 100%;
                        transform: none;
                    }
                }

                span[role='link'] {
                    cursor: pointer;
                    color: $violet-new;
                    font-weight: 600;
                    width: max-content;

                    &.disabled {
                        pointer-events: none;
                    }

                    &:hover {
                        text-decoration: underline;
                    }

                    + span[role='link'] {
                        margin-left: 16px;
                    }
                }

                section {
                    margin-bottom: 24px;

                    .section-label {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        border-bottom: 1px solid $card-border;
                        padding-bottom: 8px;
                        font-weight: 700;
                    }
                }
            }

            &--inner {
                display: flex;
                flex-direction: column;
                width: 100%;

                .modal-box {
                    &--header {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 0 24px 38px;

                        &-back {
                            @include header-back-btn;
                        }

                        > div {
                            display: flex;
                            align-items: center;

                            &:first-child {
                                gap: 16px;
                            }

                            &:last-child {
                                gap: 24px;
                                justify-content: flex-end;

                                .progress-deposit {
                                    width: 150px;
                                    margin: 0;
                                    height: 8px;
                                    border-radius: 8px;

                                    .MuiLinearProgress-bar {
                                        background: $violet-new;
                                        border-radius: 8px;
                                    }
                                }
                            }
                        }

                        .modal-box--title {
                            color: inherit;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 800;
                            line-height: normal;
                            margin: 0;
                        }

                        .modal-box--close {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50px;
                            border: 1px solid $step-content;
                            width: 32px;
                            height: 32px;
                            opacity: 1;
                            background: none;

                            &:hover {
                                border-color: $violet-new;

                                svg {
                                    path {
                                        fill: $violet-new;
                                    }
                                }
                            }

                            &:before {
                                content: none;
                            }
                        }
                    }
                }
            }
        }

        form {
            .prepend-field {
                @include prepend-field;

                &--mark {
                    .payment-provider-method {
                        img {
                            vertical-align: middle;
                            transform: scale(0.8);
                        }
                    }
                }
            }

            .form-group {
                label.checkbox-container {
                    font-weight: 600;
                    display: flex;

                    &:hover {
                        color: inherit;
                    }
                }

                label:not(.checkbox-container) {
                    font-weight: 700;
                    margin-bottom: 16px;

                    &[for='deposit_cvv_field'] + div {
                        position: relative;
                    }
                }

                .form-control {
                    height: 40px;

                    &.error {
                        color: $red;
                    }
                }

                &--cell {
                    &:not(:last-child) {
                        margin-right: 16px;
                    }

                    &-sm {
                        width: 25%;
                    }

                    &-md {
                        width: 50%;
                    }

                    &-lg {
                        width: 100%;
                    }
                }
            }

            .form-message {
                padding-left: 0;
            }

            .btn-voucher-action {
                height: 40px;
                width: 40px;
                background-color: $violet-new;
                color: $white;
            }

            .reveal-cvv {
                @include reveal-password;
                transform: none;
                top: 0;
            }

            .deposit-form--amount {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                padding-bottom: 16px;
                border-bottom: 1px solid $card-border;
                margin-bottom: 38px;

                > div {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    grid-gap: 16px;
                    gap: 16px;
                    min-height: 75px;

                    &:last-child {
                        align-items: flex-end;
                    }
                }

                .change-amount--active {
                    position: relative;
                    z-index: 2;
                    flex: 0;
                    gap: 8px;
                    right: 0;

                    .prepend-field {
                        width: 100%;
                    }

                    .btn {
                        height: 22px;
                    }

                    .form-group {
                        margin: 0 !important;
                    }
                }

                .change-amount--inactive {
                    .amount-value {
                        font-size: 21px;
                        font-weight: 800;
                    }
                }
            }
        }

        .deposit-view-container {
            height: 100%;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 0 24px;

            &:not(.expanded) {
                height: calc(100% - 60px);
            }

            &.blurry {
                overflow-y: hidden;

                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    background: rgba($white, 0.5);
                }
            }

            .toaster-notification {
                position: absolute;
                border-radius: 10px;
                bottom: 0;
                left: 24px;
                width: calc(100% - 48px);
                min-width: auto;
                box-shadow: none;
            }

            ul {
                list-style-type: none;
                width: 100%;
                padding: 0;
                margin: 0;

                &[class*='--list'] {
                    li {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 12px 0;
                        gap: 12px;
                        border-bottom: 1px solid $card-border;

                        &:last-child {
                            border-bottom: none;
                        }

                        .btn {
                            height: 30px;
                            min-width: 100px;
                        }
                    }
                }
            }

            .container-btns {
                display: flex;
                justify-content: center;
                gap: 24px;
                position: absolute;
                background-color: $white;
                width: 100%;
                left: 0;
                bottom: 0;
                padding: 20px 0 0;
                margin: 0 auto;
                border-radius: 0 0 20px 20px;

                .btn {
                    min-width: 180px;

                    &.apple-pay-btn {
                        border-radius: 20px;
                        width: 180px;
                    }
                }
            }
        }

        .deposit-pending-withdrawals {
            &--notification {
                display: flex;
                align-items: center;
                justify-content: space-between;
                background: $violet-new;
                border-radius: 10px;
                padding: 12px;
                color: $white;
                font-size: 12px;
                font-weight: 400;
                gap: 16px;

                > div:first-child {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    svg {
                        flex-shrink: 0;
                    }

                    strong {
                        font-weight: 800;
                    }
                }

                .btn--check-withdrawals {
                    border-radius: 20px;
                    background: transparent;
                    border: 1px solid $white;
                    font-weight: 800;
                    font-size: 12px;
                    height: 24px;
                    line-height: normal;

                    &:hover {
                        background: rgba($white, 0.3);
                    }
                }
            }

            &--list {
                li {
                    .wdr-primary {
                        position: relative;
                        flex: 1;

                        &--data {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .wdr-amount {
                                display: flex;
                                gap: 12px;
                                align-items: center;
                                font-weight: 800;
                                font-size: 21px;
                                line-height: 19px;
                            }

                            .wdr-extra {
                                font-size: 12px;
                                line-height: 14px;
                                margin-top: 8px;

                                .wdr-status {
                                    color: $blue;
                                }
                            }
                        }

                        &--message {
                            display: flex;
                            align-items: center;
                            text-align: justify;
                            position: absolute;
                            gap: 16px;
                            top: 0;
                            left: 0;
                            width: inherit;
                            height: 100%;
                            z-index: 2;

                            > div:last-child {
                                display: flex;
                            }
                        }
                    }
                }
            }
        }

        .deposit-bonus {
            &--selected-bonus {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 0;
            }

            &--selected-bonus,
            &--list {
                .bonus-card--limits {
                    font-size: 11px;
                    color: $deposit-limits-color;
                    margin-top: 8px;
                }

                .bonus-name {
                    font-size: 16px;
                    font-weight: 800;
                }

                .bonus-description {
                    font-size: 11px;
                    margin-top: 8px;
                }

                .btn-bonus {
                    width: 125px;

                    &:not(.btn-submit) {
                        border: 1px solid rgba($deposit-limits-color, 0.3);
                        background-color: $white;

                        &:hover {
                            background-color: rgba($yellow-alt, 0.1);
                        }
                    }

                    &.btn-submit {
                        border: 1px solid $yellow-alt;

                        &:hover {
                            color: inherit;
                            background-color: rgba($yellow-alt, 0.3);
                        }
                    }
                }
            }
        }

        .first-deposit-bonus-message {
            display: flex;
            align-items: center;
            gap: 16px;
            padding: 12px;
            border-radius: 10px;
            background: $violet-new;
            animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97);
            animation-delay: 0.25s;
            color: $white;
            font-size: 12px;

            br {
                display: none;
            }

            .box {
                display: grid;
                grid-template-columns: 36px 1fr;
                grid-column-gap: 12px;
                align-items: center;
                flex: 1;

                > div:last-child {
                    text-align: left;
                }

                .gift-icon {
                    background: linear-gradient(180deg, $blue 0%, $violet-new 100%);
                    box-shadow: 0 5px 12px $box-shadow-gift;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    animation: rotate-gift 1.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
                    animation-delay: 1s;

                    img {
                        position: relative;
                        transform: scale(0.25);
                    }
                }

                .gift-title {
                    font-weight: 800;
                }

                .gift-text {
                    line-height: normal;
                    font-weight: 400;

                    .violet-global {
                        color: $white;
                        font-weight: 800;
                    }

                    &--secondary {
                        align-self: center;
                        color: $message-description;
                        font-size: 12px;
                    }
                }
            }

            .gift-time-left {
                font-weight: 800;
                font-size: 21px;
                line-height: normal;
                text-align: center;
                min-width: 90px;
            }
        }

        .deposit-payment {
            form {
                display: flex;
                gap: 30px;

                > div {
                    flex: 1;
                }
            }

            &--title {
                padding-bottom: 16px;
                margin-bottom: 16px;
            }

            #deposit_first_step {
                .change-method {
                    span[role='link'] {
                        width: auto;
                        max-width: 50%;
                    }
                }
            }

            .deposit-payment-info--cashlib-direct {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            &--method {
                display: flex;
                flex-direction: column;
                gap: 16px;

                .no-payment-methods {
                    color: $error-message;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 16px;
                }

                .change-method {
                    display: flex;
                }
            }

            &--methods {
                display: flex;
                flex-wrap: wrap;
                gap: 12px;

                &.bank-cards-list {
                    flex-wrap: nowrap;
                    overflow-y: auto;
                    padding: 0 24px;
                    margin: 0 -24px;
                    width: calc(100% + 48px);

                    li {
                        &.method-wrapper {
                            width: 30%;
                            align-items: flex-start;

                            > div:first-child {
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;

                                .bank-card--actions {
                                    align-self: flex-start;

                                    svg {
                                        height: 16px;
                                    }
                                }
                            }
                        }
                    }

                    .payment-option {
                        .payment-provider-method {
                            margin-right: 12px;
                        }
                    }
                }

                li {
                    border-radius: 6px;
                    border: 1px solid $card-border;
                    background: $white;
                    flex-shrink: 0;

                    &.method-wrapper {
                        position: relative;
                        width: calc((100% - 24px) / 3);
                        min-width: 92px;
                        height: 90px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        border: 1px solid $card-border;
                        border-radius: 6px;
                        padding: 12px;
                        transition: all 0.1s;

                        &.active {
                            background-color: $payment-method-hover;
                            border: 1px solid $yellow-alt;
                        }

                        &:hover {
                            background-color: $yellow-alt-hover;
                            cursor: pointer;
                        }

                        .method-name {
                            text-align: center;
                            font-size: 12px;
                            font-weight: 700;
                        }

                        .x1-hint {
                            position: absolute;
                            top: 8px;
                            right: 8px;
                            cursor: pointer;

                            &:hover {
                                svg {
                                    path {
                                        fill: $violet-new;
                                    }
                                }
                            }
                        }
                    }
                }

                .bank-card-icon {
                    > div:first-child {
                        display: none;
                    }
                }
            }

            &--amount {
                .form-group {
                    > div:first-child {
                        display: flex;
                        align-items: center;
                        gap: 12px;
                    }

                    .prepend-field {
                        width: calc(50% + 8px);

                        .form-control {
                            color: inherit;
                            font-size: 21px;
                            font-weight: 800;
                            height: 40px;
                            line-height: 17px;
                        }
                    }
                }

                .transaction-amount-extra {
                    font-size: 11px;
                    color: $deposit-limits-color;
                }

                .amount-range {
                    display: flex;
                    margin-bottom: 12px;
                    gap: 8px;

                    .transaction-amount-btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 40px;
                        background: $white;
                        border: 1px solid $card-border;
                        border-radius: 5px;
                        color: inherit;
                        font-weight: 800;
                        font-size: 12px;
                        line-height: 19px;
                        padding: 0;
                        width: 25%;

                        &.active,
                        &:hover {
                            background: $amount-bg;
                            border: 1px solid $yellow-alt;
                        }
                    }
                }
            }
        }

        .deposit-x1-information {
            .x1-description {
                display: flex;
                gap: 24px;
                align-items: center;
                margin-bottom: 24px;
                font-weight: 700;

                .method-radio {
                    margin: 0;
                    flex-shrink: 0;
                    filter: none;
                    opacity: 1;

                    img {
                        height: 70px;
                    }
                }
            }
        }

        a {
            color: $violet-new;

            &:hover {
                text-decoration: underline;
            }
        }

        .bank-card-icon {
            display: flex;
            align-items: center;
            gap: 16px;
            font-size: 16px;
            font-weight: 800;

            &--combined {
                display: flex;
                align-items: center;
                gap: 8px;

                .payment-provider-method {
                    img {
                        width: 32px;
                    }

                    &.visa {
                        img {
                            width: 60px;
                        }
                    }
                }
            }

            + .form-group {
                margin: 0;
            }
        }
    }

    .modal-result {
        position: relative;
        width: 100%;

        .deposit-view-container {
            > div:first-child {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;

                h2 {
                    font-size: 24px;
                    font-weight: 800;
                    margin: 16px 0 0;
                }
            }
        }
    }
}

.payment-option {
    height: inherit;
    display: flex;
    align-items: center;
}

.validate-bonus-modal {
    .bonus-card--limits {
        font-size: inherit;
        color: inherit;
        margin: 0;
    }
}

.first-deposit-offer {
    padding: 0 16px;
    border-radius: 6px;
    background: $light-peach;
    border: 1px solid $latest-winner-card-border;
    animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97);
    animation-delay: 0.25s;
    color: $step-content;

    br {
        display: none;
    }

    .box {
        display: grid;
        grid-template-columns: 76px 1fr;
        grid-column-gap: 24px;
        padding: 16px 0;

        &:first-child {
            border-bottom: 1px solid $card-border;
        }

        > div:last-child {
            text-align: left;
        }

        .gift-icon {
            background: linear-gradient(180deg, $blue 0%, $violet-new 100%);
            box-shadow: 0 5px 12px $box-shadow-gift;
            width: 76px;
            height: 76px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            animation: rotate-gift 1.6s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
            animation-delay: 1s;

            img {
                position: relative;
                transform: scale(0.55);
            }
        }

        .gift-time-left {
            font-weight: 800;
            font-size: 24px;
            line-height: 24px;
            color: $violet-new;
            text-align: center;
        }

        .gift-title {
            font-weight: 800;
            font-size: 18px;
            margin-bottom: 12px;
            color: $violet-new;
        }

        .gift-text {
            font-size: 14px;
            line-height: 18px;
            font-weight: 400;

            .violet-global {
                color: $violet-new;
                font-weight: 800;
            }

            &--secondary {
                align-self: center;
                color: $message-description;
                font-size: 12px;
            }
        }
    }
}

@keyframes shake {
    0%,
    100% {
        transform: rotate(0deg);
    }
    15%,
    45% {
        transform: rotate(5deg);
    }
    30%,
    60% {
        transform: rotate(-5deg);
    }
    75% {
        transform: rotate(2deg);
    }
    90% {
        transform: rotate(-2deg);
    }
}

@keyframes rotate-gift {
    70%,
    100% {
        transform: rotate(0deg) scale(1);
    }
    80% {
        transform: rotate(15deg) scale(1.05);
    }
    90% {
        transform: rotate(-15deg) scale(0.95);
    }
}
